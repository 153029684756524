<section class="mt-3" style="height:calc(100vh - 60px);">
    <div class="d-flex flex-column h-100 w-100 flex-xl-row pt-0 px-3 justify-content-around align-content-around">
        <div class="h-100 w-100 border-sm-end-0 border-xl-end me-0 me-xl-3 border-sm-bottom border-xl-bottom-0 mb-3 mb-xl-0 overflow-auto">
            <div class="d-flex flex-row sticky-top bg-white p-1 mb-2">
                <div class="lead me-auto">
                    Domande
                </div>
                <button #addQst="ngbCollapse" [(ngbCollapse)]="!filtersActive" type="button" class="btn btn-outline-light text-dark btn-sm" (click)="addQuestion()">
                    Aggiungi Domanda
                </button>
                <button type="button" class="btn btn-outline-light text-dark btn-sm" (click)="filters.toggle();"
                    [attr.aria-expanded]="!filtersActive" aria-controls="filters">
                    Filtri
                </button>
                <div #filters="ngbCollapse" [(ngbCollapse)]="filtersActive" class="ms-2">
                    <div class="d-inline-block" *ngFor="let filter of _filters; let i = index" ngbDropdown #linkRef="ngbDropdown">
                        <button type="button" [disabled]="filter.disabled" class="btn btn-outline-light text-dark btn-sm me-2 col" [id]="'dropdown'+filter.name" ngbDropdownAnchor
                            (focus)="linkRef.open()">{{filter.selected}}</button>
                        <div ngbDropdownMenu [aria-labelledby]="'dropdown'+filter.name">
                            <button *ngFor="let opt of filter.options | keyvalue" ngbDropdownItem (click)="applyFilter(filter.name,opt.value,i)">{{opt.key}}</button>
                        </div>
                    </div>
                    <div class="d-inline-block">
                        <button type="button" class="btn btn-outline-danger btn-sm" (click)="resetFilters()">
                            Cancella
                        </button>
                    </div>
                </div>
            </div>
            <ul class="list-group list-group-flush list-group-numbered">
                <li *ngFor="let q of filteredQuestions;let i = index" routerLink="details/{{q.id}}" (click)="onListItemSelect($event);" class="list-group-item list-group-item-action list-group-item-light d-flex justify-content-between align-items-start">
                    <div class="d-flex flex-column ms-2 me-auto text-truncate p-2 w-100">
                        <div class="fw-bold d-flex flex-row">
                            <span class="badge bg-light text-dark fw-lighter p-2">Categoria: {{q.category}}</span>
                            <span class="badge bg-light text-dark fw-lighter ms-1 p-2">Difficoltà: {{q.difficulty}}</span>
                            <span class="badge bg-light text-dark fw-lighter ms-1 p-2">Tipo: {{getTypeName(q.type)}}</span>
                            <!--span class="badge bg-light text-dark fw-lighter p-2 ms-auto">Risposte: {{q.answers.length}}</span-->
                        </div>
                        <div class="d-flex flex-row justify-content-between position-relative">
                            <span class="mt-3 fs-6 flex-shrink-1">{{q.text}}</span>
                            <span class="input-group-text position-absolute end-0" (click)="deleteQuestion(i);$event.stopPropagation();">
                                <fa-icon [icon]="['fas', 'trash']"></fa-icon>
                            </span>
                        </div>
                    </div>
                </li>
            </ul>
        </div>
        <div class=" h-100 w-100 p-3">
            <router-outlet></router-outlet>
        </div>
    </div>
</section>