import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { take } from 'rxjs/operators';
import { DeleteStoredImgsService } from 'src/app/common/services/delete-stored-imgs.service';
import { StoreImgsService } from 'src/app/common/services/store-imgs.service';
import { NgxImageCompressService, DataUrl, UploadResponse } from "ngx-image-compress";
import { ConfirmModalComponent } from '../confirm-modal/confirm-modal.component';

@Component({
  selector: 'app-multi-img-modal',
  templateUrl: './multi-img-modal.component.html',
  styleUrls: ['./multi-img-modal.component.css']
})
export class MultiImgModalComponent implements OnInit {
  @Input() images: any[];
  @Input() imgFolder: string;
  @Input() multiImage: boolean = true;
  @Output() imagesChange = new EventEmitter<string[]>();
  activeSlide: number = 0;
  selectedFiles?: FileList;
  previews: string[] = [];
  imgResultMultiple: UploadResponse[] = [];
  imgResultAfterCompress: DataUrl = '';
  addedImagesIndex = [];

  constructor(public activeModal: NgbActiveModal,
    private storeImagesSvc: StoreImgsService,
    private deleteImageSvc: DeleteStoredImgsService,
    private imageCompress: NgxImageCompressService,
    private modalService: NgbModal
  ) { }

  ngOnInit(): void {
    console.log(this.images)
  }

  setActiveSlide(i: number) {
    this.activeSlide = i;
  }

  imgsInputClick(s: string) {
    this.uploadMultipleFiles();
  }

  cancel() {
    this.addedImagesIndex.forEach(i => {
      this.deleteImg(i);
    })
    this.activeModal.dismiss();
  }

  deleteImg(i?: number) {
    const modalRef = this.modalService.open(ConfirmModalComponent);
    modalRef.result.then(() => {
      console.log('confirmed')
      let files = i ? [this.images[i]] : this.images;
      const deleteimg$ = this.deleteImageSvc.deleteImgs([...files])
        .pipe(take(1));
      deleteimg$.subscribe(data => {
        console.log(`delete image msg: ${data}`);
        this.images.splice(i, 1);
        this.setActiveSlide(i==0?0:i-1);
        this.addedImagesIndex.splice(this.addedImagesIndex.indexOf(i), 1);
        this.imagesChange.emit(this.images);
      });
    })
  }

  uploadMultipleFiles() {
    return this.imageCompress
      .uploadMultipleFiles()
      .then((multipleOrientedFiles: UploadResponse[]) => {
        this.imgResultMultiple = multipleOrientedFiles;
        console.warn(`${multipleOrientedFiles.length} files selected`);
        this.imgResultMultiple.forEach((img) => {
          console.log(this.getImgProps(img.image))
          console.warn(
            'Size in bytes was:',
            this.imageCompress.byteCount(img.image)
          );
          this.imageCompress
            .compressFile(img.image, img.orientation, 50, 50)
            .then((result: DataUrl) => {
              this.imgResultAfterCompress = result;
              console.warn(
                'Size in bytes is now:',
                this.imageCompress.byteCount(result)
              );
              const saveImgs$ = this.storeImagesSvc.saveImgsToPath({ files: [result], path: this.imgFolder }).pipe(take(1));
              saveImgs$.subscribe(filePath => {
                console.log(<any[]>filePath);
                filePath.forEach(fp => {
                  this.addedImagesIndex.push(this.images.push(fp['filePath']) - 1);
                })
              })
            });
        });
      });
  }

  getImgProps(base64:string){
    let props = base64.substring(0,base64.indexOf(';'));
    let type = props.substring(props.indexOf(':')+1,props.indexOf('/'))
    let ext = props.substring(props.indexOf('/')+1);
    return {type:type,ext:ext}
  }

}
