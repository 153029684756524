import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { GbSharedService } from 'src/app/guides/services/gb-shared.service';
import { GuidebookService } from 'src/app/guides/services/guidebook.service';
import { PeopleService } from 'src/app/guides/services/people.service';
import { StoreImgsService } from 'src/app/common/services/store-imgs.service';
import { DeleteStoredImgsService } from 'src/app/common/services/delete-stored-imgs.service';
//import * as bootstrap from 'bootstrap';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfigService } from 'src/app/guides/services/config.service';
import { MultiImgModalComponent } from 'src/app/common/components/multi-img-modal/multi-img-modal.component';
import { PlaceService } from 'src/app/guides/services/place.service';

@Component({
  selector: 'app-guidebook-event',
  templateUrl: './guidebook-event.component.html',
  styleUrls: ['./guidebook-event.component.css']
})
export class GuidebookEventComponent implements OnInit {
  @Input() public config: any = {};
  @Output() public onSave!: EventEmitter<void>;
  @Input() public onCancel!: EventEmitter<void>;

  private imgFolder="../api/images/events/"
  section: any = {};
  guideSection: any = {};
  eventTypes: string[];
  events: any[] = [];
  places: any[] = [];
  people: any[] = [];

  saveSub: any;
  cancelSub: any;

  constructor(
    private service: GbSharedService,
    private configService: ConfigService,
    private gbService: GuidebookService,
    private peopleSvc: PeopleService,
    private placeSvc: PlaceService,
    private storeImagesSvc: StoreImgsService,
    private deleteImageSvc: DeleteStoredImgsService,
    private modalService: NgbModal,
  ) {
    this.config = this.service.configuration;
    this.onSave = this.service.onSave;
    this.onCancel = this.service.onCancel;
  }

  ngOnInit(): void {
    this.saveSub = this.service.onSave.subscribe(() => {
      this.save();
    });
    this.cancelSub = this.service.onCancel.subscribe(() => {
      this.cancel();
    });
    console.log(this.config);
    this.gbService
      .getGuideBookSectionContent(this.config.uuid)
      .subscribe((data) => {
        this.section = data['records'][0]?data['records'][0]:{};
        console.log(this.section);
        this.guideSection = data['records'][0]?data['records'][0]:{};
        this.events = data['records'][0]?data['records'][0].content.fields:[];
        console.log(this.events);
      });
    this.placeSvc.getPlaceByCat("evento").subscribe(
      data=>{
        this.places = data["records"]?data["records"]:[];
        console.log(this.places)
        
    })
    this.peopleSvc.getPeopleByActivity('Organizzatore Eventi').subscribe((data) => {
      this.people = data['records'];
      console.log(this.people);
    });
    this.configService.getSettingParams(2,['eventType']).subscribe(data=>{
      this.eventTypes = data[0]['vals'];
    })
  }

  remove(index: number) {
    this.events.splice(index, 1);
  }

  cancel() {
    console.log('unsubscribing');
    this.saveSub.unsubscribe();
    this.cancelSub.unsubscribe();
  }

  trackByIndex(index: number, obj: any): any {
    return index;
  }

  _click(s: string) {
    document.getElementById(s).click();
  }

  removeImg(file:string) {
    this.deleteImageSvc.deleteImgs([file])
    .subscribe(data =>{
      console.log(`delete image msg${data}`);
    });
  }

  onImgChange(e, i) {
    const reader = new FileReader();
    let img: string;

    if (e.target.files && e.target.files.length) {
      const [file] = e.target.files;
      reader.readAsDataURL(file);
      reader.onload = () => {
        img = reader.result as string;
        this.storeImagesSvc.saveImgsToPath({files:[img],path:this.imgFolder})
        .subscribe(data =>{
          console.log(<any>data);
          if(this.events[i].imgPath) {this.removeImg(this.events[i].imgPath);}
          this.events[i].imgPath = (<any>data[0]).filePath;
        })
      };
    }
  }

  comparePlace = function (option:{id:string}, value: {id:string}): boolean {
    return option?.id === value?.id
  }

  save(): void {
    console.log(this.events);
    this.guideSection.content = JSON.stringify({fields:this.events});
    this.gbService
      .saveGuideBookSectionContent(this.guideSection)
      .subscribe((data)=> {
        console.log(data);
      }
    );
  }

  addEvent(): void {
    this.events.unshift({
      name: '',
      place: '',
      people:'',
      imgPath: '',
      imgsPaths:[]
    });
    console.log(this.events);
  }

  openModalImage(imgs:any,i:number){
    const modalRef = this.modalService.open(MultiImgModalComponent);
    modalRef.componentInstance.images = imgs;
    modalRef.componentInstance.imgFolder = this.imgFolder;
    modalRef.dismissed.subscribe(()=>{
      
    })
    modalRef.result.then((images) => {
      this.events[i].imgsPaths=images;
      this.save();
    });
    modalRef.componentInstance.imagesChange.subscribe((images)=>{
      console.log('changed');
      console.log(images);
      this.events[i].imgsPaths=images;
      this.save();
    })
  }

}