import { Component, EventEmitter, Input, Output } from '@angular/core';


@Component({
  selector: 'app-modify-delete',
  templateUrl: './modify-delete.component.html',
  styleUrls: ['./modify-delete.component.css'],
  host:{'class':'w-100'}
})
export class ModifyDeleteComponent {
  @Input() target: string;
  @Output() onDelete = new EventEmitter();

  emitDeleteAction(){
    this.onDelete.emit();
  }
}
