<div id="fab-button" class="btn btn-danger position-fixed bottom-0 end-0" (click)="cancel()">
  <fa-icon [icon]="['fas', 'chevron-left']" size="2x" style="margin: 1%"></fa-icon>
</div>
<div class="add-form">
  <div class="input-group mb-1 mt-2">
    <span class="input-group-text" style="background-color: #39ca69; color: white">Area</span>
    <select class="form-select" style="max-width: 20%" name="selectArea" [(ngModel)]="itinerary.area">
      <option [ngValue]="undefined" value="" selected>Select..</option>
      <option *ngFor="let area of areas; let i = index" [disabled]="!area.active" [value]="area.id">
        {{ area.area_name }}
      </option>
    </select>
  </div>
  <div class="input-group mb-3">
    <span class="input-group-text">Name</span>
    <input type="text" [(ngModel)]="itinerary.name" class="form-control" placeholder="Name" aria-label="Name" />
    <!--span class="input-group-text">type</span>
    <input
      type="text"
      class="form-control"
      [(ngModel)]="itinerary.type"
      placeholder="type"
      aria-label="type"
    /-->
  </div>
  <div class="input-group mb-1">
    <span class="input-group-text col-3">Notes</span>
    <textarea class="form-control" aria-label="Notes" [(ngModel)]="itinerary.notes" rows="2"
      placeholder="add a note"></textarea>
  </div>
  <div class="input-group input-group-sm mt-3">
    <span class="input-group-text col-xs-2 col-sm-2 col-md-2 col-lg-2">Image</span>
    <input id="imgFile" type="file" aria-label="File" (change)="onImageChange($event)" name="iconfile"
      class="form-control" style="flex: 1" accept=".jpg,.jpeg,.png,.gif" />
  </div>
  <div class="container mt-1 mb-3 p-0" style="height: auto">
    <div class="row">
      <div *ngIf="itinerary.imgPath" class="col-sm-6 col-md-4 col-lg-3 mt-1 ml-1 mb-1" style="height: auto">
        <span class="input-group-text" aria-label="Image">Image</span>
        <img src="{{ itinerary.imgPath }}" onerror="this.onerror=null;this.src='assets/placeholder.jpg';"
          class="img-fluid" style="object-fit: cover" />
      </div>
    </div>
  </div>

  <!--app-sortable-stepper [(places)]="orderedPlaces" ></app-sortable-stepper-->

  <div class="list-group container mt-1 mb-3 p-1" style="max-height: 400px; background-color: #39ca69">
    <div class="column" style="overflow-y: auto">
      <div class="list-group-item" *ngFor="let place of places; let i = index">
        <div class="form-check">
          <input class="form-check-input" type="checkbox" [checked]="checkIfSelected(place.id)" [value]="place.id"
            (change)="onPlaceCheckChange($event)" />
          <div class="column">
            <div class="">
              <span><small>({{place.id}})</small><strong>{{ place.name }}</strong></span>
              <span style="font-size: x-small">
                GoogleId:{{ place.googleId }}</span>
            </div>
            <div class="">
              <span>{{ place.address }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <app-submit [isNew]="_isNewItinerary" section="Itinerario" (save)="onSubmit()" (cancel)="cancel()"></app-submit>
</div>