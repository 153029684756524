import { Component, EventEmitter, Injectable, Injector, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { GuidebookApiStringComponent } from './guidebook-api-string/guidebook-api-string.component';
import { GuidebookExperienceComponent } from './guidebook-experience/guidebook-experience.component';
import { GuidebookExternalServiceComponent } from './guidebook-external-service/guidebook-external-service.component';
import { GuidebookListComponent } from './guidebook-list/guidebook-list.component';
import { GuidebookContactComponent } from './guidebook-contact/guidebook-contact.component';
import { GbSharedService } from 'src/app/guides/services/gb-shared.service';
import { GuidebookActivityComponent } from './guidebook-activity/guidebook-activity.component';
import { GuidebookEventComponent } from './guidebook-event/guidebook-event.component';
import { GuidebookProductComponent } from './guidebook-product/guidebook-product.component';
import { ConfigService } from 'src/app/guides/services/config.service';
import { combineLatest, tap } from 'rxjs';


@Component({
  selector: 'app-guide-switch',
  templateUrl: './guide-switch.component.html',
  styleUrls: ['./guide-switch.component.css']
})
export class GuideSwitchComponent implements OnInit {
  public dynamicConfigComponent;
  @Output() public onSave!: EventEmitter<void>;
  @Output() public onCancel!: EventEmitter<void>;

  gbContent: any = {} as any;
  section: any;
  config: any;
  submitting = false;
  injector: any;


  constructor(
    private inj: Injector,
    private route: ActivatedRoute,
    private location: Location,
    private sharedService: GbSharedService,
    private configService: ConfigService
  ) {
    this.onSave = this.sharedService.onSave;
    this.onCancel = this.sharedService.onCancel;
  }

  ngOnInit(): void {
    const settings$ = this.configService.getSettingParams(2, ['guidebookType']);
    const params$ = this.route.params;
    combineLatest([settings$, params$]).subscribe(([settings, params]) => {
      let val = settings[0].vals.find((val) => { return val.uuid == params['sectionUuid'] })
      debugger
      let guide = val.content.find((v: any) => { return v.uuid == params['guideUuid'] })
      this.config = guide;
      this.section = settings[0].param;

      this.injector = Injector.create({
        providers: [
          { provide: Config, useValue: this.config }
        ], parent: this.inj
      });

      this.sharedService.configuration = this.config;
      this.sharedService.onSave.subscribe(() => { })
      this.sharedService.uuid = this.config.uuid;
      switch (this.config.type) {
        case 'exp': {
          this.dynamicConfigComponent = GuidebookExperienceComponent;
        }
          break;
        case 'ext': {
          this.dynamicConfigComponent = GuidebookExternalServiceComponent;
        }
          break;
        case 'api': {
          this.dynamicConfigComponent = GuidebookApiStringComponent;
        }
          break;
        case 'list': {
          this.dynamicConfigComponent = GuidebookListComponent;
        }
          break;
        case 'contact': {
          this.dynamicConfigComponent = GuidebookContactComponent;
        }
          break;
        case 'act': {
          this.dynamicConfigComponent = GuidebookActivityComponent;
        }
          break;
        case 'event': {
          this.dynamicConfigComponent = GuidebookEventComponent;
        }
          break;
        case 'product': {
          this.dynamicConfigComponent = GuidebookProductComponent;
        }
          break;
      }


    });
  }

  onSubmit() {
    this.onSave.emit();
  }

  cancel() {
    this.onCancel.emit();
    this.location.back(); // <-- go back to previous location on cancel
    this.sharedService.listSearch = null;
    this.sharedService.uuid = null;

  }

}

@Injectable()
export class Config {
  any
}






//{"name": "come arrivare","uuid": 1,"type": "xfer","content":[{"name":"plane", "active": true, "uuid": 11, "type": "api", "fields": ["dynamicUrl", "jsonRequestObject"] },{"name": "train", "active": true, "uuid": 12, "type": "api", "fields": ["dynamicUrl", "jsonRequestObject"] },{"name":"bus", "active": true, "uuid": 13, "type": "ext" },{"name":"van", "active": true, "uuid": 14, "type": "list", "search": "places", "category": "campeggio" },{"name":"vehicle", "active": true, "uuid": 15, "type": "list", "search": "places", "category": "parcheggio" },{"name":"bike", "active": true, "uuid": 16, "type": "exp" }]}
//{"name":"come muoversi","uuid":2,"type":"xfer","content":[{"name":"metro","active":true,"uuid":21,"type":"ext"},{"name":"sharing","active":true,"uuid":22,"type":"ext"},{"name":"taxi/ncc","active":true,"uuid":23,"type":"list","search":"people","activity":["taxi","ncc"]}]}
//{"name":"dove alloggiare","uuid":3,"type":"services","content":[{"name":"hotel","active":true,"uuid":31,"type":"list","search":"places","category":"hotel"},{"name":"b&b","active":true,"uuid":32,"type":"list","search":"places","category":"b%26b"},{"name":"campeggio","active": true,"uuid": 33,"type": "list","search": "places","category": "campeggio"}]}
//{"name":"dove mangiare","uuid":4,"type":"services","content":[{"name":"ristorante","active":true,"uuid":41,"type":"list","search":"places","category":"ristorante"},{"name":"osteria","active":true,"uuid":42,"type":"list","search":"places","category":"osteria"},{"name":"pub/birreria","active":true,"uuid":43,"type":"list","search":"places","category":"birreria"}]}
//{"name":"tempo libero","uuid":5,"type":"list","content":[{"name":"attività","active":true,"uuid":51,"type":"act","fields":["name","image","places"]},{"name":"eventi","active":true,"uuid":53,"type":"event","fields":["name","places","telno","link","imges","period","videos","audios"]},{"name":"esperienze","active":true,"uuid":52,"type":"exp","fields":["name","place","people","images","video","telno","link"]}]}
//{"name":"numeri utili","uuid":6,"type":"contact","content":[{"name":"pubblici","active":true,"uuid":61,"type":"contact","fields":["name","image","place","telno","link"]},{"name":"privati","active":true,"uuid":62,"type":"contact","fields":["name","image","place","telno","link"]}]}
//{"name":"cosa vedere","uuid":8,"type":"list","content":[{"name":"itinerari","active":true,"uuid":81,"type":"list","search":"itineraries","category":""},{"name":"luoghi","active":true,"uuid":82,"type":"list","search":"places","category":""}]}
//{"name":"prodotti tipici","uuid":9,"type":"list","content":[{"name":"artigianato","active":true,"uuid":91,"type":"product"},{"name":"prodotti tipici","active":true,"uuid":92,"type":"product"},{"name":"ricette della tradizione","active":true,"uuid":93,"type":"product"}]}

//{"name":"come arrivare","uuid":1,"type":"xfer","content":{"plane":{"active":true,"uuid":11,"type":"api","fields":["dynamicUrl","jsonRequestObject"]},"train":{"active":true,"uuid":12,"type":"api","fields":["dynamicUrl","jsonRequestObject"]},"bus":{"active":true,"uuid":13,"type":"ext"},"van":{"active":true,"uuid":14,"type":"list","search":"places","category":"campeggio"},"vehicle":{"active":true,"uuid":15,"type":"list","search":"places","category":"parcheggio"},"bike":{"active":true,"uuid":16,"type":"exp"}}}
//{"name":"come muoversi","uuid":2,"type":"xfer","content":{"metro":{"active":true,"uuid":21,"type":"ext"},"sharing":{"active":true,"uuid":22,"type":"ext"},"taxi/ncc":{"active":true,"uuid":23,"type":"list","search":"people","activity":["taxi","ncc"]}}}
//{"name":"dove alloggiare","uuid":3,"type":"services","content":{"hotel":{"active":true,"uuid":31,"type":"list","search":"places","category":"hotel"},"b&b":{"active":true,"uuid":32,"type":"list","search":"places","category":"b%26b"},"campeggio": {"active": true,"uuid": 33,"type": "list","search": "places","category": "campeggio"}}}
//{"name":"dove mangiare","uuid":4,"type":"services","content":{"ristorante":{"active":true,"uuid":41,"type":"list","search":"places","category":"ristorante"},"osteria":{"active":true,"uuid":42,"type":"list","search":"places","category":"osteria"},"pub/birreria":{"active":true,"uuid":43,"type":"list","search":"places","category":"birreria"}}}
//{"name":"tempo libero","uuid":5,"type":"list","content":{"attività":{"active":true,"uuid":51,"type":"act","fields":["name","image","places"]},"eventi":{"active":true,"uuid":53,"type":"event","fields":["name","places","telno","link","imges","period","videos","audios"]},"esperienze":{"active":true,"uuid":52,"type":"exp","fields":["name","place","people","images","video","telno","link"]}}}
//{"name":"numeri utili","uuid":6,"type":"contact","content":{"pubblici":{"active":true,"uuid":61,"type":"contact","fields":["name","image","place","telno","link"]},"privati":{"active":true,"uuid":62,"type":"contact","fields":["name","image","place","telno","link"]}}}
//{"name":"cosa vedere","uuid":8,"type":"list","content":{"itinerari":{"active":true,"uuid":81,"type":"list","search":"itineraries","category":""},"luoghi":{"active":true,"uuid":82,"type":"list","search":"places","category":""}}}
//{"name":"prodotti tipici","uuid":9,"type":"list","content":{"artigianato":{"active":true,"uuid":91,"type":"product"},"prodotti tipici":{"active":true,"uuid":92,"type":"product"},"ricette della tradizione":{"active":true,"uuid":93,"type":"product"}}}