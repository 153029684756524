import { Component, OnInit, ViewChild } from '@angular/core';
import { Area, Itinerary, Place, Time2visit } from 'src/app/guides/model/interfaces';
import { AreasService } from 'src/app/guides/services/areas.service';
import { PlaceService } from 'src/app/guides/services/place.service';
import { UiService } from 'src/app/services/ui.service';
import { ItinerariesService } from 'src/app/guides/services/itineraries.service';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { Duration } from 'luxon';
import { environment } from 'src/environments/environment';
import { EMPTY, switchMap, map } from 'rxjs';

@Component({
  selector: 'app-add-itinerary',
  templateUrl: './add-itinerary.component.html',
  styleUrls: ['./add-itinerary.component.css'],
})
export class AddItineraryComponent implements OnInit {
  imgpref = environment.imagesPrefix;

  areas: Area[] = [];
  places: Place[] = [];
  selectedPlacesIds: number[] = [];
  orderedPlaces: {
    totTime: Duration;
    steps: {
      id?: number;
      ordinal: number;
      placeId: number;
      duration?: Duration;
    }[];
  } = {} as any;

  itinerary: Itinerary = {} as Itinerary;

  _isNewItinerary: boolean = true;
  _isImageChanged: boolean = false;

  toastHeader: string;
  toastBody: string;

  constructor(
    private route: ActivatedRoute,
    private uiService: UiService,
    private areasService: AreasService,
    private placeService: PlaceService,
    private itinerariesService: ItinerariesService,
    private location: Location
  ) {
    this.route.params.subscribe(({ uuid }) => {
      if (uuid === '') {
        this.itinerary = new Itinerary();
      } else {
        this._isNewItinerary = false;
        /*this.itinerariesService.getItineraryById(uuid).subscribe((itinerary: Itinerary) => {
          this.itinerary = itinerary
        })*/
        this.itinerariesService.getItineraryById(uuid).pipe(
          switchMap((itinerary: Itinerary) => {
            this.itinerary = itinerary;
            return this.itinerariesService.getItineraryPlaces(this.itinerary.id).pipe(
              map((places: any[]) => {
                return this.itinerary.selectedPlaces =
                  places.map((place: any) => {
                    this.selectedPlacesIds.push(place.placeId);
                    return {
                      id: place.id,
                      placeId: place.placeId,
                      ordinal: place.ordinal
                    };
                  });
              })
            )
          })
        ).subscribe(() => {
          this.orderedPlaces.totTime = Duration.fromObject({ hours: 0 });
          this.orderedPlaces.steps = [];
          this.areasService.getAreas().subscribe((data: any) => {
            this.areas = data['records'];
          });
          this.placeService.getAllPlaces().subscribe((data: any) => {
            this.places = data;

            let selLenght = this.itinerary.selectedPlaces.length;
            for (let i = 0; i < selLenght; i++) {
              this.orderedPlaces.steps.push(
                this.itinerary.selectedPlaces.find((p) => p.ordinal == i)
              );
              let time2visit: Time2visit =
                this.places.find((pl) => pl.id == this.orderedPlaces.steps[i].placeId).time2visit;
              this.orderedPlaces.steps[i].duration = Duration.fromObject({
                hours: time2visit.max,
              });
              this.orderedPlaces.totTime.plus(this.orderedPlaces.steps[i].duration);
            }
            console.log(this.orderedPlaces);
          });
          console.log(this.itinerary);
        })
      }
    });
  }

  ngOnInit(): void {

    //this.areasService.getAreas().subscribe((data) => {
    //this.areas = data['records'];
    //this.placeService.getAllPlaces().subscribe((data) => {
    //this.places = data['records'];
    /*this.orderedPlaces.map(op=>{
          
          let time2visit:Time2visit = JSON.parse(
            this.places.find(
              p=>p.id == op.placeId
            ).time2visit);
          //let minD:Duration = Duration.fromObject({ hours: <number>time2visit.min })
          let maxD:Duration = Duration.fromObject({ hours: <number>time2visit.max })
          //let minD:Duration = Duration.fromObject({ hours: 0.5 })
          //let maxD:Duration = Duration.fromObject({ hours: 0.5 })
          
          op.duration = Duration.fromObject({hours: maxD.as('hours')})
          console.log(op.duration)
        })*/
    //});
    //console.log(this.areas)
    //});
  }

  onImageChange(e) {
    this._isNewItinerary ? null : (this._isImageChanged = true);
    const reader = new FileReader();

    if (e.target.files && e.target.files.length) {
      const [file] = e.target.files;
      reader.readAsDataURL(file);

      reader.onload = () => {
        this.itinerary.imgFile = reader.result as string;
        this.itinerary.imgPath = reader.result as string;
      };
    }
  }

  onPlaceCheckChange(e) {
    console.log(this.orderedPlaces);
    //console.log(e.target.value);
    let pId = Number(e.target.value as number);
    //console.log(pId)
    //console.log(Number(pId));
    if (e.target.checked) {
      if (this._isNewItinerary) {
        this.itinerary.selectedPlaces.push({
          placeId: pId,
          ordinal: this.itinerary.selectedPlaces.length,
        });
      } else {
        if (
          this.itinerary.placesToRemove.indexOf(pId) > -1
        ) {
          this.itinerary.placesToRemove.splice(
            this.itinerary.placesToRemove.indexOf(pId),
            1
          );
        } else {
          this.itinerary.placesToAdd.push({
            placeId: pId,
            ordinal: this.itinerary.selectedPlaces.length,
          });
        }
      }
      this.orderedPlaces.steps.push({
        placeId: pId,
        ordinal: this.orderedPlaces.steps.length,
        duration: Duration.fromObject({
          hours: <number>(
            (<Time2visit>(this.places.find((p) => p.id == pId).time2visit)).max
          ),
        }),
      });
      this.orderedPlaces.totTime.plus(
        this.orderedPlaces.steps[this.orderedPlaces.steps.length - 1].duration
      );
    } else {
      if (this._isNewItinerary) {
        let placePosition: number = this.itinerary.selectedPlaces
          .map(place => place.placeId)
          .indexOf(pId);
        this.itinerary.selectedPlaces.splice(placePosition, 1);
      } else {

        let placePosition: number = this.itinerary.placesToAdd
          .map(place => place.placeId)
          .indexOf(pId);
        if (placePosition > -1) {
          this.itinerary.placesToAdd.splice(placePosition, 1);
        } else {
          this.itinerary.placesToRemove.push(pId);
        }

      }

      let index = this.orderedPlaces.steps
        .map(p => p.placeId)
        .indexOf(pId);
      if (index > -1) {
        this.orderedPlaces.totTime.minus(
          this.orderedPlaces.steps[index].duration
        );
        this.orderedPlaces.steps.splice(index, 1);
      }
    }
    console.log(this.itinerary.selectedPlaces);
    console.log(this.itinerary.placesToAdd);
    console.log(this.itinerary.placesToRemove);
    console.log(this.orderedPlaces);
  }

  checkIfSelected(placeId): boolean {
    if (!this._isNewItinerary) {
      return this.selectedPlacesIds.indexOf(placeId) > -1 ? true : false;
    } else {
      return false;
    }
  }

  cancel() {
    this.location.back(); // <-- go back to previous location on cancel
  }

  onSubmit() {
    if (this._isNewItinerary) {
      this.itinerariesService
        .addItinerary(this.itinerary)
        .subscribe((savedItineraryId) => {
          let selectedPlaces = this.itinerary.selectedPlaces.map((place) => {
            return {
              itineraryId: savedItineraryId,
              placeId: place.placeId,
              ordinal: place.ordinal,
            };
          });
          this.itinerariesService
            .assignPlacesToItinerary(selectedPlaces)
            .subscribe((data) => {
              console.log(data);
            });
          //alert('itinerary added');
        });
    } else {
      let imgBkUp;
      if (!this._isImageChanged) {
        imgBkUp = this.itinerary.imgPath;
        delete this.itinerary.imgPath;
      }
      this.itinerariesService
        .updateItinerary(this.itinerary)
        .subscribe((data) => {
          /*let selectedPlaces = this.itinerary.selectedPlaces.map((pId) => {
            return { itineraryId: data, placeId: pId };
          });*/
          let removing = [];
          removing.push(
            this.itinerary.placesToRemove.map((i) => {
              return this.itinerary.selectedPlaces.find((j) => {
                let k = j['placeId'] == i;
                console.log(k);
                return k;
              })['id'];
            })
          );
          if (removing[0].length) {
            console.log(removing.join(','));
            this.itinerariesService
              .deleteAssignedPlacesToItinerary(removing.join(','))
              .subscribe((data) => {
                console.log(data);
              });
          }
          if (this.itinerary.placesToAdd.length) {
            console.log(this.itinerary.placesToAdd);
            this.itinerariesService
              .assignPlacesToItinerary(
                this.itinerary.placesToAdd.map((place) => {
                  return {
                    itineraryId: this.itinerary.id,
                    placeId: place.placeId,
                    ordinal: place.ordinal,
                  };
                })
              )
              .subscribe((data) => {
                console.log(data);
              });
          }
        });
      this.itinerary.imgPath = imgBkUp;
    }

    //alert('itinerary updated');
    //TODO: ALERT
  }
}
