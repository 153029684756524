import { Component, OnInit, ViewChild } from '@angular/core';
import { AreasService } from 'src/app/guides/services/areas.service';
import { Place } from 'src/app/guides/model/interfaces';
import { NavigationExtras, Router } from '@angular/router';
import { PlaceService } from 'src/app/guides/services/place.service';

@Component({
  selector: 'app-places',
  templateUrl: './places.component.html',
  styleUrls: ['./places.component.css'],
})
export class PlacesComponent implements OnInit {
  
  area: string = '';
  availAreas = [];
  places: Place[] = [];
  loading: boolean = true;
  datalist: Element;


  constructor(
    private router: Router,
    private placeService: PlaceService,
    private areasService: AreasService,
    ) {}

  ngOnInit(): void {
    this.loading = true;
    this.areasService.getAreas().subscribe((data) => {
      this.availAreas = data['records'];
    });
    this.placeService.getAllPlaces().subscribe((data) => {
      this.places = data;
    });
    this.loading = false;
    //this.placeService.getPlace().subscribe((data) => {this.places = data['records']});
  }

  onSelectArea(area: string) {
    if (area || area != '') {
      this.placeService.getSelectedAreaPlaces(area).subscribe((data) => {
        this.places = data['records'];
      });
    } else {
      this.placeService.getAllPlaces().subscribe((data) => {
        this.places = data['records'];
      });
    }
  }

  /*onFilterByName(e) {
    console.log(e);
    console.log(this.datalist);
    let elem:Element = document.getElementById('datalistOptions');
    let elem1:Element = document.getElementById('PlacesDataList');
    console.log(elem);
    console.log(elem1);
    }*/

  deletePlace(place: Place) {
    this.placeService.deletePlace(place).subscribe((data) => {
      console.log(data);
      this.places = this.places.filter((t) => t.id !== place.id);
      //TODO ALERT
    });
  }

  /*toggleReminder(place: Place) {
    //place.reminder = !place.reminder;
    this.placeService.savePlace(place).subscribe();
  }*/

  /*addPlace(place: Place) {
    this.placeService
      .savePlace(place)
      .subscribe((placeId) => (place.id = placeId));
  }*/
}
