import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EMPTY, Observable, map, take, tap } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Guidebook, Setting } from 'src/app/guides/model/interfaces';
import { ConfigService } from 'src/app/guides/services/config.service';
import { DeleteStoredImgsService } from 'src/app/common/services/delete-stored-imgs.service';
import { StoreImgsService } from 'src/app/common/services/store-imgs.service';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
  }),
};

@Injectable({
  providedIn: 'root',
})
export class GuidebookService {
  private apiUrl = `${environment.dbApiBaseDir}guidebooks`;
  /* !!! REFACTOR remove setting from name !!! */
  private guideBookSectionApiUrl = `${environment.dbApiBaseDir}guidebookSections`;
  private guideBookSectionContentApiUrl = `${environment.dbApiBaseDir}guidebookSectionContent`;

  constructor(
    private http: HttpClient,
    private storeImgsService: StoreImgsService,
    private deleteStoredImgsService: DeleteStoredImgsService,
    private configService: ConfigService
  ) { }

  getGuideBooksConfig(area): Observable<Setting> {
    return this.configService.getSettingParams(area, ['guidebookType']).pipe(
      take(1),
      //tap(data=>{debugger})
      map((setting: Setting[]) => { return setting[0] })
    );
  }

  getGuideBookSetting(): Observable<any> {
    return this.http.get<any>(this.apiUrl);
  }

  saveGuideBookSection(setting: any): Observable<number> {
    const url = `${this.guideBookSectionApiUrl}${setting.id ? '/' + setting.id : ''}`;
    if (setting.id) {
      return this.http.put<number>(url, setting, httpOptions);
    } else {
      return this.http.post<number>(this.apiUrl, setting, httpOptions);
    }
  }

  saveGuideBookSectionContent(content: any): Observable<number> {
    const url = `${this.guideBookSectionContentApiUrl}${content.id ? '/' + content.id : ''}`;
    if (content.id) {
      return this.http.put<number>(url, content, httpOptions);
    } else {
      return this.http.post<number>(this.apiUrl, content, httpOptions);
    }
  }

  getGuideBookSection(params: { area?: number, uuid?: number }): Observable<any[]> {
    //return this.http.get<any[]>(this.guideBookSectionApiUrl+"?filter=active,eq,1&include=id,area,uuidRefer,ordinal,name,type,relatedGame,notes"+(params.area?"&filter=area,eq,"+params.area:"")+(params.uuid?"&filter=uuidRefer,eq,"+params.uuid:"")+"&order=ordinal,desc");
    return this.http.get<any>(this.guideBookSectionApiUrl + "?include=id,area,uuid,ordinal,name,type,relatedGame,notes" + (params.area ? "&filter=area,eq," + params.area : "") + (params.uuid ? "&filter=id,eq," + params.uuid : "") + "&order=ordinal,desc").pipe(
      take(1),
      map(({ records }) => {
        return records[0]
      })
    );
  }

  getGuideBookSectionContent(uuid?: number): Observable<any> {
    return this.http.get<any>(this.guideBookSectionContentApiUrl + "?filter=active,eq,1&include=id,guidebookSectionId,uuid,name,type,ordinal,content" + (uuid ? "&filter=id,eq," + uuid : "")).pipe(
      take(1),
      map(({ records }) => {
        return records.length ? records[0] : []
      })
    );
  }

  getGuideBookSectionContents(guidebookSectionSettingId: number): Observable<any[]> {
    return this.http.get<any[]>(this.guideBookSectionContentApiUrl + "?filter=active,eq,1&filter=guidebookSectionId,eq," + guidebookSectionSettingId)
  }

  getGuideBooks(): Observable<Guidebook[]> {
    return this.http.get<Guidebook[]>(this.apiUrl);
  }

  addGuideBooks(): Observable<any[]> {
    return EMPTY;
  }

  deleteGuidebook(guidebook: Guidebook) { }
}
