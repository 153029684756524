import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { EMPTY, forkJoin, Observable, of } from 'rxjs';
import { Setting } from '../model/interfaces';
import { concatMap, map, switchMap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
  }),
};

@Injectable({
  providedIn: 'root',
})
export class ConfigService {
  private settingParamsApi = `${environment.dbApiBaseDir}viewSettingParams`;
  
  constructor(private http: HttpClient) {}

  loadSettings(area): boolean {
    let url = `${this.settingParamsApi}?filter=area,eq,${area}`;
    this.http.get<Setting[]>(url).subscribe((data) => {
      data['records'].array.forEach((element) => {
        sessionStorage.setItem(element.param, element.value);
      });
    });
    console.log(sessionStorage);
    return true;
  }

  getSettingParams(area?:number,param?:string[]): Observable<Setting[]> {
    let url = `${this.settingParamsApi}${area?'?filter=area,eq,'+area:''}${param?'&filter=param,in,'+[...param]:''}`;
    console.log(url);
    return this.http.get<any>(url).pipe(
      map(({records}) => {
        let settings: Setting[] = records;
        /*settings.map(s=>{
          s.vals = JSON.parse(<string><any>s.vals);
        })*/
        return settings;
      })
    );
  }

  /*getSettingParams(filter?:{area:number,param:string[]}): Observable<Setting[]> {
    let url = `${this.settingParamsApi}${filter?'?filter=area,eq,'+filter.area+'&param,in,'+[...filter.param]:''}`;
    console.log(url);
    return this.http.get<Setting[]>(url).pipe(
      map((data) => {
        let settings: Setting[] = [];
        let result: any[] = data['records'];
        settings = result;
        return settings;
      })
    );
  }*/

  splitPairToKeyValue(pair:string,separator:string):{k: string,v: string}{
    let str: string[] = pair.split(separator);
    return {k:str[0],v:str[1]}
  }

  //TODO:GENERAL DECODING FUNCTION
  //settingsDecode(arraySetting):any{}
  //LOOPS OVER SETTINGS, SWITHES ON settings.type

  //MYSQL DEFINER ERROR: https://stackoverflow.com/questions/10169960/mysql-error-1449-the-user-specified-as-a-definer-does-not-exist
  /*
    SELECT CONCAT("ALTER DEFINER=`youruser`@`host` VIEW ", 
    table_name, " AS ", view_definition, ";") 
    FROM information_schema.views 
    WHERE table_schema='your-database-name';
  */ 
}
