import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { GbSharedService } from 'src/app/guides/services/gb-shared.service';
import { ItinerariesService } from 'src/app/guides/services/itineraries.service';
import { PeopleService } from 'src/app/guides/services/people.service';
import { ItinerarieslistComponent } from './itinerarieslist/itinerarieslist.component';
import { PeoplelistComponent } from './peoplelist/peoplelist.component';
import { PlaceslistComponent } from './placeslist/placeslist.component';
import { PlaceService } from 'src/app/guides/services/place.service';

@Component({
  selector: 'app-guidebook-list',
  templateUrl: './guidebook-list.component.html',
  styleUrls: ['./guidebook-list.component.css'],
})
export class GuidebookListComponent implements OnInit {
  public dynamicListComponent;
  @Input() public config: any = {};
  //@Output() public onSave!: EventEmitter<void>;
  constructor(
    private service: GbSharedService,
    private placeService: PlaceService,
    private itinerariesService: ItinerariesService,
    private peopleService: PeopleService
  ) {
    this.config = this.service.configuration;
    //this.onSave = this.service.onSave;
  }
  //search: any;
  ngOnInit(): void {
    console.log(this.config);
    switch (this.config.search) {
      case 'places':
        {
          if (this.config.category) {
            this.placeService.getPlaceByCat(this.config.category).subscribe((data) => {
              this.service.listSearch = data['records'];
              this.dynamicListComponent = PlaceslistComponent;
            });
          } else {
            this.placeService.getAllPlaces().subscribe((data) => {
              this.service.listSearch = data;
              this.dynamicListComponent = PlaceslistComponent;
            });
          }
          
        }
        break;
      case 'itineraries':
        {
          this.itinerariesService.getItineraries().subscribe((data) => {
            this.service.listSearch = data['records'];
            this.dynamicListComponent = ItinerarieslistComponent;
          });
          
        }
        break;
      case 'people':
        {
          this.peopleService.getPeople().subscribe((data) => {
            this.service.listSearch = data['records'];
            this.dynamicListComponent = PeoplelistComponent;  
          });
          
        }
        break;
    }
    //this.search = [];
  }
}
