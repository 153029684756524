import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ConfigService } from 'src/app/guides/services/config.service';
import { environment } from 'src/environments/environment';
import { EMPTY, Observable } from 'rxjs';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
  }),
};

@Injectable({
  providedIn: 'root'
})
export class QuizService {
  private apiUrl = `${environment.dbApiBaseDir}questions`;

  constructor(
    private http: HttpClient,
    private configService: ConfigService
  ){ }

  getQuestionPropertiesConfig():Observable<any>{
    return EMPTY;
  }

  getQuestions():Observable<any>{
    return this.http.get<any>(`${this.apiUrl}?include=id,text,category,difficulty`,httpOptions);
  }

  getQuestion(id:any):Observable<any>{
    return this.http.get<any>(`${this.apiUrl}/${id}`,httpOptions)
  }

  saveQuestion(question:any):Observable<any>{
    if(question.id){
      return this.http.put<any>(`${this.apiUrl}/${question.id}`,question,httpOptions)
    }else{
      return this.http.post<any>(this.apiUrl,question,httpOptions)
    }
  }

  deleteQuestion(id:any):Observable<any>{
    return this.http.delete<any>(`${this.apiUrl}/${id}`,httpOptions)
  }
}
