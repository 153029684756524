import { v4 as uuidv4 } from 'uuid';
import { LatLngLiteral } from 'ngx-google-places-autocomplete/objects/latLng';

export class Area {
  constructor(){
    this.uuid = uuidv4();
  }
  id?: number;
  uuid:string;
  desc:string;
  area_name: string;
  latlng: LatLngLiteral;
  active: boolean;
  image?: File;
  imgName: string;
}

//type vals<Type> = Type | Type[];

export interface Setting {
  param: string;
  type: number;
  area: number;
  vals: any[];
  valsArray?: string[];
  //vals: vals<string>
}

export interface Cost {
  cat: string;
  val: string;
}
export interface Time2visit {
  min: number;
  max: number;
}
//export interface LatLong { lat:number; long: number}

export class Place {
  constructor(){
    this.uuid = uuidv4();
  }
  id?: number;
  uuid: string;
  area: number;
  googleName: string;
  name: string;
  googleId: string;
  address: string;
  phone:string;
  email:string;

  googlePlacesObj: any;

  placeType: string; //museo monumento parco palazzo ristorante bar/pub discoteca panorama
  placeClass: string; //aperto coperto prato ghiaia cemento asfalto
  description: string;
  arrival: boolean|number;
  mapIconPath: string;
  oldMapIconPath?: string;
  placeImages: Image[];
  images:any;
  spareTime: boolean|number;
  vacancies: boolean|number;
  costs: Cost[];
  rating: number;
  tags: string;
  advisor: string;

  time2visit: Time2visit;
  rank: number;
  evals?: string;
  notes: string;

  active: boolean;
}

export class Itinerary {
  constructor(){
    this.uuid = uuidv4();
  }
  id?: number;
  uuid:string;
  area: number;
  name: String;
  type: string;
  notes: string;
  imgPath: string;
  imgFile?: string;
  selectedPlaces?: {id?:number; placeId:number,ordinal:number}[];
  placesToRemove?: number[];
  placesToAdd?: {placeId:number,ordinal:number}[];
}

export interface Guidebook {
  id?: number;
  area: number;
  name: string;
  type: string;
  iconPath: string;
  coverImagePath: string;
  description: string;
  images: Image;
  notes: string;
  specs?:{
    [spec:string]:boolean;
  }
}

export interface Image {
  id?: number;
  name?: string;
  path: string;
  title: string;
  desc: string;
  storedPath?: string;
  file?: string;
}

export class Person {
    constructor(){
      this.uuid = uuidv4();
    }
  id?: number;
  uuid:string;
  area: number;
  name: string;
  surname: string;
  activity: string;
  city: string;
  phone:string;
  email:string;
  description: string;
  notes:string;
  profileImgPath: string;
  oldProfileImgPath?: string;
  bookable: number|boolean;
  professional: number|boolean;
  tags: string|string[];
  rating: number;
  rank: number;
  evals?: string;
  active: boolean;
}


/*export interface GuideBookSectionSetting{
  id?:number;
  ordinal:number;
  uuid:number;
  area:number;
  notes:string;
  name:string;//"come arrivare","come muoversi","dove alloggiare","mangiare","tempo libero","contatti","community"
  type:string;//"xfer","services","list","people"
  content?:{
    [contentType:string]: ContentTypeLink |
                          ContentTypeList | 
                          ContentTypeApiString |
                          ContentTypePeople;
  }
}


export interface GBContentType{
  active:boolean;
  type:string;
}

export interface ContentTypeLink extends GBContentType {
  [index: number]:{link:string};
}

export interface ContentTypeApiString extends GBContentType {
  [index: number]:{fields:string[]}
}

export interface ContentTypeList extends GBContentType {
  [index: number]:{  
    searchItem:string;
    searchColumn:string[];
    searchValue:string[];
  }
}

export interface ContentTypePeople extends GBContentType {}*/

//"bus":{"active":false},"van":{"active":true,"type":"list","search":"places","type":"parking"},"vehicle":{"active":true},"bike":{"active":true}