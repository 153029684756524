import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { DevelService } from 'src/app/services/devel.service';
import { PeopleService } from 'src/app/guides/services/people.service';
import { XferService } from 'src/app/services/xfer.service';
import { StateService } from 'src/app/services/state.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmModalComponent } from 'src/app/common/components/confirm-modal/confirm-modal.component';

@Component({
  selector: 'app-devel',
  templateUrl: './devel.component.html',
  styleUrls: ['./devel.component.css'],
})
export class DevelComponent implements OnInit {
  //trainSolutions:{[t:string]:any,data?:any} = {}
  trainSolutions:any = {} as any;
  planeSolutions:any = {} as any;
  trainSearch:{origin:string,date:string,time:number} = {origin:"chieri",date:"20/12/2021",time:17};
  planeSearch:{origin:string,date:string} = {origin:"FCO",date:"2021-12-25"}
  images:string[] = [];
  removing:string = '';
  myForm = new UntypedFormGroup({
    name: new UntypedFormControl('', [Validators.required, Validators.minLength(3)]),
    file: new UntypedFormControl('', [Validators.required]),
    fileSource: new UntypedFormControl('', [Validators.required]),
  });

  toJSON:string = "";
  toString:string = "";

  constructor(
    private develSvc: DevelService,
    private http: HttpClient,
    private peopleService:PeopleService,
    private xfer:XferService,
    private stateService: StateService,
    private modalService: NgbModal
    ) {}

  ngOnInit(): void {
    this.planeSolutions.data = [];
    //this.getPlanes()
  }

  get f() {
    return this.myForm.controls;
  }

  onFileChange(event) {
    if (event.target.files && event.target.files[0]) {
      var filesAmount = event.target.files.length;
      for (let i = 0; i < filesAmount; i++) {
        var reader = new FileReader();

        reader.onload = (event: any) => {
          console.log(event.target.result);
          this.images.push(event.target.result);
          console.log(this.images)

          this.myForm.patchValue({
            fileSource: this.images,
          });
        };

        reader.readAsDataURL(event.target.files[i]);
      }
      console.log(this.images)
    }
  }

  removeImg(i:number){
    console.log(this.images)
    this.images.splice(i,1);
  }

  removeImgs(){
    let removing:string[] = this.removing.split(",");
    removing = removing.map(x=>"images/devel/"+x);
    this.deleteImgs(removing).subscribe(data=>{
      console.log(data);
    })
  }

  deleteImgs(imgs:string[]):Observable<string[]>{
    return this.develSvc.deleteImgs(imgs)
  }

  getTrains(){
    this.trainSolutions = [];
    this.xfer.getTrenitaliaSolutions(this.trainSearch).subscribe(data=>{
      //console.log(data)
      this.trainSolutions = data;
    })
  }

  alert(type:string){
    this.stateService.message$.next({text:"prova",type:type})
  }

  confirm(){
    const modalRef = this.modalService.open(ConfirmModalComponent);
    modalRef.componentInstance.alertTarget = 'test';
  }

  getPlanes(){
    this.planeSolutions = [];
    this.xfer.getAmadeusPlaneSolutions(this.planeSearch).subscribe(data=>{
      this.planeSolutions = data
    })
  }

  submit() {
    const value:string[] = this.myForm.value['fileSource'];
    const path:string = "images/devel/"
    this.develSvc.storeImgs({files: value, path: path}).subscribe(data=>{
      console.log(data)
    })
    
  }
}
