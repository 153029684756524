import { Component, ViewChild } from '@angular/core';
import packageJson from '../../package.json';
import { StateService } from './services/state.service';
import { NgbAlert } from '@ng-bootstrap/ng-bootstrap';
import { tap } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  public version: string = packageJson.version;
  title = 'tourismappAdmin';

  activeRoute:string;
  
  staticAlertClosed = false;
	message : {text:string, type:string} | undefined = undefined;// {text:"prova",type:"success"};
 
	@ViewChild('selfClosingAlert', { static: false }) selfClosingAlert!: NgbAlert;

  /*constructor(private areasService:AreasService){
    this.areasService.getAreas().subscribe((data)=>{
      this.areas = data['records'];
    })
  }*/
  constructor(public stateService: StateService){
    localStorage.setItem("section",null);
  //constructor(private configService:ConfigService,private router: Router){
    /*this.configService.getSettingParams().subscribe((data)=>{
       sessionStorage.setItem('settings',data['records']); 
    })*/
    //this.configService.loadSettings();
  }

  ngOnInit():void{
    /*setInterval(()=>{
      if(this.message==null){
        this.message={text:"prova",type:"success"};
      }else{
        //this.message=null
      }
    },500)*/
    this.stateService.message$.pipe(
      tap(()=>{
          setTimeout(() => { 
            if (this.selfClosingAlert) {
              this.selfClosingAlert.close();
            }
          },2500)
        }
      )
    ).subscribe((message) => {
      this.message = message;
    });
  }

  /*ngOnInit(): void {
    this.router.events
      .pipe(
      filter(event => event instanceof NavigationEnd)
      )
      .subscribe((event: NavigationEnd) => {
          this.activeRoute = event.url;
          console.log(this.activeRoute);
          //this.isHome = event.url === "/"; // Toggle a boolean based on url
      });
  }*/
}
