import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Itinerary, Setting } from '../model/interfaces';
import { StoreImgsService } from 'src/app/common/services/store-imgs.service';
import { DeleteStoredImgsService } from 'src/app/common/services/delete-stored-imgs.service';
import { map, switchMap, take } from 'rxjs/operators';
import { ConfigService } from './config.service';
import { environment } from 'src/environments/environment';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
  }),
};

@Injectable({
  providedIn: 'root',
})
export class ItinerariesService {
  private imgFolder = `${environment.imagesBaseDir}itineraries/`;
  private apiUrl = `${environment.dbApiBaseDir}itineraries`;
  private bindApiUrl = `${environment.dbApiBaseDir}placeItineraryBind`;

  constructor(
    private http: HttpClient,
    private storeImgsService: StoreImgsService,
    private deleteStoredImgsService: DeleteStoredImgsService,
    private configService: ConfigService
  ) { }

  getItineraries(): Observable<Itinerary[]> {
    return this.http.get<Itinerary[]>(this.apiUrl);
  }

  getItineraryName(id: number): Observable<any[]> {
    const url = `${this.apiUrl}/${id}?include=name`;
    return this.http.get<any[]>(url);
  }

  getItineraryById(id: string): Observable<any> {
    const url = `${this.apiUrl}/${id}`;
    return this.http.get<any>(url).pipe(
      take(1));
  }

  getItineraryByUuid(uuid: string): Observable<any> {
    const url = `${this.apiUrl}?filter=uuid,eq,${uuid}`;
    return this.http.get<any>(url).pipe(
      take(1),
      map(({ records }) => { return records[0] }));
  }

  deleteItinerary(itinerary: Itinerary): Observable<number> {
    const url = `${this.apiUrl}/${itinerary.id}`;
    return this.deleteStoredImgsService.deleteImgs([itinerary.imgPath]).pipe(
      switchMap(data => {
        return this.http.delete<any>(url);
      })
    );
  }

  updateItinerary(itinerary: Itinerary): Observable<number> {
    const url = `${this.apiUrl}/${itinerary.id}`;
    if (itinerary.imgPath) {
      return this.storeImgsService
        .saveImgsToPath({ files: [itinerary.imgPath], path: this.imgFolder })
        .pipe(
          switchMap(data => {
            itinerary.imgPath = data[0]['filePath'];
            return this.http.put<any>(url, itinerary, httpOptions);
          })
        );
    } else {
      return this.http.put<any>(url, itinerary, httpOptions);
    }
  }

  addItinerary(itinerary: Itinerary): Observable<any> {
    if (itinerary.imgFile) {
      return this.storeImgsService
        .saveImgsToPath({ files: [itinerary.imgFile], path: this.imgFolder })
        .pipe(
          switchMap((data) => {
            itinerary.imgPath = data[0]['filePath'];
            return this.http.post<any>(this.apiUrl, itinerary, httpOptions);
          })
        );
    } else {
      return this.http.post<any>(this.apiUrl, itinerary, httpOptions);
    }
  }

  assignPlacesToItinerary(places: {}[]) {
    const url = this.bindApiUrl;
    return this.http.post<any>(url, places, httpOptions);
  }

  deleteAssignedPlacesToItinerary(placesString: string) {
    const url = `${this.bindApiUrl}/${placesString}`;
    return this.http.delete<any>(url);
  }

  getItineraryPlaces(itineraryId: number) {
    //const url = `${this.bindApiUrl}?filter=itineraryId,eq,${itineraryId}&include=placeId`;
    const url = `${this.bindApiUrl}?filter=itineraryId,eq,${itineraryId}&include=placeId,id,ordinal`;
    return this.http.get<any>(url).pipe(
      take(1),
      map(({ records }) => { return records }));
  }
}
