<div role="button" class="btn btn-primary sticky-top" (click)="addContact()">
  Add new
</div>
<div
  *ngFor="let contact of contacts; let i = index"
  class="contact-box border p-2 mb-4"
>
  <fa-icon
    [icon]="['fas', 'trash']"
    size="lg"
    class="ml-auto"
    style="color: red; position: relative; top: 0rem; right: 0rem; float: right"
    (click)="remove(i)"
  ></fa-icon>
  <div class="d-flex flex-row flex-wrap justify-content-between p-1">
    <div class="p-2 d-flex w-100">
      <div class="p-2 flex-grow-1">
        <div class="form-group mb-1">
          <label [for]="name[i]">Nome</label>
          <input
            type="text"
            class="form-control"
            id="name"
            required
            [(ngModel)]="contacts[i].name"
            name="name"
            #name="ngModel"
          />
        </div>
        <div class="form-group mb-1">
          <label for="link">Link</label>
          <input
            type="text"
            class="form-control"
            id="link"
            required
            [(ngModel)]="contacts[i].link"
            name="link"
            #link="ngModel"
          />
        </div>
        <div class="input-group d-flex flex-row flex-nowwrap mb-1">
          <span class="input-group-text"
            ><fa-icon [icon]="['fas', 'phone']"></fa-icon
          ></span>
          <input
            type="number"
            class="form-control"
            id="phone"
            required
            [(ngModel)]="contacts[i].telno"
            name="phone"
            #phone="ngModel"
          />
        </div>
      </div>
      <div class="border p-2 ms-auto">
        <div
          class="d-flex flex-grow-1 p-1"
          style="max-height: 200px; aspect-ratio: 1/1; background-color: gray"
        >
          <img
            src="{{ contact.imgPath }}"
            (click)="_click('img' + i)"
            onerror="this.onerror=null;this.src='assets/placeholder.jpg';"
            class="img-fluid border"
            style="overflow: hidden; object-fit: cover"
          />
          <div hidden class="input-group input-group-sm mt-3">
            <input
              type="file"
              aria-label="File"
              (change)="onImgChange($event, i)"
              name="img{{ i }}"
              id="img{{ i }}"
              class="form-control"
              accept=".jpg,.jpeg,.png,.gif"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="w-100">
      <select
        class="form-select col-auto"
        name="place{{ i }}"
        id="place{{ i }}"
        [(ngModel)]="contacts[i].place"
      >
        <option
          [ngValue]="undefined"
          value=""
          selected disabled
        >
          Posizione
        </option>
        <option *ngFor="let place of places" [value]="place.id">
          {{ place.name }}
        </option>
      </select>
    </div>
  </div>
</div>
