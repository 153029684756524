<div id="fab-button" class="btn btn-danger position-fixed bottom-0 end-0" (click)="cancel()">
  <fa-icon [icon]="['fas', 'chevron-left']" size="2x" style="margin: 1%"></fa-icon>
</div>
<div class="container mw-100 mt-1">
  <div *ngIf="config">
    <h3>CONFIGURA {{ config.name | uppercase }}</h3>
  </div>


  <div class="w-100 h-100 border align-content-center justify-content-center p-3 mb-2">
    <ng-container *ngComponentOutlet="dynamicConfigComponent; injector:injector" [config]="config"></ng-container>
  </div>

  <app-submit [isNew]="true" [disabled]="submitting" section="Guida" (save)="onSubmit()"
    (cancel)="cancel()"></app-submit>
</div>