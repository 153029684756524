import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmModalComponent } from 'src/app/common/components/confirm-modal/confirm-modal.component';
import { Itinerary, Place } from 'src/app/guides/model/interfaces';
import { ItinerariesService } from 'src/app/guides/services/itineraries.service';
import { PlaceService } from 'src/app/guides/services/place.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-itinerary-item',
  templateUrl: './itinerary-item.component.html',
  styleUrls: ['./itinerary-item.component.css'],
})
export class ItineraryItemComponent implements OnInit {
  @Input() itinerary: Itinerary;
  @Output() onDeleteItinerary: EventEmitter<Itinerary> = new EventEmitter();
  @Output() onToggleReminder: EventEmitter<Itinerary> = new EventEmitter();

  imgpref = environment.imagesPrefix;

  constructor(
    private itineraryService: ItinerariesService,
    private placeService: PlaceService,
    private router: Router,
    private modalService: NgbModal
  ) {}

  itineraryPlaces = [];
  places: Place[] = [];

  ngOnInit(): void {
    this.itineraryService
      .getItineraryPlaces(this.itinerary.id)
      .subscribe((data) => {
        let array: [] = data['records'];
        if (array.length) {
          this.itineraryPlaces = array;

          console.log(this.itineraryPlaces);
          let placeIds: number[] = [];
          this.itineraryPlaces.map((i) => {
            placeIds.push(i['placeId']);
          });
          this.placeService.getSelectedPlaces(placeIds).subscribe((data) => {
            this.places = data['records'];
          });
        }
      });
  }

  /*enableModify() {
    this.itinerary.selectedPlaces = this.itineraryPlaces;
    let navigationExtras: NavigationExtras = {
      queryParams: {
        itinerary: JSON.stringify(this.itinerary),
      },
    };
    this.router.navigate(['/addItinerary'], navigationExtras);
  }*/

  onDelete(itinerary) {
    const modalRef = this.modalService.open(ConfirmModalComponent);
    modalRef.componentInstance.alertTarget = 'luogo';
    
    modalRef.result.then(() => {
      this.onDeleteItinerary.emit(itinerary);
      
    })
  }

  onToggle(itinerary) {
    this.onToggleReminder.emit(itinerary);
  }
}
