import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Itinerary } from 'src/app/guides/model/interfaces';
import { AreasService } from 'src/app/guides/services/areas.service';
import { ItinerariesService } from 'src/app/guides/services/itineraries.service';
import { PlaceService } from 'src/app/guides/services/place.service';

@Component({
  selector: 'app-itineraries',
  templateUrl: './itineraries.component.html',
  styleUrls: ['./itineraries.component.css'],
})
export class ItinerariesComponent implements OnInit {
  
  area: string = '';
  availAreas = [];
  loading: boolean = true;
  itineraries: Itinerary[] = [];

  toastHeader: string;
  toastBody: string;

  constructor(
    private router: Router,
    private placeService: PlaceService,
    private areasService: AreasService,
    private itinerariesService: ItinerariesService,
    
  ) {}

  ngOnInit(): void {
    this.loading = true;
    this.areasService.getAreas().subscribe((data) => {
      this.availAreas = data['records'];
    });
    this.itinerariesService.getItineraries().subscribe((data) => {
      this.itineraries = data['records'];
    });
    this.loading = false;
    //this.placeService.getPlace().subscribe((data) => {this.places = data['records']});
  }

  deleteItinerary(itinerary: Itinerary) {
    this.itinerariesService.deleteItinerary(itinerary).subscribe((data) => {
      this.itineraries = this.itineraries.filter((t) => t.id !== itinerary.id);
      this.toastBody = 'Itinerary Deleted';
    });
  }

  onSelectArea(area: string) {
    if (area || area != '') {
      //this.placeService.getSelectedAreaPlaces(area).subscribe((data) => {this.places = data['records']});
    } else {
      //this.placeService.getAllPlaces().subscribe((data) => {this.places = data['records']});
    }
  }

  createNew() {
    this.router.navigate(['/addItinerary']);
  }
}
