import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { EMPTY, Observable } from 'rxjs';
import { StoreImgsService } from 'src/app/common/services/store-imgs.service';
import { DeleteStoredImgsService } from 'src/app/common/services/delete-stored-imgs.service';
import { ConfigService } from 'src/app/guides/services/config.service';
import { switchMap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
  }),
};

const httpOptionsOrigin = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    "Access-Control-Allow-Origin": "*"
  }),
};

@Injectable({
  providedIn: 'root'
})
export class XferService {
  //private amadeusTokenUrl = 'tourismappAdmin/api/amadeusAccessToken.php'; //development
  private amadeusTokenUrl = environment.xferApi._AMADEUS_AUTH;
  //private trenitaliaUrl = 'tourismappAdmin/api/trenitaliaApi.php'; //development
  private trenitaliaUrl = environment.xferApi._TRENITALIA; //production  

  constructor(
    private http: HttpClient,
    private storeImgsService: StoreImgsService,
    private deleteStoredImgsService: DeleteStoredImgsService,
    private configService: ConfigService
  ) { }

  /*--------API TRENITALIA------------*/
  private callTrenitaApi(urlToBeCalled:string):Observable<any>{
    return this.http.get<any>(this.trenitaliaUrl,{params:{url:urlToBeCalled}})
  }
  
  /* API STRING: https://www.lefrecce.it/msite/api/solutions?origin=[STAZIONEPARTENZA]&destination=[STAZIONEARRIVO]&arflag=[AR]&adate=[DATA]&atime=[ORA]&offset=[OFFSET]&adultno=[ADULTI]&childno=[BAMBINI]&direction=[DIREZIONE]&frecce=[FRECCE]&onlyRegional=[REGIONALI]&rdate=[DATARITORNO]&rtime=[ORARITORNO]&codeList=[CODICE_CARTAFRECCIA]*/
  getTrenitaliaSolutions(details:{origin:string,date:string,time:number}):Observable<any>{
    const url = `https://www.lefrecce.it/msite/api/solutions?origin=${details.origin}&destination=moncalieri&arflag=A&adate=${details.date}&atime=${details.time}&adultno=1&childno=0&direction=A&frecce=false&onlyRegional=false` //production
    //return this.http.get<any>(url);
    return this.callTrenitaApi(url);
  }
  /*--------API TRENITALIA FINE------------*/
  
  /*--------API AMADEUS------------*/
  private getAmadeusAuthCode():Observable<any>{ //RICHIESTA AUTH CODE VIA PHP WRAPPER
    return this.http.get<any>(this.amadeusTokenUrl)
  }

  getAmadeusPlaneSolutions(details:{origin:string,date:string}):Observable<any>{
    return this.getAmadeusAuthCode().pipe(
      switchMap(data=>{
        let url = `https://test.api.amadeus.com/v2/shopping/flight-offers?originLocationCode=${details.origin}&destinationLocationCode=TRN&departureDate=${details.date}&adults=1&nonStop=false&max=5`;
        return this.http.get<any>(url,{headers:new HttpHeaders({"Authorization": "Bearer "+data['access_token']})})
      })
    )

  }
  /*--------API AMADEUS FINE------------*/
  
}
