import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MultiImgModalComponent } from 'src/app/common/components/multi-img-modal/multi-img-modal.component';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BreakpointObserver } from '@angular/cdk/layout';
import { AnswersComponent } from '../answers/answers.component';
import { QuizService } from 'src/app/contest/services/quiz.service';

@Component({
  selector: 'app-question-details',
  templateUrl: './question-details.component.html',
  styleUrls: ['./question-details.component.css']
})
export class QuestionDetailsComponent implements OnInit, AfterViewInit {
  @ViewChild(AnswersComponent) AnswersComponent!: AnswersComponent;
  private imgFolder = "../api/images/questions/";
  lgScreen: boolean = false;
  question: any = {};
  id: any = null;

  _filters = [
    {
      name: 'type',
      displayName: 'Tipo',
      options: { 'Risposta Multipla': 1, 'Vero/falso': 2, 'Immagine': 3 },
      selected: 'Tipo',
      disabled: false
    },
    {
      name: 'cat',
      displayName: 'Categoria',
      options: { 'Arte': 'arte', 'Cinema': 'cinema', 'Cultura Generale': 'culturaGenerale' },
      selected: 'Categoria',
      disabled: false
    }, {
      name: 'difficulty',
      displayName: 'Difficoltà',
      options: { 1: 1, 2: 2, 3: 3, 4: 4, 5: 5, 6: 6, 7: 7, 8: 8, 9: 9, 10: 10 },
      selected: 'Difficoltà',
      disabled: false
    },
  ]

  constructor(private route: ActivatedRoute,
    private modalService: NgbModal,
    private activeModal: NgbActiveModal,
    private responsive: BreakpointObserver,
    private quizService: QuizService) {
    this.responsive.observe('(min-width: 1200px)')
      .subscribe(result => {
        if (result.matches) {
          this.lgScreen = true
        } else {
          this.lgScreen = false;
        }
        console.log("lgScreen " + this.lgScreen);
      });
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    this.route.params.subscribe(param => {
      this.getQuestion(param['id']);
    });
  }

  getQuestion(id: any) {
    if (id!='0') {
      this.quizService.getQuestion(id).subscribe((question: any) => {
        this.question = question;
        this.AnswersComponent.answers = this.question.answers;
      })
    }else{
      this.question = {
        text:'testo della domanda',
        difficulty:1,
        category:'',
        type:1,
        imgPath:'',
        answers:[],
      }
      this.AnswersComponent.answers = this.question.answers;
    }
  }

  openModalImage(img: any) {
    let images = [];
    images.push(img);
    const modalRef = this.modalService.open(MultiImgModalComponent);
    modalRef.componentInstance.images = images;
    modalRef.componentInstance.imgFolder = this.imgFolder;
    modalRef.componentInstance.multiImage = false;
    modalRef.dismissed.subscribe(() => {

    })
    modalRef.result.then((images) => {
      this.question.imgPath = images[0];
      this.save();
    });
    modalRef.componentInstance.imagesChange.subscribe((images) => {
      console.log('changed');
      console.log(images);
      this.question.imgPath = images[0];
      this.save();
    })
  }

  openModalAnswers() {
    const modalRef = this.modalService.open(AnswersComponent);
    modalRef.componentInstance.activeModal = this.activeModal;
    modalRef.componentInstance.answers = this.question.answers;
    modalRef.dismissed.subscribe(() => {

    })
    modalRef.result.then((answers) => {
      this.question.answers = answers;
    });
  }

  save() {
    this.quizService.saveQuestion(this.question).subscribe(result => {
      console.log(result);
    })
  }

  saveBatch() {
    let questions = [

      //   {
      //     "text": "quanti abitanti ha Moncalieri",
      //     "category": "moncalieri",
      //     "difficulty": 1,
      //     "type": 1,
      //     "answers": [
      //         {
      //             "text": "tra 50 e 60 mila",
      //             "valid": true
      //         },
      //         {
      //             "text": "circa 25 mila",
      //             "valid": false
      //         },
      //         {
      //             "text": "poco più di 35 mila",
      //             "valid": false
      //         },
      //         {
      //             "text": "oltre 100 mila",
      //             "valid": false
      //         }
      //     ]
      // },
      // {
      //     "text": "come si chimano gli abitanti di moncalieri",
      //     "category": "moncalieri",
      //     "difficulty": 1,
      //     "type": 1,
      //     "imgpath": "null",
      //     "answers": [
      //         {
      //             "text": "moncalieresi",
      //             "valid": true
      //         },
      //         {
      //             "text": "moncalierini",
      //             "valid": false
      //         },
      //         {
      //             "text": "moncalesi",
      //             "valid": false
      //         },
      //         {
      //             "text": "moncoloresi",
      //             "valid": false
      //         }
      //     ]
      // },
      // {
      //     "text": "per quanti Km2 si estende Moncalieri",
      //     "category": "moncalieri",
      //     "difficulty": 2,
      //     "type": 1,
      //     "imgpath": "null",
      //     "answers": [
      //         {
      //             "text": "circa 49",
      //             "valid": true
      //         },
      //         {
      //             "text": "circa 38",
      //             "valid": false
      //         },
      //         {
      //             "text": "più di 60",
      //             "valid": false
      //         },
      //         {
      //             "text": 31,
      //             "valid": false
      //         }
      //     ]
      // },
      // {
      //     "text": "quante aree verdi ha moncalieri",
      //     "category": "moncalieri",
      //     "difficulty": 2,
      //     "type": 1,
      //     "imgpath": "null",
      //     "answers": [
      //         {
      //             "text": 75,
      //             "valid": true
      //         },
      //         {
      //             "text": 45,
      //             "valid": false
      //         },
      //         {
      //             "text": 65,
      //             "valid": false
      //         },
      //         {
      //             "text": 25,
      //             "valid": false
      //         }
      //     ]
      // },
      // {
      //     "text": "per quanti ettari si estendono le aree verdi di moncalieri",
      //     "category": "moncalieri",
      //     "difficulty": 2,
      //     "type": 1,
      //     "imgpath": "null",
      //     "answers": [
      //         {
      //             "text": "19 ettari",
      //             "valid": true
      //         },
      //         {
      //             "text": "12 ettari",
      //             "valid": false
      //         },
      //         {
      //             "text": "16 ettati",
      //             "valid": false
      //         },
      //         {
      //             "text": "29 ettari",
      //             "valid": false
      //         }
      //     ]
      // },
      // {
      //     "text": "chi è il patrono di Moncalieri",
      //     "category": "moncalieri",
      //     "difficulty": 1,
      //     "type": 1,
      //     "imgpath": "null",
      //     "answers": [
      //         {
      //             "text": "beato bernardo",
      //             "valid": true
      //         },
      //         {
      //             "text": "beato Bartolo Longo",
      //             "valid": false
      //         },
      //         {
      //             "text": "San Martino",
      //             "valid": false
      //         },
      //         {
      //             "text": "Santa Rosalia",
      //             "valid": false
      //         }
      //     ]
      // },
      // {
      //     "text": "quanti Km dista da Torino centro",
      //     "category": "moncalieri",
      //     "difficulty": 1,
      //     "type": 1,
      //     "answers": [
      //         {
      //             "text": "10KM",
      //             "valid": true
      //         },
      //         {
      //             "text": "15KM",
      //             "valid": false
      //         },
      //         {
      //             "text": "17KM",
      //             "valid": false
      //         },
      //         {
      //             "text": "22KM",
      //             "valid": false
      //         }
      //     ]
      // },
      // {
      //     "text": "quanti Km dista da Savona",
      //     "category": "moncalieri",
      //     "difficulty": 1,
      //     "type": 1,
      //     "answers": [
      //         {
      //             "text": "131 KM",
      //             "valid": true
      //         },
      //         {
      //             "text": "90KM",
      //             "valid": false
      //         },
      //         {
      //             "text": "175KM",
      //             "valid": false
      //         },
      //         {
      //             "text": "200KM",
      //             "valid": false
      //         }
      //     ]
      // },
      // {
      //     "text": "quanti Km dista da Sestriere",
      //     "category": "moncalieri",
      //     "difficulty": 1,
      //     "type": 1,
      //     "answers": [
      //         {
      //             "text": "circa 100 KM",
      //             "valid": true
      //         },
      //         {
      //             "text": "circa 200 km",
      //             "valid": false
      //         },
      //         {
      //             "text": "oltre 300KM",
      //             "valid": false
      //         },
      //         {
      //             "text": "meno di 50 KM",
      //             "valid": false
      //         }
      //     ]
      // },
      // {
      //     "text": "quanti Km dista da Cervinia",
      //     "category": "moncalieri",
      //     "difficulty": 1,
      //     "type": 1,
      //     "answers": [
      //         {
      //             "text": "146 KM",
      //             "valid": true
      //         },
      //         {
      //             "text": "102 KM",
      //             "valid": false
      //         },
      //         {
      //             "text": "400 KM",
      //             "valid": false
      //         },
      //         {
      //             "text": "199 KM",
      //             "valid": false
      //         }
      //     ]
      // },
      // {
      //     "text": "quanti Km dista da Asti",
      //     "category": "moncalieri",
      //     "difficulty": 1,
      //     "type": 1,
      //     "answers": [
      //         {
      //             "text": "meno di 50",
      //             "valid": true
      //         },
      //         {
      //             "text": "circa 75",
      //             "valid": false
      //         },
      //         {
      //             "text": "103 KM",
      //             "valid": false
      //         },
      //         {
      //             "text": "più di 90",
      //             "valid": false
      //         }
      //     ]
      // },
      // {
      //     "text": "quanti Km dista da Roma",
      //     "category": "moncalieri",
      //     "difficulty": 1,
      //     "type": 1,
      //     "answers": [
      //         {
      //             "text": "680 KM",
      //             "valid": true
      //         },
      //         {
      //             "text": "740 KM",
      //             "valid": false
      //         },
      //         {
      //             "text": "910 KM",
      //             "valid": false
      //         },
      //         {
      //             "text": "490 KM",
      //             "valid": false
      //         }
      //     ]
      // },
      // {
      //     "text": "quanti Km dista da Lione",
      //     "category": "moncalieri",
      //     "difficulty": 1,
      //     "type": 1,
      //     "answers": [
      //         {
      //             "text": "322 KM",
      //             "valid": true
      //         },
      //         {
      //             "text": "433 KM",
      //             "valid": false
      //         },
      //         {
      //             "text": "644 KM",
      //             "valid": false
      //         },
      //         {
      //             "text": "211 KM",
      //             "valid": false
      //         }
      //     ]
      // },
      // {
      //     "text": "quanti Km dista da Aosta",
      //     "category": "moncalieri",
      //     "difficulty": 1,
      //     "type": 1,
      //     "answers": [
      //         {
      //             "text": "144 KM",
      //             "valid": true
      //         },
      //         {
      //             "text": "92 KM",
      //             "valid": false
      //         },
      //         {
      //             "text": "300 KM",
      //             "valid": false
      //         },
      //         {
      //             "text": "160 KM",
      //             "valid": false
      //         }
      //     ]
      // },
      // {
      //     "text": "moncalieri è bagnata dal Po?",
      //     "category": "moncalieri",
      //     "difficulty": 1,
      //     "type": 2,
      //     "answers": [
      //         {
      //             "text": "SI",
      //             "valid": true
      //         },
      //         {
      //             "text": "NO",
      //             "valid": false
      //         }
      //     ]
      // },
      // {
      //     "text": "Moncalieri confina con Asti?",
      //     "category": "moncalieri",
      //     "difficulty": 1,
      //     "type": 2,
      //     "answers": [
      //         {
      //             "text": "NO",
      //             "valid": true
      //         },
      //         {
      //             "text": "SI",
      //             "valid": false
      //         }
      //     ]
      // },
      // {
      //     "text": "Moncalieri confina con Torino?",
      //     "category": "moncalieri",
      //     "difficulty": 1,
      //     "type": 2,
      //     "answers": [
      //         {
      //             "text": "SI",
      //             "valid": true
      //         },
      //         {
      //             "text": "NO",
      //             "valid": false
      //         }
      //     ]
      // },
      // {
      //     "text": "Moncalieri possiede una residenza sabauda?",
      //     "category": "moncalieri",
      //     "difficulty": 1,
      //     "type": 2,
      //     "answers": [
      //         {
      //             "text": "SI",
      //             "valid": true
      //         },
      //         {
      //             "text": "NO",
      //             "valid": false
      //         }
      //     ]
      // },
      // {
      //     "text": "Quando è stata fondata Moncalieri?",
      //     "category": "moncalieri",
      //     "difficulty": 2,
      //     "type": 1,
      //     "answers": [
      //         {
      //             "text": "inizi del 1200",
      //             "valid": true
      //         },
      //         {
      //             "text": "inizi del 1500",
      //             "valid": false
      //         },
      //         {
      //             "text": "metà del 1600",
      //             "valid": false
      //         },
      //         {
      //             "text": "fine del 1600",
      //             "valid": false
      //         }
      //     ]
      // },
      // {
      //     "text": "\nQuando fu firmato il proclama di Moncalieri firmato da re Vittorio Emanuele II?",
      //     "category": "moncalieri",
      //     "difficulty": 2,
      //     "type": 1,
      //     "answers": [
      //         {
      //             "text": 1849,
      //             "valid": true
      //         },
      //         {
      //             "text": 1899,
      //             "valid": false
      //         },
      //         {
      //             "text": 1901,
      //             "valid": false
      //         },
      //         {
      //             "text": 1914,
      //             "valid": false
      //         }
      //     ]
      // },
      // {
      //     "text": "n quale periodo è stata costruita la Collegiata Santa Maria della Scala?",
      //     "category": "moncalieri",
      //     "difficulty": 3,
      //     "type": 3,
      //     "answers": [
      //         {
      //             "text": "prima metà del 1300",
      //             "valid": true
      //         },
      //         {
      //             "text": "intorno all'anno 1000",
      //             "valid": false
      //         },
      //         {
      //             "text": "nel 1492",
      //             "valid": false
      //         },
      //         {
      //             "text": "nella second metà del 1600",
      //             "valid": false
      //         }
      //     ]
      // },
      // {
      //     "text": "quale personaggio è rappresentato nella fontana presente piazza Vittorio Emanuele II",
      //     "category": "moncalieri",
      //     "difficulty": 3,
      //     "type": 3,
      //     "answers": [
      //         {
      //             "text": "Poseidone, Nettuno",
      //             "valid": true
      //         },
      //         {
      //             "text": "Ermes, Mercurio",
      //             "valid": false
      //         },
      //         {
      //             "text": "Zeus, Giove",
      //             "valid": false
      //         },
      //         {
      //             "text": "Dionisio, Bacco",
      //             "valid": false
      //         }
      //     ]
      // },
      // {
      //     "text": "cosa è rappresentato nell'altorilievo in bronzo situato nella porta navina ",
      //     "category": "moncalieri",
      //     "difficulty": 3,
      //     "type": 3,
      //     "answers": [
      //         {
      //             "text": "firma del proclama di moncaleiri",
      //             "valid": true
      //         },
      //         {
      //             "text": "l'ultima cena ",
      //             "valid": false
      //         },
      //         {
      //             "text": "il re Vittorio Emanuele II",
      //             "valid": false
      //         },
      //         {
      //             "text": "il patrono Beato Bernardo",
      //             "valid": false
      //         }
      //     ]
      // },
      // {
      //     "text": "La biblioteca civica intitolata ad Antonio Arduino, ma chi fu costui?",
      //     "category": "moncalieri",
      //     "difficulty": 3,
      //     "type": 1,
      //     "answers": [
      //         {
      //             "text": "un caduto della 1a G.M.",
      //             "valid": true
      //         },
      //         {
      //             "text": "un inventore",
      //             "valid": false
      //         },
      //         {
      //             "text": "un politico ",
      //             "valid": false
      //         },
      //         {
      //             "text": "un calciatore",
      //             "valid": false
      //         }
      //     ]
      // }

    ]
    this.quizService.saveQuestion(questions).subscribe(result => {
      console.log("batch succeded");
    })
  }
}
