import { Component, OnInit, ViewChild } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { Guidebook, Setting } from 'src/app/guides/model/interfaces';
import { GuidebookService } from 'src/app/guides/services/guidebook.service';
import { EMPTY, Observable, switchMap, take, tap } from 'rxjs';
import { GameService } from 'src/app/contest/services/game.service';

@Component({
  selector: 'app-guidebooks',
  templateUrl: './guidebooks.component.html',
  styleUrls: ['./guidebooks.component.css'],
})
export class GuidebooksComponent implements OnInit {
  
  guidebookConfig:Setting = {} as Setting as any;
  guidebookSectionSettings:any[] = [];
  area: string = '';
  guidebooks: Guidebook[] = [];
  itineraries:[];
  games = []
  loading: boolean = false;

  constructor(
    private router: Router,
    private guidebookService: GuidebookService,
    private gameService: GameService) {
  }

  ngOnInit(): void {
    this.gameService.getGames().pipe(
      take(1),
      tap(data=>{
        this.games = data['records']
      })
    ).subscribe()

    //TODO: set AREA dinamically
    this.getGuideBooks$(2).subscribe()
  }

  getGuideBooks$(area:number):Observable<any>{
    return this.guidebookService.getGuideBooksConfig(area).pipe(
      switchMap((guideConfig) => {
        this.guidebookConfig = guideConfig;
        this.guidebookConfig.vals.forEach((val:any)=>{
          this.guidebookService.getGuideBookSection({area:area,uuid:val.uuid}).pipe(
            tap(({relatedGame}:any)=>{
                val.relatedGame = relatedGame??null;
            })
          ).subscribe();
        });
      return EMPTY;
    }))
  }

  deleteGuidebook(guidebook: Guidebook) {}

  onSelectArea(area: string) {}

  saveGBSectionDetails(){}

  configureGuidebook(section,config) {
    localStorage.setItem("section",section.name);
    let navigationExtras: NavigationExtras = {
      queryParams: {
        config: JSON.stringify(config),
        section: section.type
      },
    };
    this.router.navigate(['guidebooks/guidebookConfig'], navigationExtras);
  }

  message(event$:{successful:boolean,message:string}) {
    //TODO:aggiungere notifica
  }

  debug(obj:any){
    console.log(obj)
  }

  createNew() {
    this.router.navigate(['/addGuidebook']);
  }
  
}


/*SET @@group_concat_max_len = value_numeric;*/