import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChildren } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-answers',
  templateUrl: './answers.component.html',
  styleUrls: ['./answers.component.css']
})
export class AnswersComponent implements OnInit ,AfterViewInit{
  @ViewChildren('linkRef') linkRefs;
  @Input() activeModal!:NgbActiveModal;
  @Input() answers:any[];
  @Output() answersChange = new EventEmitter<any[]>();
  modal = false;
  constructor() { }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    if(this.activeModal){
      this.modal=true
    }else{
      this.modal=false
    }
  }
  cancel() {
    if(this.modal) this.activeModal.dismiss();
  }

  save(){
    if(this.modal) this.answersChange.emit(this.answers);
  }

  addAnswer(){
    this.answers.push({text:'testo della risposta',valid:false})
  }

  deleteAnswer(i:any){
    this.answers.splice(i,1)
  }
}
