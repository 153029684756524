import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Area } from 'src/app/guides/model/interfaces';
import { environment } from 'src/environments/environment';
//import { Place } from '../Place';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
  }),
};

@Injectable({
  providedIn: 'root',
})
export class AreasService {
  private apiUrl = `${environment.dbApiBaseDir}areas`;

  constructor(private http: HttpClient) {}

  getAreas(id?: number): Observable<Area|Area[]> {
    if(id){
      const url = `${this.apiUrl}/${id}`;
      return this.http.get<Area>(url);
    }else{
      return this.http.get<Area[]>(this.apiUrl);
    }
  }

  deleteArea(area: any): Observable<any> {
    const url = `${this.apiUrl}/${area.id}`;
    return this.http.delete<any>(url);
  }

  updateArea(area: any): Observable<any> {
    const url = `${this.apiUrl}/${area.id}`;
    return this.http.put<any>(url, area, httpOptions);
  }

  addArea(area: any): Observable<any> {
    return this.http.post<any>(this.apiUrl, area, httpOptions);
  }
}
