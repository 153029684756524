import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GuidesRoutingModule } from './guides-routing.module';
import { PlacesComponent } from './components/places/places.component';
import { ItinerariesComponent } from './components/itineraries/itineraries.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { PlaceItemComponent } from './components/places/place-item/place-item.component';
import { AddPlaceComponent } from './components/places/add-place/add-place.component';
import { GooglePlaceModule } from 'ngx-google-places-autocomplete';
import { CustomComponentsModule } from '../common/components/custom-components.module';
import { ItineraryItemComponent } from './components/itineraries/itinerary-item/itinerary-item.component';
import { AddItineraryComponent } from './components/itineraries/add-itinerary/add-itinerary.component';
import { GuidebookProductComponent } from './components/guidebooks/guide-switch/guidebook-product/guidebook-product.component';
import { GuideSwitchComponent } from './components/guidebooks/guide-switch/guide-switch.component';
import { GuidebookApiStringComponent } from './components/guidebooks/guide-switch/guidebook-api-string/guidebook-api-string.component';
import { GuidebookListComponent } from './components/guidebooks/guide-switch/guidebook-list/guidebook-list.component';
import { GuidebookExperienceComponent } from './components/guidebooks/guide-switch/guidebook-experience/guidebook-experience.component';
import { GuidebookExternalServiceComponent } from './components/guidebooks/guide-switch/guidebook-external-service/guidebook-external-service.component';
import { PlaceslistComponent } from './components/guidebooks/guide-switch/guidebook-list/placeslist/placeslist.component';
import { ItinerarieslistComponent } from './components/guidebooks/guide-switch/guidebook-list/itinerarieslist/itinerarieslist.component';
import { PeoplelistComponent } from './components/guidebooks/guide-switch/guidebook-list/peoplelist/peoplelist.component';
import { GuidebookContactComponent } from './components/guidebooks/guide-switch/guidebook-contact/guidebook-contact.component';
import { GuidebookActivityComponent } from './components/guidebooks/guide-switch/guidebook-activity/guidebook-activity.component';
import { GuidebookEventComponent } from './components/guidebooks/guide-switch/guidebook-event/guidebook-event.component';
import { FaModule } from '../common/fa/fa.module';
import { PeopleComponent } from './components/people/people.component';
import { AddPersonComponent } from './components/people/add-person/add-person.component';
import { PersonItemComponent } from './components/people/person-item/person-item.component';
import { AreasComponent } from './components/areas/areas.component';
import { AreaItemComponent } from './components/areas/area-item/area-item.component';
import { GuidebooksComponent } from './components/guidebooks/guidebooks.component';
import { SettingsComponent } from './components/settings/settings.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { StateService } from '../services/state.service';

@NgModule({
  declarations: [
    AreasComponent,
    GuidebooksComponent,
    AreaItemComponent,
    PlacesComponent,
    ItinerariesComponent,
    PlaceItemComponent,
    AddPlaceComponent,
    ItineraryItemComponent,
    AddItineraryComponent,
    GuideSwitchComponent,
    GuidebookApiStringComponent,
    GuidebookListComponent,
    GuidebookExperienceComponent,
    GuidebookExternalServiceComponent,
    PlaceslistComponent,
    ItinerarieslistComponent,
    PeoplelistComponent,
    GuidebookContactComponent,
    GuidebookActivityComponent,
    GuidebookEventComponent,
    GuidebookProductComponent,
    PeopleComponent,
    AddPersonComponent,
    PersonItemComponent,
    SettingsComponent
  ],
  imports: [
    CommonModule,
    GuidesRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    FontAwesomeModule,
    GooglePlaceModule,
    CustomComponentsModule,
    FaModule,
    NgbModule
  ],
  providers:[
    StateService,
  ]

})
export class GuidesModule { }
