import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { TagsService } from 'src/app/services/tags.service';
//import { find, get, pull } from 'lodash';

@Component({
  selector: 'app-tags-input',
  templateUrl: './tags-input.component.html',
  styleUrls: ['./tags-input.component.scss'],
})
export class TagsInputComponent implements OnInit {
  @Input() tags: string[];
  // Output prop name must be Input prop name + 'Change'
  // Use in your component to write an updated value back out to the parent
  @Output() tagsChange = new EventEmitter<string[]>();
  @Output() forbiddenTag = new EventEmitter<string>();

  @ViewChild('tagInput') tagInputRef: ElementRef;
  //tags: string[] = [];
  form: UntypedFormGroup;

  public tagsList: string[] = [];
  public blacklistedTagsList: string[] = [];

  constructor(private fb: UntypedFormBuilder, private tagsService: TagsService) {}

  ngOnInit() {
    this.tagsService.getTags().subscribe((data) => {
      this.tagsList.push(...data);
    });
    this.tagsService.getBlacklistedTags().subscribe((data) => {
      this.blacklistedTagsList = data.split('\n');
      console.log(this.blacklistedTagsList);
    });
    console.log(this.tagsList);
    this.form = this.fb.group({
      tag: [undefined],
    });
  }

  focusTagInput(): void {
    this.tagInputRef.nativeElement.focus();
  }

  onKeyUp(event: KeyboardEvent): void {
    const inputValue: string = this.form.controls.tag.value;
    if (event.code === 'Backspace' && !inputValue) {
      this.removeTag();
      return;
    } else {
      if (event.code === 'Comma' || event.code === 'Space') {
        this.addTag(inputValue);
        this.form.controls.tag.setValue('');
      }
    }
  }

  addTag(tag: string): void {
    tag = tag.toLowerCase();
    if (tag[tag.length - 1] === ',' || tag[tag.length - 1] === ' ') {
      tag = tag.slice(0, -1);
    }
    if (
      (this.blacklistedTagsList.indexOf(tag) < 0) &&
       (tag.length > 0 && (this.tags.indexOf(tag) < 0))
    ) {
      this.tags.push(tag);
      if (!(this.tagsList.indexOf(tag) > -1)) {
        this.tagsList.push(tag);
        this.tagsService.postTag().subscribe((data) => {
          console.log(data);
        });
      }
    } else {
      if (this.blacklistedTagsList.indexOf(tag) > -1) {
        this.forbiddenTag.emit('invalid tag');
      }
    }
  }

  public saveTag(e): void {
    this.addTag(e.target.value);
    e.target.value = '';
    this.focusTagInput();
  }

  removeTags() {
    this.tags.length = 0;
  }

  removeTag(tag?: string): void {
    if (!!tag) {
      this.tags.splice(this.tags.indexOf(tag), 1);
      //pull(this.tags, tag);
    } else {
      this.tags.splice(-1);
    }
  }
}
