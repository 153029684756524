<div style="position: absolute;bottom: 0px;left: 0px;">V:{{version}}</div>
<div class="d-flex mh-100" id="wrapper">
  <!-- Sidebar-->
  <div class="border-end bg-white" id="sidebar-wrapper">
    <div class="sidebar-heading border-bottom bg-light p-0" role="button" [routerLink]="['areas']">
      <img src="assets/logoAlpha.png" class="textSideMenu img-fluid" alt="" />
    </div>
    <div class="list-group list-group-flush">
      <a class="list-group-item list-group-item-action list-group-item-light p-3" [routerLink]="['areas']"
        routerLinkActive="activeSection">
        <span class="textSideMenu" i18n="@@areas">Aree</span>
        <fa-icon [icon]="['fas', 'globe']" class="iconSideMenu" size=""></fa-icon>
      </a>
      <a class="list-group-item list-group-item-action list-group-item-light p-3" [routerLink]="['guidebooks']"
        routerLinkActive="activeSection">
        <span class="textSideMenu" i18n="@@guidebooks">Guide</span>
        <fa-icon [icon]="['fas', 'map']" class="iconSideMenu" size=""></fa-icon>
      </a>
      <a class="list-group-item list-group-item-action list-group-item-light p-3" [routerLink]="['itineraries']"
        routerLinkActive="activeSection">
        <span class="textSideMenu" i18n="@@itineraries">Itinerari</span>
        <fa-icon [icon]="['fas', 'street-view']" class="iconSideMenu" size=""></fa-icon>
      </a>
      <a class="list-group-item list-group-item-action list-group-item-light p-3" [routerLink]="['places']"
        routerLinkActive="activeSection">
        <span class="textSideMenu" i18n="@@places">Luoghi</span>
        <fa-icon [icon]="['fas', 'map-marker']" class="iconSideMenu" size=""></fa-icon>
      </a>
      <a class="list-group-item list-group-item-action list-group-item-light p-3" [routerLink]="['people']"
        routerLinkActive="activeSection">
        <span class="textSideMenu" i18n="@@people">Persone</span>
        <fa-icon [icon]="['fas', 'users']" class="iconSideMenu" size=""></fa-icon>
      </a>
      <!--a
        class="list-group-item list-group-item-action list-group-item-light p-3"
        [routerLink]="['advisors']"
      >
        <span class="textSideMenu">Advisors</span>
        <fa-icon
          [icon]="faAddressCard"
          class="iconSideMenu"
          size="lg"
        ></fa-icon>
      </a-->
      <a class="list-group-item list-group-item-action list-group-item-light p-3" [routerLink]="['settings']"
        routerLinkActive="activeSection">
        <span class="textSideMenu">Impostazioni</span>
        <fa-icon [icon]="['fas', 'cog']" class="iconSideMenu" size=""></fa-icon>
      </a>
      <a class="list-group-item list-group-item-action list-group-item-light p-3" [routerLink]="['devel']"
        routerLinkActive="activeSection">
        <span class="textSideMenu">##DEVEL##</span>
        <fa-icon [icon]="['fas', 'hashtag']" class="iconSideMenu" size=""></fa-icon>
      </a>
      <!--a
        class="d-flex flex-row align-items-center justify-items-center list-group-item list-group-item-action list-group-item-light p-3"
        [routerLink]="['accountInfo']"
        routerLinkActive="activeSection"
      >
        <fa-icon [icon]="['fas', 'address-card']" class="iconSideBusinessMenu me-2" size="" ></fa-icon>
        <span class="textSideMenu">Info</span>
      </a-->
      <!--a class="d-flex flex-row align-items-center justify-items-center list-group-item list-group-item-action list-group-item-light p-3"
        [routerLink]="['biz']" routerLinkActive="activeSection">
        <fa-icon [icon]="['fas', 'store']" class="iconSideBusinessMenu me-2" size=""></fa-icon>
        <span class="textSideMenu">Attività</span>
      </a-->
      <!--a
        class="d-flex flex-row align-items-center justify-items-center list-group-item list-group-item-action list-group-item-light p-3"
        [routerLink]="['accStmnt']"
        routerLinkActive="activeSection"
      >
        <fa-icon [icon]="['fas', 'file-invoice-dollar']" class="iconSideBusinessMenu me-2" size=""></fa-icon>
        <span class="textSideMenu">Estratto Conto</span>
      </a>
      <a
        class="d-flex flex-row align-items-center justify-items-center list-group-item list-group-item-action list-group-item-light p-3"
        [routerLink]="['prizes']"
        routerLinkActive="activeSection"
      >
        <fa-icon [icon]="['fas', 'gift']" class="iconSideBusinessMenu me-2" size=""></fa-icon>
        <span class="textSideMenu">Premi</span>
      </a>
      <a
        class="d-flex flex-row align-items-center justify-items-center list-group-item list-group-item-action list-group-item-light p-3"
        [routerLink]="['adv']"
        routerLinkActive="activeSection"
      >
        <fa-icon [icon]="['fas', 'rectangle-ad']" class="iconSideBusinessMenu me-2" size=""></fa-icon>
        <span class="textSideMenu">Visibilità</span>
      </a-->
      <a class="d-flex flex-row align-items-center justify-items-center list-group-item list-group-item-action list-group-item-light p-3"
        [routerLink]="['contest']" routerLinkActive="activeSection">
        <fa-icon [icon]="['fas', 'rectangle-ad']" class="iconSideBusinessMenu me-2" size=""></fa-icon>
        <span class="textSideMenu">Concorsi</span>
      </a>
      <a class="d-flex flex-row align-items-center justify-items-center list-group-item list-group-item-action list-group-item-light p-3"
        [routerLink]="['contest/sessions']" routerLinkActive="activeSection">
        <fa-icon [icon]="['fas', 'rectangle-ad']" class="iconSideBusinessMenu me-2" size=""></fa-icon>
        <span class="textSideMenu">Giochi</span>
      </a>
      <a class="d-flex flex-row align-items-center justify-items-center list-group-item list-group-item-action list-group-item-light p-3"
        [routerLink]="['questions']" routerLinkActive="activeSection">
        <fa-icon [icon]="['fas', 'rectangle-ad']" class="iconSideBusinessMenu me-2" size=""></fa-icon>
        <span class="textSideMenu">Quiz</span>
      </a>
      <!--div class="accordion" id="accordionMenu">
        <div class="accordion-item">
          <h2 class="accordion-header" id="panelsStayOpen-heading2">
            <button
              class="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#panelsStayOpen-collapse2"
              aria-expanded="false"
              aria-controls="panelsStayOpen-collapse2"
              [routerLink]="['places']"
            >
              <span class="textSideMenu">Place</span>
              <fa-icon
                [icon]="faMapMarker"
                class="iconSideMenu"
                size="lg"
              ></fa-icon>
            </button>
          </h2>
          <div
            id="panelsStayOpen-collapse2"
            class="accordion-collapse collapse"
            aria-labelledby="panelsStayOpen-heading2"
          >
            <div class="accordion-body">
              <!--div class="">
                <input
                  class="form-control"
                  list="datalistOptions"
                  id="exampleDataList"
                  placeholder="Search Area"
                />
                <datalist id="datalistOptions">
                  <option *ngFor="let area of areas" >
                    {{ area.area_name }}
                  </option>
                </datalist>
              </div-->
      <!--div
                class="btn btn-outline mt-3"
                role="button"
                [routerLink]="['addPlace']"
              >
                <fa-icon [icon]="fapluscircle"></fa-icon
                ><span class="textSideMenu">New Place</span>
              </div>
            </div>
          </div>
        </div>
      </div-->
    </div>
  </div>
  <!-- Page content wrapper-->
  <div id="page-content-wrapper" class="d-flex flex-column mh-100 overflow-hidden position-relative">
    <div *ngIf="message" class="w-100 px-1 position-absolute" style="height: 2.4rem;bottom:0;z-index:100;">
      <ngb-alert class="" #selfClosingAlert [dismissible]="false" [animation]="true" [type]="message.type"
        (closed)="message=undefined "><span class="text-truncate">{{ message.text }}</span>
      </ngb-alert>
    </div>
    <!-- Breadcrumb -->
    <div class="navbar navbar-light bg-light border-bottom">
      <nav aria-label="breadcrumb">
        <xng-breadcrumb></xng-breadcrumb>
      </nav>
    </div>
    <!-- Top navigation -->
    <!--nav class="navbar navbar-expand-lg navbar-light bg-light border-bottom">
      <div class="container-fluid"-->
    <!--button class="btn btn-primary" id="sidebarToggle">Toggle Menu</button-->
    <!--div role="button" [routerLink]="['areas']">
          <img style="height: 50px" src="assets/logoAlpha.png" class="iconSideMenu img-fluid" alt="" />
        </div>
        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
          <ul class="navbar-nav ms-auto mt-2 mt-lg-0">
            <li class="nav-item active">
              <a class="nav-link" href="#!">Home</a>
            </li>
            <li class="nav-item"><a class="nav-link" href="#!">Link</a></li>
            <li class="nav-item dropdown">
              <a class="nav-link dropdown-toggle" id="navbarDropdown" href="#" role="button" data-bs-toggle="dropdown"
                aria-haspopup="true" aria-expanded="false">Dropdown</a>
              <div class="dropdown-menu dropdown-menu-end" aria-labelledby="navbarDropdown">
                <a class="dropdown-item" href="#!">Action</a>
                <a class="dropdown-item" href="#!">Another action</a>
                <div class="dropdown-divider"></div>
                <a class="dropdown-item" href="#!">Something else here</a>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </nav-->
    <!-- Page content-->
    <div class="container-fluid d-flex flex-column h-100 overflow-scroll flex-shrink-1">
      <!--h1 class="mt-4">Simple Sidebar</h1>
          <p>The starting state of the menu will appear collapsed on smaller screens, and will appear non-collapsed on larger screens. When toggled using the button below, the menu will change.</p>
          <p>
              Make sure to keep all page content within the
              <code>#page-content-wrapper</code>
              . The top navbar is optional, and just for demonstration. Just create an element with the
              <code>#sidebarToggle</code>
              ID which will toggle the menu when clicked.
          </p-->
      <router-outlet></router-outlet>
    </div>
  </div>
</div>