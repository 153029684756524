import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PlacesComponent } from './components/places/places.component';
import { ItinerariesComponent } from './components/itineraries/itineraries.component';
import { AreasComponent } from './components/areas/areas.component';
import { GuidebooksComponent } from './components/guidebooks/guidebooks.component';
import { PeopleComponent } from './components/people/people.component';
import { AddPersonComponent } from './components/people/add-person/add-person.component';
import { SettingsComponent } from './components/settings/settings.component';
import { AddPlaceComponent } from './components/places/add-place/add-place.component';
import { GuideSwitchComponent } from './components/guidebooks/guide-switch/guide-switch.component';
import { AddItineraryComponent } from './components/itineraries/add-itinerary/add-itinerary.component';


const routes: Routes = [
  {
    path: "", redirectTo: "/areas", pathMatch: "full"
  },
  {
    path: "areas", component: AreasComponent,
    children: [
      //{path: "details/:id", component: QuestionDetailsComponent},
    ]
  },
  {
    path: "guidebooks", component: GuidebooksComponent,
    children: [
      //{path: "guidebookConfig", component: GuideModalComponent},
    ]
  },
  {
    path: "guidebooks/guidebookConfig", component: GuideSwitchComponent,
    children: []
  },
  {
    path: "guidebooks/:sectionUuid/:guideUuid", component: GuideSwitchComponent,
    children: []
  },
  {
    path: 'places', component: PlacesComponent,
    children: [
      //{path: "sessions", component: SessionsComponent},
    ]
  },
  {
    path: 'places/:uuid', component: AddPlaceComponent,
    children: [
      //{path: "sessions", component: SessionsComponent},
    ]
  },
  {
    path: "itineraries", component: ItinerariesComponent,
    children: [
      //{path: "details/:id", component: QuestionDetailsComponent},
    ]
  },
  {
    path: 'itineraries/:uuid', component: AddItineraryComponent,
    children: [
      //{path: "sessions", component: SessionsComponent},
    ]
  },
  {
    path: "people", component: PeopleComponent,
    children: [
      //{path: "details/:id", component: QuestionDetailsComponent},
    ]
  },
  {
    path: "people/:uuid", component: AddPersonComponent,
    children: [
      //{path: "details/:id", component: QuestionDetailsComponent},
    ]
  },
  {
    path: "addPerson", component: AddPersonComponent,
    children: [
      //{path: "details/:id", component: QuestionDetailsComponent},
    ]
  },
  {
    path: "settings", component: SettingsComponent,
  }

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class GuidesRoutingModule { }