import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Place, Image, Setting } from '../model/interfaces';
import { StoreImgsService } from 'src/app/common/services/store-imgs.service';
import { DeleteStoredImgsService } from 'src/app/common/services/delete-stored-imgs.service';
import { map, switchMap, take, tap } from 'rxjs/operators';
import { ConfigService } from './config.service';
import * as _ from 'lodash';
import { environment } from 'src/environments/environment';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
  }),
};

@Injectable({
  providedIn: 'root',
})
export class PlaceService {
  private imgFolder = `${environment.imagesBaseDir}places/`;
  private iconImgFolder = `${environment.imagesBaseDir}mapIcons/`;
  private placeApiUrl = `${environment.dbApiBaseDir}places?join=placeImages`;
  private crudPlaceApiUrl = `${environment.dbApiBaseDir}places`;
  private imgApiUrl = `${environment.dbApiBaseDir}placeImages`;
  private placeImgBindApiUrl = `${environment.dbApiBaseDir}placeImgBind`;
  private viewPlaceImgBind = `${environment.dbApiBaseDir}viewPlaceImgBind`;

  constructor(
    private http: HttpClient,
    private storeImgsService: StoreImgsService,
    private deleteStoredImgsService: DeleteStoredImgsService,
    private configService: ConfigService
  ) { }

  getPlaceByCat(placeCat: string): Observable<Place[]> {
    const url = `${this.placeApiUrl}&filter=placeType,cs,${placeCat}`;
    return this.http.get<Place[]>(url).pipe(
      take(1)
    );
  }

  getPlaceById(id: string): Observable<Place> {
    const url = `${this.placeApiUrl}/${id}`;
    return this.http.get<any>(url).pipe(
      take(1)
    );
  }

  getPlaceByUuid(uuid: string): Observable<Place> {
    const url = `${this.placeApiUrl}&filter=uuid,eq,${uuid}`;
    return this.http.get<any>(url).pipe(
      take(1),
      map(({ records }) => { return records[0] ?? [] })
    );
  }

  getAllPlaces(): Observable<Place[]> {
    return this.http.get<any>(this.placeApiUrl).pipe(
      take(1),
      map(({ records }) => { return records }),
      tap((result) => {
        //debugger
      })
    );
  }

  getSelectedPlaces(places: number[]): Observable<Place[]> {
    const placesSerialized = places.join(',');
    const url = `${this.placeApiUrl}&filter=id,in,${placesSerialized}`;
    return this.http.get<Place[]>(url);
  }

  getSelectedAreaPlaces(area: string): Observable<Place[]> {
    const url = `${this.placeApiUrl}&filter=area,eq,${area}`;
    return this.http.get<Place[]>(url);
  }

  deletePlace(place: Place): Observable<string[]> {
    let performedOperations: string[] = [];
    const url = `${this.crudPlaceApiUrl}/${place.id}`;
    //return this.deleteStoredImgsService.deleteImg(place.mapIconPath).pipe(

    return this.deleteStoredImgsService
      .deleteImgs(
        place.mapIconPath ? [place.mapIconPath] : []
      )
      .pipe(
        switchMap((removedIcon) => {
          performedOperations.push(
            removedIcon[0] ? 'removed ' + removedIcon[0] : 'no Icon Found'
          );
          //TODO: DELETE ENTRIES IN PLACE IMAGES
          let imgsPath: string[] = [];
          place.placeImages.map((x) => imgsPath.push(x.storedPath));

          return this.deleteStoredImgsService
            .deleteImgs(imgsPath)
            .pipe(
              switchMap((removedImages) => {
                performedOperations.push(
                  removedImages.length
                    ? 'removed ' + [...removedImages]
                    : 'no Images Found'
                );
                return this.http.delete<number>(url).pipe(
                  switchMap((removedPlace) => {
                    performedOperations.push(
                      removedPlace ? 'removed ' + place.name : 'no place Found'
                    );

                    let deleteImgUrl =
                      this.imgApiUrl +
                      '/' +
                      (place.placeImages.length
                        ? [...place.placeImages.map((x) => x.id)]
                        : 0); //imgs.map(x=>x.id).join(',');

                    return this.http.delete<[number[]]>(deleteImgUrl).pipe(
                      switchMap((removedDbImages) => {
                        performedOperations.push(
                          removedDbImages.length
                            ? 'removed images ' + [...removedDbImages]
                            : 'no db image found'
                        );
                        return new Observable<string[]>((observer) => {
                          console.log(performedOperations);
                          observer.next(performedOperations);
                          performedOperations = [];
                        });
                      })
                    );
                  })
                );
              })
            );
        })
      );
  }

  savePlace(place: Place): Observable<any> {
    let bindingImgs: { placeId: string; placeImgId: string }[];
    const storeIcon$ = this.storeImgsService.saveImgsToPath({
      files: [place.mapIconPath],
      path: this.iconImgFolder,
    });
    const storeImages$ = this.storeImgsService.saveImgsToPath({
      files: place.placeImages.map((i) => i.file),
      path: this.imgFolder,
    });
    let performedOperations: string[] = [];
    return storeIcon$.pipe(
      switchMap((iconPath) => {
        if (iconPath[0]) {
          performedOperations.push('saved ' + iconPath[0]['filePath']);
          place.mapIconPath = iconPath[0]['filePath'];
        }

        return storeImages$.pipe(
          switchMap((imagesPath) => {
            let clonedImages: Image[] = _.cloneDeep(place.placeImages);
            if (imagesPath.length) {
              performedOperations.push('saved images files ' + [...imagesPath]);
              imagesPath.forEach((p, i) => {
                clonedImages[i].storedPath = p['filePath'];
                clonedImages[i].file = '';
              });
            }
            const saveImages$ = this.saveImgsToDb(clonedImages);
            return saveImages$.pipe(
              switchMap((imagesIds) => {
                imagesIds.length
                  ? performedOperations.push(
                    'saved images details' + [...imagesIds]
                  )
                  : null;
                place.placeImages = null;

                const savePlace$ = !place.id ? this.http.post<string>(
                  this.crudPlaceApiUrl,
                  place,
                  httpOptions
                ) : this.http.put<string>(
                  `${this.crudPlaceApiUrl}/${place.id}`,
                  place,
                  httpOptions
                );
                return savePlace$.pipe(
                  switchMap((placeId) => {
                    performedOperations.push('saved place ' + placeId);
                    bindingImgs = [];
                    imagesIds.forEach((id) => {
                      bindingImgs.push({
                        placeId: place.id ? <string><unknown>place.id : placeId,
                        placeImgId: id,
                      });
                    });
                    return this.bindImgs(bindingImgs).pipe(
                      switchMap((bindingEntries) => {
                        performedOperations.push(
                          bindingEntries.length
                            ? 'bound images ' + [...bindingEntries]
                            : 'no images bound'
                        );
                        return new Observable<string[]>((observer) => {
                          console.log(performedOperations);
                          observer.next(performedOperations);
                          performedOperations = [];
                        });
                      })
                    );
                  })
                );
              })
            );
          })
        );
      })
    );
  }

  /*updatePlace(place: Place): Observable<number> {
    let bindingImgs: { placeId: string; placeImgId: string }[];
    let performedOperations: string[] = [];
    const url = `${this.crudPlaceApiUrl}/${place.id}`;
    const storeIcon$ = this.storeImgsService.saveImgsToPath({
      files: [place.mapIconPath],
      path: this.iconImgFolder,
    });
    const storeImages$ = this.storeImgsService.saveImgsToPath({
      files: place.placeImages.map((i) => i.file),
      path: this.imgFolder,
    });
    return forkJoin([storeIcon$,storeImages$]).pipe(
      switchMap(([iconPath,imagesPath])=>{
        if (iconPath[0]) {
          performedOperations.push('saved ' + iconPath[0]['filePath']);
          place.mapIconPath = iconPath[0]['filePath'];
        }
        let clonedImages: Image[] = _.cloneDeep(place.placeImages);
        if (imagesPath.length) {
          performedOperations.push('saved images files ' + [...imagesPath]);
          imagesPath.forEach((p, i) => {
            clonedImages[i].storedPath = p['filePath'];
            clonedImages[i].file = '';
          });
        }
        const saveImages$ = this.saveImgsToDb(clonedImages);
        return EMPTY
      })
    )
    if (place.mapIconPath || place.placeImages.length) {
      return this.storeImgsService
        .saveImgsToPath({ files: [place.mapIconPath], path: this.iconImgFolder })
        .pipe(
          switchMap((data) => {
            place.mapIconPath = data['filePath'];
            if (place.placeImages.length) {
              place.placeImages.forEach((img, i) => {
                this.storeImgsService
                  .saveImgsToPath({
                    files: [img['file']],
                    path: this.imgFolder,
                  })
                  .pipe(
                    switchMap((data) => {
                      place.placeImages[i].storedPath = data['filePath'];
                      return EMPTY
                  }));
              });
              return this.http.put<number>(url, place, httpOptions);
            }
          })
        );
    } else {
      return this.http.put<number>(url, place, httpOptions);
    }
  }*/

  disablePlace(placeId: number): Observable<number> {
    const url = `${this.imgApiUrl}/${placeId}`;
    return this.http.patch<number>(url, { active: 0 }, httpOptions);
  }

  saveImgsToDb(placeImages: Image[]): Observable<string[]> {
    if (placeImages.length) {
      return this.http.post<string[]>(this.imgApiUrl, placeImages, httpOptions);
    } else {
      return new Observable((observer) => {
        observer.next([]);
      });
    }
  }

  bindImgs(
    data: { placeId: string; placeImgId: string }[]
  ): Observable<string[]> {
    if (data.length) {
      return this.http.post<string[]>(
        this.placeImgBindApiUrl,
        data,
        httpOptions
      );
    } else {
      return new Observable((observer) => {
        observer.next([]);
      });
    }
  }

  removeImage(image: Image): Observable<number> {
    const url = `${this.imgApiUrl}/${image.id}`;
    return this.deleteStoredImgsService
      .deleteImgs([image.storedPath])
      .pipe(
        switchMap(() => {
          return this.http.delete<number>(url);
        })
      );
  }

  removeIcon(path: string): Observable<string[]> {
    return this.deleteStoredImgsService.deleteImgs([path]);
  }

  /*getImage(id: number) {
    const url = `${this.imgApiUrl}/${id}`;
    return this.http.get<any>(url);
  }

  getPlaceImages(placeId: number) {
    const url = `${this.viewPlaceImgBind}?filter=placeId,eq,${placeId}`;
    return this.http.get<any>(url);
  }*/
}
