<div class="h-100 w-100 mt-3 overflow-auto" [ngClass]="modal?'p-4 bg-light':''">
    <div class="lead fs-6 me-auto mb-1">
        Risposte
    </div>
    <div class="d-flex flex-column overflow-auto" style="max-height:50vh;">
        <ul class="list-group list-group-flush list-group-numbered">
            <li *ngFor="let a of answers;let i = index"
                class="list-group-item list-group-item-action list-group-item-light d-flex justify-content-between align-items-start">
                <div class="d-flex flex-column ms-2 me-auto text-truncate p-2 w-100">
                    <div class="form-group flex-grow-1">
                        <label [for]="'answerText'+i">Testo</label>
                        <div class="d-flex flex-row">
                            <input type="text" class="form-control form-control-sm" [id]="'answerText'+i"
                                [(ngModel)]="a.text" [name]="'answerText'+i" #linkRef="ngModel" required />
                            <span class="input-group-text" (click)="deleteAnswer(i)">
                                <fa-icon [icon]="['fas', 'trash']"></fa-icon>
                            </span>
                        </div>
                        <div [hidden]="linkRef.valid || linkRef.pristine" class="alert alert-danger small position-absolute" style="width:calc(100% - 67.5px);z-index:2">
                            Inserisci il testo
                        </div>
                    </div>
                    <div class="form-check">
                        <input class="form-check-input" type="checkbox" value="" [id]="'answerValid'+i"
                            [(ngModel)]="a.valid">
                        <label class="form-check-label" [for]="'answerText'+i">
                            Valida
                        </label>
                    </div>
                </div>
            </li>
        </ul>
    </div>
    <div class="mt-3 d-block btn btn-light text-dark" (click)="addAnswer()">Aggiungi Risposta</div>
</div>