

<!-- bottone blu in alto a destra -->
<div role="button" class="btn btn-primary sticky-top m-auto m-3" (click)="addExperience()" style="left: 100%;
width: 40px;
border-radius: 35px;">+</div>


<div class="row mx-0">

  <!-- il form -->
  <div *ngFor="let experience of experiences; let i = index" class="p-2 col-12 col-xl-6" >

    <div class=" border bg-success text-dark bg-opacity-10">
      <fa-icon [icon]="['fas', 'trash']" size="lg" class="ml-auto"
      style="color: red; position: relative; top: 0rem; right: 0rem; float: right" (click)="remove(i)">
      </fa-icon>
    
      <div class="d-flex flex-row flex-wrap justify-content-between p-1">
    
        <div class="p-2 d-flex w-100">
          <div class="p-2 flex-grow-1">
            <div class="form-group mb-1">
              <label [for]="name[i]">Nome</label>
              <input type="text" class="form-control" id="name" required [(ngModel)]="experiences[i].name" name="name"
                #name="ngModel" />
            </div>
            <div class="w-100 mt-3">
              <select class="form-select col-auto" name="place{{ i }}" id="place{{ i }}"
                [(ngModel)]="experiences[i].person">
                <option [ngValue]="undefined" value="" selected disabled>
                  Tour Leader
                </option>
                <option *ngFor="let person of people" [value]="person.id">
                  {{ person.name }}
                </option>
              </select>
            </div>
            <div class="w-100 mt-3">
              <select class="form-select col-auto" name="activity{{ i }}" id="activity{{ i }}"
                [(ngModel)]="experiences[i].activity" [compareWith]="compareActivity">
                <option [ngValue]="undefined" value="" selected disabled>
                  Attività
                </option>
                <option *ngFor="let activity of activities" [ngValue]="activity">
                  {{ activity.name }}
                </option>
              </select>
            </div>
          </div>
          <div class="border p-2 ms-auto">
            <div class="d-flex flex-grow-1 flex-column p-1" style="max-height: 200px; aspect-ratio: 1/1; background-color: gray">
              <img src="{{ experience.imgPath }}" (click)="_click('img' + i)"
                onerror="this.onerror=null;this.src='assets/placeholder.jpg';" class="img-fluid border w-100"
                style="overflow: hidden; object-fit: cover;height: 100%;" />
              <div hidden class="input-group input-group-sm mt-3">
                <input type="file" aria-label="File" (change)="onImgChange($event, i)" name="img{{ i }}"
                  id="img{{ i }}" class="form-control" accept=".jpg,.jpeg,.png,.gif" />
              </div>
              <div class="btn btn-success w-100" (click)="openModalImage(experiences[i].imgsPaths,i)" type="button">
                <fa-icon [icon]="['fas', 'images']"></fa-icon>
              </div>
            </div>
          </div>
        </div>
        <!--button class="btn btn-outline-success" (click)="openModalImage(experiences[i].imgsPaths,i)" type="button">
          <fa-icon [icon]="faImages"></fa-icon>
        </button-->
    
    
        <div class="d-flex flex-column w-100 mt-5 border">
    
          <div class="me-2">
            <label>Quando</label>
          </div>
    
          <div class="d-flex flex-row w-100">
    
            <div class="form-check form-check-inline m-auto">
              <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked"
                [(ngModel)]="experiences[i].days.lun">
              <label class="form-check-label" for="flexCheckChecked">
                lun
              </label>
            </div>
    
            <div class="form-check form-check-inline m-auto">
              <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked"
                [(ngModel)]="experiences[i].days.mar">
              <label class="form-check-label" for="flexCheckChecked">
                mar
              </label>
            </div>
    
            <div class="form-check form-check-inline m-auto">
              <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked"
                [(ngModel)]="experiences[i].days.mer">
              <label class="form-check-label" for="flexCheckChecked">
                mer
              </label>
            </div>
    
            <div class="form-check form-check-inline m-auto">
              <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked"
                [(ngModel)]="experiences[i].days.gio">
              <label class="form-check-label" for="flexCheckChecked">
                gio
              </label>
            </div>
    
            <div class="form-check form-check-inline m-auto">
              <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked"
                [(ngModel)]="experiences[i].days.ven">
              <label class="form-check-label" for="flexCheckChecked">
                ven
              </label>
            </div>
    
            <div class="form-check form-check-inline m-auto">
              <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked"
                [(ngModel)]="experiences[i].days.sab">
              <label class="form-check-label" for="flexCheckChecked">
                sab
              </label>
            </div>
    
            <div class="form-check form-check-inline m-auto">
              <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked"
                [(ngModel)]="experiences[i].days.dom">
              <label class="form-check-label" for="flexCheckChecked">
                dom
              </label>
            </div>
    
          </div>
    
        </div>
    
        <div class="d-flex flex-row mt-2 align-items-center">
          <div class="form-group">
            <label class="active" for="timeStandard">Dalle</label>
            <input class="form-control" id="timeStandard" type="time" [(ngModel)]="experiences[i].time">
          </div>
          <div class="form-group ms-3">
            <label class="active" for="timeStandard">Durata</label>
            <ngb-timepicker [(ngModel)]="experiences[i].duration" [spinners]="false"></ngb-timepicker>
          </div>
          <div class="form-group ms-5 w-auto">
            <label [for]="cost[i]">Costo</label>
            <input type="text" class="form-control" id="cost" [(ngModel)]="experiences[i].cost" name="cost"
              #cost="ngModel" />
          </div>
        </div>
    
        <div class="d-flex flex-row mt-2 align-items-center mw-100">
          <div class="form-group">
            <label class="active" for="timeStandard">Dal</label>
            <div class="input-group">
              <input class="form-control" placeholder="dd/mm/yyyy" name="dp" [(ngModel)]="experiences[i].date.from" ngbDatepicker
                #dfrom="ngbDatepicker">
              <button class="btn btn-outline-success calendar" (click)="dfrom.toggle()" type="button">
                <fa-icon [icon]="['fas', 'calendar']"></fa-icon>
              </button>
            </div>
          </div>
          <div class="form-group ms-1">
            <label class="active" for="timeStandard">Al</label>
            <div class="input-group ">
              <input class="form-control" placeholder="dd/mm/yyyy" name="dp" [(ngModel)]="experiences[i].date.to" ngbDatepicker
                #dto="ngbDatepicker">
              <button class="btn btn-outline-success calendar" (click)="dto.toggle()" type="button">
                <fa-icon [icon]="['fas', 'calendar']"></fa-icon>
              </button>
            </div>
          </div>
        </div>
    
        <div class="d-flex flex-column w-100 mt-5 border p-2">
    
          <div class="form-group mb-1">
            <label [for]="description[i]">Descrizione</label>
            <textarea class="form-control" id="description{{i}}" [(ngModel)]="experiences[i].description" name="description{{i}}"
                #description="ngModel" ></textarea>
          </div>
    
          <div class="form-group mb-1">
            <label [for]="notes[i]">Note</label>
            <textarea type="textarea" class="form-control" id="notes{{i}}" [(ngModel)]="experiences[i].notes" name="notes{{i}}"
              #notes="ngModel" ></textarea>
          </div>
          
        </div>
    
      </div>
    </div>

  </div>

</div>