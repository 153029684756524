<div
    class="list-group-item d-flex justify-content-between"
    *ngFor="let item of listSearch; let i = index"
  >
    <div class="form-check d-flex flex-row  align-self-center">
      <input
        class="form-check-input d-flex"
        type="checkbox"
        [checked]="checkIfSelected(item.id)"
        [value]="item.id"
        (change)="onItemCheckChange($event)"
      />
      <div class=" d-flex flex-column">
        <div class="">
          <span
            ><small>({{ item.id }})</small
            ><strong
              >{{ item.name }}  
            </strong></span
          >
          <!--p style="font-size: x-small">{{ item.notes }}</p-->
        </div>
        <div class="">
          <span>{{ item.address }}</span>
        </div>
      </div>
    </div>
    <!--div class="border d-flex img-fluid" style="height: 50px; width: 50px">
      <img [src]="item.profileImgPath" alt="" />
    </div-->
  </div>
