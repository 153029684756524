<div [formGroup]="form">
  <div class="tag-input" (click)="focusTagInput()">
    <span class="tag-item" *ngFor="let tag of tags">
      {{ tag }}
      <span class="remove-tag" (click)="removeTag(tag)">
        <i class="fa fa-trash fa"></i>
      </span>
    </span>
    <input
      list="codes"
      placeholder="Enter a Tag"
      #tagInput
      type="text"
      class="input-tag"
      (keyup)="onKeyUp($event)"
      formControlName="tag"
      (change)="saveTag($event)"
    />
    <datalist id="codes">
      <option *ngFor="let tag of tagsList">{{ tag }}</option>
    </datalist>
    <span class="delete-icon" (click)="removeTags()"
      ><i class="fa fa-trash fa"></i
    ></span>
  </div>
</div>
