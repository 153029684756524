<div
  id="fab-button"
  class="btn btn-danger position-fixed bottom-0 end-0"
  (click)="cancel()"
>
  <fa-icon [icon]="['fas', 'chevron-left']" size="2x" style="margin: 1%"></fa-icon>
</div>
<div class="container mw-100 mt-1">
  <div >
    <form (ngSubmit)="onSubmit()" #PersonForm="ngForm">
     
      <div class="d-flex flex-row flex-wrap justify-content-between border p-1">
        <div class="form-group col-lg-6 flex-grow-1">
          <label for="name">Name</label>
          <input
            type="text"
            class="form-control"
            id="name"
            required
            [(ngModel)]="person.name"
            name="name"
            #name="ngModel"
          />
          <div
            [hidden]="name.valid || name.pristine"
            class="alert alert-danger small"
          >
            Name is required
          </div>
        </div>
        <div class="form-group col-lg-6 flex-grow-1">
          <label for="surname">Surname</label>
          <input
            type="text"
            class="form-control"
            id="surname"
            required
            [(ngModel)]="person.surname"
            name="surname"
            #surname="ngModel"
          />
          <div
            [hidden]="surname.valid || surname.pristine"
            class="alert alert-danger small"
          >
            Surname is required
          </div>
        </div>
        <div class="flex-grow-1 mt-1">
          <div
            class="
              form-group
              d-flex
              flex-column flex-sm-row
              justify-content-between
            "
          >
            <div class="d-flex flex-column col-sm-4">
              <div class="input-group d-flex flex-row flex-nowwrap flex-grow-1">
                <span class="input-group-text"
                  ><fa-icon [icon]="['fas', 'phone']"></fa-icon
                ></span>
                <input
                  type="number"
                  class="form-control"
                  id="phone"
                  required
                  [(ngModel)]="person.phone"
                  name="phone"
                  #phone="ngModel"
                />
              </div>
              <div
                [hidden]="phone.valid || phone.pristine"
                class="alert alert-danger small"
              >
                Phone is required
              </div>
            </div>
            <div class="d-flex flex-column col-sm-8">
              <div class="input-group d-flex flex-row flex-nowrap flex-grow-1">
                <span class="input-group-text"
                  ><fa-icon [icon]="['fas', 'at']"></fa-icon
                ></span>
                <input
                  type="text"
                  class="form-control"
                  id="email"
                  required
                  [(ngModel)]="person.email"
                  name="email"
                  #email="ngModel"
                />
              </div>
              <div
                [hidden]="email.valid || email.pristine"
                class="alert alert-danger small"
              >
                Email is required
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="d-flex flex-row flex-wrap justify-content-between border p-1 mt-3 mb-3">
        <div class="form-group flex-grow-1">
          <label for="activity">City</label>
          <select
            class="form-control"
            id="city"
            required
            [(ngModel)]="person.city"
            name="city"
            #city="ngModel"
          >
            <option *ngFor="let city of cities" [value]="city">
              {{ city }}
            </option>
          </select>
          <div
            [hidden]="city.valid || city.pristine"
            class="alert alert-danger small"
          >
            City is required
          </div>
        </div>
        <div class="form-group flex-grow-1">
          <label for="activity">Activity</label>
          <select
            class="form-control"
            id="activity"
            required
            [(ngModel)]="person.activity"
            name="activity"
            #activity="ngModel"
          >
            <option *ngFor="let act of activities" [value]="act">
              {{ act }}
            </option>
          </select>
          <div
            [hidden]="activity.valid || activity.pristine"
            class="alert alert-danger small"
          >
            Activity is required
          </div>
        </div>
      </div>
      <div class="d-flex flex-column flex-sm-row border p-1 mb-3">
        <div class="d-flex flex-column col-sm-8">
          <div
            class="d-flex flex-row justify-content-around align-items-around p-1 border">
            <div class="d-flex">
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="checkbox"
                  name="bookable"
                  [(ngModel)]="person.bookable"
                  id="bookable"
                />
                <label class="form-check-label" for="bookable">Bookable</label>
              </div>
            </div>
            <div class="d-flex">
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="checkbox"
                  name="professional"
                  [(ngModel)]="person.professional"
                  id="professional"
                />
                <label class="form-check-label" for="professional"
                  >Professional</label
                >
              </div>
            </div>
          </div>
          <div class="d-flex flex-column flex-md-column flex-grow-1">
            <div class="form-group flex-grow-1">
              <label for="description">Description</label>
              <textarea
                class="form-control"
                id="description"
                name="description"
                aria-label="Description"
                [(ngModel)]="person.description"
                rows="3"
                placeholder="add a description"
              ></textarea>
            </div>
            <div class="form-group flex-grow-1">
              <label for="notes">Notes</label>
              <textarea
                class="form-control"
                id="notes"
                name="notes"
                aria-label="Notes"
                [(ngModel)]="person.notes"
                rows="3"
                placeholder="add Notes"
              ></textarea>
            </div>
          </div>
        </div>
        <div
          class="d-flex border flex-grow-1 p-1"
          style="max-height: 300px; aspect-ratio: 1/1; background-color: gray">
          <img
            src="{{ person.profileImgPath }}"
            (click)="_click('profileImg')"
            onerror="this.onerror=null;this.src='assets/placeholder.jpg';"
            class="img-fluid border w-100"
            style="overflow: hidden;object-fit: cover;"/>
          <div hidden class="input-group input-group-sm mt-3">
            <input
              type="file"
              aria-label="File"
              (change)="onImgChange($event)"
              name="profileImg"
              id="profileImg"
              class="form-control"
              accept=".jpg,.jpeg,.png,.gif"
            />
          </div>
        </div>
      </div>

      <div class="form-group border p-1">
        <label for="tags">Tags</label>
        <app-tags-input
          id="tags"
          name="tags"
          [(tags)]="person.tags"
          (forbiddenTag)="showAlert($event)"
          class="col-9"
        >
        </app-tags-input>
      </div>
         </form>
  </div>

  <app-submit
    [isNew]="_isNew"
    [disabled] = "!PersonForm.form.valid||submitting"
    section="Persona"
    (save)="onSubmit()"
    (cancel)="cancel()"
  ></app-submit>
</div>
