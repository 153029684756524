<!--form [formGroup]="myForm" (ngSubmit)="submit()">
  <!--div class="form-group">
    <label for="name">Name</label>
    <input formControlName="name" id="name" type="text" class="form-control" />
    <div *ngIf="f.name.touched && f.name.invalid" class="alert alert-danger">
      <div *ngIf="f.name.errors.required">Name is required.</div>
      <div *ngIf="f.name.errors.minlength">Name should be 3 character.</div>
    </div>
  </div-->

<!--div class="form-group">
    <!--label for="file">File</label-->
<!--input
      formControlName="file"
      id="file"
      type="file"
      class="form-control"
      multiple=""
      (change)="onFileChange($event)"
    />
    <div *ngIf="f.file.touched && f.file.invalid" class="alert alert-danger">
      <div *ngIf="f.file.errors.required">File is required.</div>
    </div>
  </div>

  <div class="row">
    <div *ngFor="let url of images; let i = index" class="col">
      <img [src]="url" height="150" width="200px" style="margin: 3px" /> <br />
      <span (click)="removeImg(i)">delete</span>
    </div>
  </div>
  <button class="btn btn-primary" type="submit">Submit</button>
</form>

<input [(ngModel)]="toJSON" />
<span>{{ toJSON | json }}</span>
<!--input [(ngModel)]="toString"/>
<span [(ngModel)]="JSON.parse(toString)"></span-->

<!--div class="row d-flex m-3">
  <input class="col-11" [(ngModel)]="removing" type="text/" />
  <button class="col-1 btn btn-primary" (click)="removeImgs()"></button>
</div-->

<!--app-tags-input></app-tags-input>

<app-sortable-stepper></app-sortable-stepper>

<app-login></app-login>


<app-duration></app-duration-->

<div class="w-100 d-flex justify-content-around py-5">
  <button class="btn btn-success" (click)="alert('success')">success</button>
  <button class="btn btn-warning" (click)="alert('warning')">warning</button>
  <button class="btn btn-danger" (click)="alert('danger')">danger</button>
  <button class="btn btn-log" (click)="alert('log')">log</button>
</div>

<div class="w-100 d-flex justify-content-center py-5">
  <div class="btn btn-primary" (click)="confirm()">test conferma</div>
</div>


<!--TRAIN-->
<div class="container border d-flex flex-column">
  <span>TRENO - inserire nome esatto stazione</span>
  <small>DA FARE: completamento automatico su inserimento città: "torino porta susa"</small>

  <input class="mb-1" [(ngModel)]="trainSearch.origin" type="text" placeholder="Origine" />
  <input class="mb-1" [(ngModel)]="trainSearch.date" type="text" placeholder="data" />
  <input class="mb-1" [(ngModel)]="trainSearch.time" type="number" placeholder="ora" />
  <div class="btn btn-primary" (click)="getTrains()">Cerca</div>
  <div *ngFor="let solution of trainSolutions" class="d-flex flex-column">
    <div class="d-flex flex-row border">
      <label class="form-label" style="margin-right: 5px;">
        Partenza: {{ solution.departuretime | date: "HH:mm" }}
      </label>
      <label class="form-label" style="margin-right: 5px;">
        Arrivo: {{ solution.arrivaltime | date: "HH:mm" }}
      </label>
      <label class="form-label" style="margin-right: 5px;">
        prezzo: {{ solution.minprice}}
      </label>
      <label class="form-label" style="margin-right: 5px;">
        durata: {{ solution.duration}}
      </label>
      <label class="form-label" style="margin-right: 5px;">
        cambi: {{ solution.changesno}}
      </label>
    </div>
  </div>
</div>


<div class="container border d-flex flex-column mt-3">
  <span>AEREO - USARE TRILETTERALE AEROPORTO: "NAP", "FCO", "CDG","CTA" etc</span>
  <small>DA FARE: completamento automatico su inserimento città</small>
  <input class="mb-1" [ngModel]="planeSearch.origin" (ngModelChange)="planeSearch.origin = $event.toUpperCase()"
    type="text" placeholder="Origine" />
  <input class="mb-1" [(ngModel)]="planeSearch.date" type="text" placeholder="data" />
  <div class="btn btn-primary" (click)="getPlanes()">Cerca</div>
  <div *ngFor="let solution of planeSolutions.data" class="d-flex flex-column">
    <div class="d-flex flex-row border">
      <label class="form-label" style="margin-right: 5px;">
        Partenza: {{ solution.itineraries[0].segments[0].departure.at}}
      </label>
      <label class="form-label" style="margin-right: 5px;">
        prezzo: {{ solution.price.total}}
      </label>
    </div>
  </div>
</div>

<!--TRAIN-->


<hr>