<div class="">
  <div class="card-sl mb-1 d-flex align-items-start flex-column p-0">
    <div class="card-image w-100 m-1">
      <img src="{{imgpref + itinerary.imgPath}}" class="card-img-top img-fluid"
        style="height: 10rem; overflow: hidden; object-fit: cover" />
    </div>
    <div class="mb-auto border m-1 overflow-auto" style="max-width: 18rem; width: 97%; max-height: 24%">
      <div class="card-heading">
        <h5 class="card-title text-truncate text-uppercase">
          {{ itinerary.name }}
        </h5>
      </div>
      <div class="card-text text-muted h-auto d-inline-block">
        {{ itinerary.notes }}
      </div>
    </div>
    <div class="list-group container m-1 p-1 border" style="height: 8rem; width: 97%">
      <div class="column list-group list-group-flush" style="overflow-y: auto">
        <div class="list-group-item m-0" *ngFor="let place of places; let i = index">
          <span class="text-truncate">{{ place.name }}</span>
        </div>
      </div>
    </div>
    <app-modify-delete [target]="itinerary.id" (onDelete)="onDelete(itinerary)"></app-modify-delete>
  </div>
</div>