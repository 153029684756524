import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { Options } from '@angular-slider/ngx-slider';
import { Time2visit } from 'src/app/guides/model/interfaces';

@Component({
  selector: 'app-duration',
  templateUrl: './duration.component.html',
  styleUrls: ['./duration.component.css']
})
export class DurationComponent implements OnInit {
  @Input() time2visit:Time2visit;
  // Output prop name must be Input prop name + 'Change'
  // Use in your component to write an updated value back out to the parent
  @Output() time2visitChange = new EventEmitter<Time2visit>();

  
  options: Options = {
    floor: 0,
    ceil: 12,
    step: 0.5
  };

  constructor() { }

  ngOnInit(): void {
    console.log(this.time2visit)
    //this.time2visit = {min:1.5, max:2.5};
  }

  /*resetForm(): void {
    this.sliderForm.reset({sliderControl: [20, 80]});
  }*/
}