import { Component, OnInit, Input, Output, EventEmitter, AfterViewInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmModalComponent } from 'src/app/common/components/confirm-modal/confirm-modal.component';
import { Area } from 'src/app/guides/model/interfaces';
import { environment } from 'src/environments/environment';
import Map from 'ol/Map';
import View from 'ol/View';
import Layer from 'ol/layer/Layer';
import TileLayer from 'ol/layer/Tile';
import OSM from 'ol/source/OSM';
import { fromLonLat } from 'ol/proj';
import Feature from 'ol/Feature';
import Point from 'ol/geom/Point';
import Style from 'ol/style/Style';
import Icon from 'ol/style/Icon';
import VectorSource from 'ol/source/Vector';
import VectorLayer from 'ol/layer/Vector';
import Overlay from 'ol/Overlay';
import { Zoom, defaults as defaultControls } from 'ol/control';

@Component({
  selector: 'app-area-item',
  templateUrl: './area-item.component.html',
  styleUrls: ['./area-item.component.css']
})

export class AreaItemComponent implements OnInit, AfterViewInit {
  @Input() area: Area;
  @Output() onDeleteArea: EventEmitter<Area> = new EventEmitter();
  @Output() onToggleReminder: EventEmitter<Area> = new EventEmitter();

  imgpref = environment.imagesPrefix;

  map!: Map;
  overlay!: Overlay;


  constructor(private modalService: NgbModal) { }

  ngOnInit(): void {

  }

  ngAfterViewInit(): void {
    this.map = new Map({
      view: new View({
        center: fromLonLat([this.area.latlng.lng - 0.1, this.area.latlng.lat - 0.02]),
        zoom: 10,
      }),
      layers: [
        new TileLayer({
          source: new OSM(),
        }),
        //this.createMarkers()
      ],
      target: `ol-map-${this.area.area_name}`,
      /*controls:defaultControls({
        attribution: false,
        zoom: true,
        rotate: false
      },)*/
      controls: [
        new Zoom({
          //target: 'custom-zoom',
          zoomInTipLabel: 'Zoom in',
          zoomOutTipLabel: 'Zoom out',
          className: 'ol-custom-zoom',
          //zoomInLabel: '+',
          zoomInLabel: this.createZoomButtonLabel('plus.svg'),
          //zoomOutLabel: '-',
          zoomOutLabel: this.createZoomButtonLabel('minus.svg'),
        }),
      ],
      /*overlays: [
        this.overlay = new Overlay({
          element: this.container!,
          autoPan: {
            animation: {
              duration: 250,
            },
          },
        })
      ]*/
    });
  }

  createZoomButtonLabel(imagePath) {
    const icon = document.createElement('img');
    icon.src = `assets/icons/${imagePath}`;
    return icon;
  }

  onDelete(area) {
    this.onDeleteArea.emit(area);
    const modalRef = this.modalService.open(ConfirmModalComponent);
    modalRef.componentInstance.alertTarget = 'city';

    modalRef.result.then(() => {
      this.onDeleteArea.emit(area);

    })
  }

  selectArea(uuid: string) {
    console.log("area selected")
  }
}

