


<!-- bottone blu di aggiunta in alto a destra -->
<div role="button" class="btn btn-primary sticky-top m-auto m-3" (click)="addActivity()"
      style="left: 100%;
        width: 40px;
        border-radius: 35px;">+</div>

<!-- contenitore dei form -->
<div class="row mx-0">
  
  <!-- il form -->
  <div
    *ngFor="let activity of activities; let i = index"
    class="col-12 col-xl-6 p-2"
  >
    <!-- contenuto interno del form -->
    <div class="border bg-success text-dark bg-opacity-10">
      
      <fa-icon
        [icon]="['fas', 'trash']"
        size="lg"
        class="ml-auto"
        style="color: red; position: relative; top: 0rem; right: 0rem; float: right"
        (click)="remove(i)"
      ></fa-icon>
      <div class="d-flex flex-row flex-wrap justify-content-between p-1">
        <div class="p-2 d-flex w-100">
          <div class="p-2 flex-grow-1">
            <div class="form-group mb-1">
              <label [for]="name[i]">Nome</label>
              <input
                type="text"
                class="form-control"
                id="name"
                required
                [(ngModel)]="activities[i].name"
                name="name"
                #name="ngModel"
              />
            </div>
            <div class="form-check form-switch">
              <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault">
              <label class="form-check-label" for="flexSwitchCheckDefault">Obbligo Tour Leader</label>
            </div>
            <div class="w-100 mt-5">
              <select
                class="form-select col-auto"
                name="place{{ i }}"
                id="place{{ i }}"
                [(ngModel)]="activities[i].place"
              >
                <option [ngValue]="undefined" value="" selected disabled>
                  Posizione
                </option>
                <option *ngFor="let place of places" [value]="place.id">
                  {{ place.name }}
                </option>
              </select>
            </div>
          </div>  
          <div class="border p-2 ms-auto">
            <div class="d-flex flex-grow-1 flex-column p-1" style="max-height: 200px; aspect-ratio: 1/1; background-color: gray">
              <img src="{{ activity.imgPath }}" (click)="_click('img' + i)"
                onerror="this.onerror=null;this.src='assets/placeholder.jpg';" class="img-fluid border w-100"
                style="overflow: hidden; object-fit:cover;height: 100%;" />
              <div hidden class="input-group input-group-sm mt-3">
                <input type="file" aria-label="File" (change)="onImgChange($event, i)" name="img{{ i }}"
                  id="img{{ i }}" class="form-control" accept=".jpg,.jpeg,.png,.gif"
                />
              </div>
              <div class="btn btn-success w-100" (click)="openModalImage(activities[i].imgsPaths,i)" type="button">
                <fa-icon [icon]="['fas', 'images']"></fa-icon>
              </div>
            </div>
          </div>
        </div>
        <div class="d-flex flex-column w-100 mt-5 p-2">
          <div class="form-group mb-1">
              <label [for]="description[i]">Descrizione</label>
              <textarea class="form-control" id="description{{i}}" [(ngModel)]="activities[i].description" name="description{{i}}"
                  #description="ngModel" ></textarea>
          </div>
          <div class="form-group mb-1">
              <label [for]="notes[i]">Note</label>
              <textarea type="textarea" class="form-control" id="notes{{i}}" [(ngModel)]="activities[i].notes" name="notes{{i}}"
                #notes="ngModel" ></textarea>
            </div>
      </div>
      </div>

    </div>
  </div>

</div>