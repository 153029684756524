<div id="fab-button" class="btn btn-danger position-fixed bottom-0 end-0" (click)="cancel()">
  <fa-icon [icon]="['fas', 'chevron-left']" size="2x" style="margin: 1%"></fa-icon>
</div>
<div class="add-form">
  <div class="input-group pt-1">
    <span class="input-group-text" style="background-color: #17a240; color: white">Città</span>
    <select class="form-select" style="max-width: 20%" name="selectArea" [(ngModel)]="place.area"
      (change)="onSelectArea(place.area)">
      <option [hidden]="!_isNew" [ngValue]="undefined" value="" selected>Scegli</option>
      <option *ngFor="let area of areas; let i = index" [disabled]="!area.active||!_isNew" [value]="area.id">
        {{ area.area_name }}
      </option>
    </select>
    <input [hidden]="!_isNew" type="text" class="form-control" placeholder="Place on Google" aria-label="Place"
      aria-describedby="basic-addon2" ngx-google-places-autocomplete [options]='placeSearchOptions'
      #placesRef="ngx-places" (onAddressChange)="handleAddressChange($event)" />
    <span class="input-group-text ms-auto">{{_isNew?"Google Place Search":"Id Google: "+place.googleId}}</span>
  </div>
  <div class="input-group pt-3">
    <span class="input-group-text">Nome</span>
    <input type="text" [(ngModel)]="place.name" class="form-control" placeholder="Name" aria-label="Name" />
    <span class="input-group-text">Indirizzo</span>
    <input type="text" class="form-control" [(ngModel)]="place.address" placeholder="Address" aria-label="Address" />
  </div>
  <div class="input-group pt-1">
    <span class="input-group-text"><fa-icon [icon]="['fas', 'phone']"></fa-icon></span>
    <input type="text" [(ngModel)]="place.phone" class="form-control" placeholder="Phone" aria-label="Phone" />
    <span class="input-group-text"><fa-icon [icon]="['fas', 'at']"></fa-icon></span>
    <input type="text" class="form-control" [(ngModel)]="place.email" placeholder="Email" aria-label="Email" />
  </div>
  <!--div class="form-control" name="added-fields" [style.display]="placeSelected?'block':'none'"-->

  <div class="input-group pt-3">
    <span class="input-group-text col-3">Descrizione</span>
    <textarea class="form-control" aria-label="Description" [(ngModel)]="place.description" rows="2"
      placeholder="add a description"></textarea>
  </div>
  <div class="input-group pt-1">
    <span class="input-group-text col-3">Note</span>
    <textarea class="form-control" aria-label="Notes" [(ngModel)]="place.notes" rows="2"
      placeholder="add a note"></textarea>
  </div>

  <div class="input-group pt-3">
    <span class="input-group-text col-3">Tipologia</span>

    <select class="form-select col-auto" size="3" name="selectedTipology" multiple [(ngModel)]="place.placeType">
      <option [ngValue]="undefined" value="" selected disabled>Tipo</option>
      <option *ngFor="let type of placeTypeList" [value]="type">
        {{ type }}
      </option>
    </select>

    <select class="form-select col-auto" size="3" name="selectedClass" multiple [(ngModel)]="place.placeClass">
      <option [ngValue]="undefined" value="" selected disabled>Caratteristiche</option>
      <option *ngFor="let class of placeClassList" [value]="class">
        {{ class }}
      </option>
    </select>
  </div>
  <div class="input-group pt-1">
    <span class="input-group-text col-3">Tags</span>
    <app-tags-input [(tags)]="tags" (forbiddenTag)="showAlert($event)" class="col-9">
    </app-tags-input>
  </div>

  <div class="container pt-2 d-flex flex-row flex-wrap border mt-3 rounded" style="border: 1px solid #ced4da!important">
    <div class="col-3 container d-flex flex-column">
      <div class=" form-check form-switch">
        <input class="form-check-input" type="checkbox" role="switch" name="arrival" [(ngModel)]="place.arrival"
          id="arrival" />
        <label class="form-check-label" for="arrival">Luogo di arrivo</label>
      </div>
      <div class=" form-check form-switch">
        <input class="form-check-input" type="checkbox" role="switch" name="spareTime" [(ngModel)]="place.spareTime"
          id="spareTime" />
        <label class="form-check-label" for="spareTime">Tempo libero</label>
      </div>
      <div class=" form-check form-switch">
        <input class="form-check-input" type="checkbox" role="switch" name="vacancies" [(ngModel)]="place.vacancies"
          id="vacancies" />
        <label class="form-check-label" for="vacancies">Posti letto</label>
      </div>
    </div>
    <div class="col input-group d-flex flex-column">
      <label class="form-label" for="time2visit">Tempo di visita (hrs)</label>
      <app-duration class="flex-grow-1" id="time2visit" [(time2visit)]='place.time2visit'></app-duration>
    </div>
  </div>


  <!--div class="input-group mb-3">
    <span class="input-group-text col-3">Advisor</span>
    <input
      type="text"
      aria-label
      name="advisor"
      [(ngModel)]="place.advisor"
      class="form-control"
    />
  </div-->
  <!--/div-->

  <table class="table table-striped w-50">
    <thead>
      <tr>
        <th scope="col">Categoria</th>
        <th scope="col">Prezzo</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let cost of place.costs; index as i">
        <td>{{ cost.cat | number }}</td>
        <td>{{ cost.val | number }}</td>
      </tr>
    </tbody>
  </table>


  <div class="pt-2" style="max-width:300px">
    <div class="input-group input-group-sm">
      <span class="input-group-text">Prezzi</span>
    </div>
    <div class="input-group input-group-sm">
      <span class="input-group-text" [ngClass]="{ 'dyn-tbl-first': place.costs?place.costs.length:0 }"
        style="flex: 1">Cat</span>
      <span class="input-group-text" [ngClass]="{ 'dyn-tbl-first': place.costs?place.costs.length:0 }"
        style="flex: 1">Price</span>
      <button class="btn btn-outline-secondary" [ngClass]="{ 'dyn-tbl-first': place.costs?place.costs.length:0 }"
        (click)="addCostCat('')" type="button" style="
        background-color: green;
        color: white;
        padding: 0px 10px;
        width: 35px;
      ">
        <fa-icon [icon]="['fas', 'plus-circle']"></fa-icon>
      </button>
    </div>
    <div *ngFor="let cost of place.costs; let i = index; let l = last; let f = first"
      class="input-group input-group-sm">
      <input type="text" aria-label="Cat" [(ngModel)]="cost.cat" name="cost{{ i }}key" class="form-control"
        [ngClass]="{ 'dyn-tbl-last': l, 'dyn-tbl': !l }" style="flex: 1" />
      <input type="text" aria-label="Prise" [(ngModel)]="cost.val" name="cost{{ i }}val" class="form-control"
        [ngClass]="{ 'dyn-tbl-last': l, 'dyn-tbl': !l }" style="flex: 1" />
      <button class="btn btn-outline-secondary" (click)="remCostCat(i)" [ngClass]="{ 'dyn-tbl-last': l, 'dyn-tbl': !l }"
        type="button" style="
        background-color: red;
        color: white;
        padding: 0px 10px;
        width: 35px;
      ">
        <fa-icon [icon]="['fas', 'minus-circle']"></fa-icon>
      </button>
    </div>
  </div>

  <!--div class="input-group input-group-sm mt-3">
    <span class="input-group-text col-xs-2 col-sm-2 col-md-2 col-lg-2"
      >Map Icon</span
  -->
  <!--input
      type="text"
      aria-label="Name"
      [(ngModel)]="mapIcon.name"
      name="iconname"
      class="form-control"
      style="flex: 1"
    /-->
  <!--input
      type="file"
      id="mapIcon"
      aria-label="File"
      (change)="onIconChange($event)"
      name="iconfile"
      class="form-control"
      style="flex: 1"
      accept=".jpg,.jpeg,.png,.gif"
    />
  </div-->

  <div class="input-group input-group-sm mb-0 mt-3">
    <span class="input-group-text col-2"
      [ngClass]="{ 'dyn-tbl-first': place.images?place.images.length:0 }">Immagini</span>
    <!--span
      class="input-group-text"
      style="flex: 1"
      [ngClass]="{ 'dyn-tbl-first': place.images.length }"
      >Name</span
    -->
    <span class="input-group-text" style="flex: 1"
      [ngClass]="{ 'dyn-tbl-first':place.images?place.images.length:0 }">File</span>
    <button class="btn btn-outline-secondary" [ngClass]="{ 'dyn-tbl-first': place.images?place.images.length:0 }"
      (click)="addPlaceImg('')" type="button" style="
        background-color: green;
        color: white;
        padding: 0px 10px;
        width: 35px;
      ">
      <fa-icon [icon]="['fas', 'plus-circle']"></fa-icon>
    </button>
  </div>
  <div *ngFor="let img of place.images; let i = index; let l = last; let f = first" class="input-group input-group-sm">
    <span class="input-group-text col-2" [ngClass]="{ 'dyn-tbl-last': l, 'dyn-tbl': !l }">{{ i + 1
      }}</span><!--'dyn-tbl-first':f&&!l,'dyn-tbl-last':l&&!f,'dyn-tbl':(!f&&!l)-->
    <!--input
      type="text"
      aria-label="Name"
      [(ngModel)]="place.images[i].name"
      name="img{{ i }}name"
      class="form-control col-4"
      style="flex: 1"
      [ngClass]="{ 'dyn-tbl-last': l, 'dyn-tbl': !l }"
    /-->
    <input type="file" aria-label="File" id="img{{ i }}file" (change)="onImagesChange($event, i)" name="img{{ i }}file"
      class="form-control" style="flex: 1" [ngClass]="{ 'dyn-tbl-last': l, 'dyn-tbl': !l }"
      [value]="_isNewPlace?place.imgFile:''" accept=".jpg,.jpeg,.png,.gif" />
    <button class="btn btn-outline-secondary" (click)="remPlaceImg(i)" [ngClass]="{ 'dyn-tbl-last': l, 'dyn-tbl': !l }"
      type="button" style="
        background-color: red;
        color: white;
        padding: 0px 10px;
        width: 35px;
      ">
      <fa-icon [icon]="['fas', 'minus-circle']"></fa-icon>
    </button>
  </div>

  <div class="container mt-1 mb-3 p-0" style="height: auto">
    <div class="row">
      <div *ngIf="place.mapIconPath" class="col-sm-6 col-md-4 col-lg-3 mt-1 ml-1 mb-1" style="height: auto">
        <span class="input-group-text" aria-label="Map Icon">Map Icon</span>
        <img src="{{ place.mapIconPath }}" (click)="_click('mapIcon')" class="img-fluid border"
          style="overflow:hidden; object-fit: cover;height:8rem;width: 100%;" />
      </div>
      <div *ngFor="let img of place.images; let i = index" class="col-sm-6 col-md-4 col-lg-3 mt-1 ml-1 mb-1"
        style="height: auto">
        <input type="text" [(ngModel)]="img.title" class="form-control" placeholder="Title" aria-label="Title"
          accept=".jpg,.jpeg,.png,.gif" />
        <img src="{{ imgpref+img.path }}" (click)="_click('img' + i + 'file')" class="img-fluid border"
          style="overflow:hidden; object-fit: cover;height:8rem;width: 100%;" />
        <textarea class="form-control" aria-label="Description" [(ngModel)]="img.desc" rows="2"
          placeholder="add a description"></textarea>
      </div>
    </div>
  </div>

  <div class="input-group input-group-sm mb-3 mt-3">
    <span class="input-group-text col-2"
      [ngClass]="{ 'dyn-tbl-first': place.images?place.images.length:0 }">Audios</span>
    <span class="input-group-text" style="flex: 1"
      [ngClass]="{ 'dyn-tbl-first': place.images?place.images.length:0 }">Name</span>
    <span class="input-group-text" style="flex: 1"
      [ngClass]="{ 'dyn-tbl-first': place.images?place.images.length:0 }">File</span>
    <button disabled class="btn btn-outline-secondary" style="width: 35px"
      [ngClass]="{ 'dyn-tbl-first': place.images?place.images.length:0 }" (click)="addPlaceImg('')" type="button"
      style="background-color: green; color: white; padding: 0px 10px">
      <fa-icon [icon]="['fas', 'plus-circle']"></fa-icon>
    </button>
  </div>
  <!--div *ngFor="let img of imgArray; let i = index; let l = last; let f = first" class="input-group input-group-sm">
    <span class="input-group-text col-xs-2 col-sm-2 col-md-2 col-lg-2" [ngClass]="{'dyn-tbl-last':l,'dyn-tbl':!l}">{{i+1}}</span>--><!--'dyn-tbl-first':f&&!l,'dyn-tbl-last':l&&!f,'dyn-tbl':(!f&&!l)-->
  <!--input type="text" aria-label="Name" [(ngModel)]="imgArray[i].name" name="img{{i}}name" class="form-control col-xs-3 col-sm-3 col-md-3 col-lg-3" [ngClass]="{'dyn-tbl-last':l,'dyn-tbl':!l}">
    <input type="file" aria-label="File" id="img{{i}}file" (change)="onImagesChange_($event,i)" name="img{{i}}file" class="form-control" style="width: 19%;" [ngClass]="{'dyn-tbl-last':l,'dyn-tbl':!l}">
    <button class="btn btn-outline-secondary col-xs-2 col-sm-2 col-md-2 col-lg-2" (click)="remPlaceImg_(i)" [ngClass]="{'dyn-tbl-last':l,'dyn-tbl':!l}" type="button" style="background-color:red;color: white;padding: 0px 10px;">Remove</button>
  </div-->

  <!--div style="border: 1px solid #ced4da;padding: .375rem .75rem;border-radius: .25rem;margin: 20px 0;">
    <p>Images<button class="btn" type='button' (click)="addPlaceImg_('')" style="background-color:green;color: white;padding: 0px 10px;"> + </button></p>
    <ng-container *ngFor="let img of imgArray; let j = index">
      <div style="display: flex;">
        <label for="name">File Name</label>
        <input placeholder="File Name" type="text" [(ngModel)]="imgprev[j]" name="img{{j}}name">
        <label for="file">File</label>
        <input type="file" name="img{{j}}file" (change)="onImagesChange_($event,j)">      
        <div class="preview" *ngIf="imgprev[j]">
          <img [src]="imgprev[j]" style="width:80px;">
        </div>
        <button class="btn" type='button' (click)="remPlaceImg_(i)" style="background-color:red;color: white;padding: 0px 10px;"> x </button>
      </div>
    </ng-container-->

  <!--p>Map Icon</p> 
    <div style="display: flex;">
      <label for="iconName">File Name</label>
      <input placeholder="File Name" type="text" [(ngModel)]="iconprev" name="iconName">
      <label for="iconFile">File</label>
      <input type="file" name="iconFile" (change)="onIconChange($event)">      
      <div class="preview" *ngIf="iconprev">
        <img [src]="iconprev" style="width:80px;">
      </div>
    </div>
  </div-->

  <!--div style="border: 1px solid #ced4da;padding: .375rem .75rem;border-radius: .25rem;margin: 20px 0;">
    <p>Images<button class="btn" type='button' (click)="addPlaceImg()" style="background-color:green;color: white;padding: 0px 10px;"> + </button></p>
    <ng-container *ngFor="let imgform of imgforms; let j = index">
      <form [formGroup]="imgform" (ngSubmit)="uploadImg(j)">

        <div class="preview" *ngIf="imgFiles[j]">
          <img [src]="imgFiles[j]" style="width:400px;">
        </div>
      
        <div class="form-group">
          <label for="name">File Name</label>
          <input class="form-control" id="file{{j}}" formControlName="file" type="text">
          <div *ngIf="uf.name.touched && uf.name.invalid" class="alert alert-danger">
            <div *ngIf="uf.name.errors.required">Name is required.</div>
          </div>
        </div>
      
        <div class="form-group">
          <label for="file">File</label>
          <input type="file" class="form-control" id="file{{j}}" formControlName="file" (change)="onImagesChange($event,j)">
          <div *ngIf="uf.file.touched && uf.file.invalid" class="alert alert-danger">
            <div *ngIf="uf.file.errors.required">Image file is required.</div>
          </div>
        </div>
      
        <button class="btn btn-success" type="submit">Upload File</button>
      
      </form>
    </ng-container>
  </div-->

  <!--UPLOAD IMGS-->
  <!--div style="border: 1px solid #ced4da;padding: .375rem .75rem;border-radius: .25rem;margin: 20px 0;">
    <form [formGroup]="uploadForm" (ngSubmit)="upload()">

      <div class="preview" *ngIf="imgFile">
        <img [src]="imgFile" style="width:400px;">
      </div>
    
      <div class="form-group">
        <label for="name">File Name</label>
        <input class="form-control" id="file" formControlName="file" type="text">
        <div *ngIf="uf.name.touched && uf.name.invalid" class="alert alert-danger">
          <div *ngIf="uf.name.errors.required">Name is required.</div>
        </div>
      </div>
    
      <div class="form-group">
        <label for="file">File</label>
        <input type="file" class="form-control" id="file" formControlName="file" (change)="onImageChange($event)">
        <div *ngIf="uf.file.touched && uf.file.invalid" class="alert alert-danger">
          <div *ngIf="uf.file.errors.required">Image file is required.</div>
        </div>
      </div>
    
      <button class="btn btn-success" type="submit">Upload File</button>
    
    </form>
    <UPLOAD IMGS-!->
    
  </div-->
  <!--input
    type="submit"
    value="Save Place"
    class="btn btn-block"
    (click)="onSubmit()"
  /-->
  <app-submit [isNew]="_isNew" section="Luogo" (save)="onSubmit()" (cancel)="cancel()"></app-submit>
</div>
<!--/form-->