import { Component, OnInit, } from '@angular/core';
import { Subscription } from 'rxjs';
import { Area, Place, Image } from 'src/app/guides/model/interfaces';
import { AreasService } from 'src/app/guides/services/areas.service';
import { PlaceService } from 'src/app/guides/services/place.service';
import { ConfigService } from 'src/app/guides/services/config.service';
import { Address } from 'ngx-google-places-autocomplete/objects/address';
import { Location } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { Setting } from 'src/app/guides/model/interfaces';
import { Options } from 'ngx-google-places-autocomplete/objects/options/options';
import { environment } from 'src/environments/environment';
import * as _ from 'lodash';

@Component({
  selector: 'app-add-place',
  templateUrl: './add-place.component.html',
  styleUrls: ['./add-place.component.css'],
  host: { 'style': 'max-width:1000px;margin-left:auto;margin-right:auto', 'class': 'w-75 pt-5' }
})
export class AddPlaceComponent implements OnInit {

  imgpref = environment.imagesPrefix;

  placeSearchOptions: Options = {} as Options;

  areas: Area[] = [] as Area[];
  address: Address = {} as any as Address;
  place: Place = {} as any as Place;

  _isNew: boolean = true;
  _isIconChanged = false;
  _isImageChanged = false;

  tags: string | string[] = [];

  showAddPlace: boolean;
  placeSelected: boolean;
  subscription: Subscription;

  placeTypeList: string[];
  placeClassList: string[];

  submitting = false;
  iconUpdated = false;

  newImagesIndex: number[] = [];
  imagesToDeleteIndex: number[] = [];
  imagesCopy: Image[];

  constructor(
    private route: ActivatedRoute,
    private location: Location,
    private areasService: AreasService,
    private placeService: PlaceService,
    private configService: ConfigService,
  ) {
    this.route.params.subscribe(({ uuid }) => {
      if (uuid === '') {
        this.place = new Place();
        this.place.placeImages = [];
        this.place.area = 2;
        this.place.time2visit = { min: 1, max: 2 };
        this.place.costs = [];
      } else {
        this.place.area = 2;
        this.placeService.getPlaceById(uuid).subscribe((place) => {
          this._isNew = false;
          this.place = place;
          this.place.oldMapIconPath = this.place.mapIconPath;
          this.tags = this.place.tags
          this.place.mapIconPath ? this.place.mapIconPath = this.imgpref + this.place.mapIconPath : '';
          this.place.images.map(img => {
            img.storedPath = this.imgpref + img.storedPath;
          })
          this.imagesCopy = _.cloneDeep(this.place.images);
        })
      }

      /*if (params['place']) {
        this._isNew = false;
        this.place = JSON.parse(params['place']);
        this.place.oldMapIconPath = this.place.mapIconPath;
        this.tags = this.place.tags
        this.place.mapIconPath?this.place.mapIconPath = this.imgpref + this.place.mapIconPath:'';
        this.place.placeImages.map(img=>{
          img.storedPath = this.imgpref + img.storedPath;
        })
        this.imagesCopy = _.cloneDeep(this.place.placeImages);
      } else {
        
      }*/
    });
  }

  ngOnInit(): void {
    this.configService.getSettingParams(this.place.area, ['placeClass', 'placeType']).subscribe((data) => {
      let settings: Setting[] = data;
      this.placeClassList = [...(settings
        .find((s) => {
          return s.param == 'placeClass';
        })
      ['vals'])];
      this.placeTypeList = [...(settings
        .find((s) => {
          return s.param == 'placeType';
        })
      ['vals'])];

      if (this._isNew) {
      } else {
        this.place.placeImages.forEach((img) => {
          img.file = img.storedPath;
        });
      }
    });
    this.getAreas(this.place.area);
    //this.place.area = JSON.parse(this.areas.find(a=>a.id===this.place.area)['latlong']);
  }

  handleAddressChange(address: Address) {
    this.address = address as Address;
    this.placeSelected = true;

    this.place.name = address.name;
    this.place.googleName = address.name;
    this.place.googleId = address.place_id;
    this.place.address = address.formatted_address;
    this.place.phone = address.international_phone_number;

  }

  onSelectArea(area: number) {
    //this.placeSearchOptions.componentRestrictions = {country:'it'};
    //this.placeSearchOptions = {
    //componentRestrictions: {country:'it'},
    this.placeSearchOptions.componentRestrictions = { country: 'it' };

    /*bounds:{ne: {
        lat: this.area.latlong.lat + 0.1,
        lng: this.area.latlong.lng + 0.1
       },
       sw: {
        lat: this.area.latlong.lat - 0.1,
        lng: this.area.latlong.lng - 0.1
      }*/

    //console.log(this.placeSearchOptions);
  }

  getAreas(id?: number) {
    this.areasService.getAreas(id).subscribe((data) => {
      this.areas = [];
      if (this._isNew) {
        this.areas.push(<Area>data);
      } else {
        this.areas = [];
        this.areas.push(
          <Area>(<unknown>data) /*{
          id: <number>data['id'],
          area_name: <string>data['area_name'],
          active: <boolean>data['active'],
          imgName: <string>data['imgName'],
          latlng: <LatLng>data['latlng']}*/
        );
      }
      //console.log(this.areas);
    });
  }

  _click(s: string) {
    document.getElementById(s).click();
  }

  ngOnDestroy() {
    // Unsubscribe to ensure no memory leaks
    //this.subscription.unsubscribe();
  }

  addCostCat(k: string) {
    this.place.costs.push({ cat: k, val: '' });
    //console.log(this.costs.length);
  }

  remCostCat(i: number) {
    this.place.costs.splice(i, 1);
  }

  addPlaceImg(k: string) {
    this.place.placeImages.push({
      name: k,
      file: '',
      title: '',
      desc: '',
      path: '',
    });
    //this._click("img"+(this.place.images.length-1)+"file")
    //console.log(this.imgArray.length);
  }

  remPlaceImg(index) {
    this.imagesToDeleteIndex.push(index);
    this.place.placeImages.splice(index, 1);
  }

  syncPlaceImgs() {
    if (!this._isNew) {
      let images: Image[] = this.imagesToDeleteIndex.map(i => {
        return this.imagesCopy[i]
      })
      images.forEach(image => {
        this.placeService.removeImage(image).subscribe();
      })
    }
    this.imagesToDeleteIndex = [];
    this.imagesCopy = _.cloneDeep(this.place.placeImages);
  }

  syncPlaceIcon() {
    if (!this._isNew) {
      this.placeService.removeIcon(this.place.oldMapIconPath).subscribe();
    }
  }

  onImagesChange(e, i) {
    const reader = new FileReader();

    if (e.target.files && e.target.files.length) {
      const [file] = e.target.files;
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.imagesToDeleteIndex.push(i)
        this.place.placeImages[i].file = reader.result as string;
        this.place.placeImages[i].id = undefined;
        this.newImagesIndex.push(i);
      };
    }
  }

  onIconChange(e) {
    this.iconUpdated = true;
    const reader = new FileReader();

    if (e.target.files && e.target.files.length) {
      const [file] = e.target.files;
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.place.mapIconPath = reader.result as string;
      };
    }
  }

  cancel() {
    this.location.back(); // <-- go back to previous location on cancel
  }

  showAlert($event) {
    //TODO ALERT
  }

  onSubmit() {
    this.submitting = true;
    let place_copy: Place = _.cloneDeep(this.place);

    place_copy.spareTime ? place_copy.spareTime = 1 : place_copy.spareTime = 0;
    place_copy.arrival ? place_copy.arrival = 1 : place_copy.arrival = 0;
    place_copy.vacancies ? place_copy.vacancies = 1 : place_copy.vacancies = 0;

    if (!place_copy.googleName || !place_copy.area) {
      //TODO ALERT ERROR
    } else {
      if (!this._isNew) {
        place_copy.placeImages = this.newImagesIndex.map(i => {
          return place_copy.placeImages[i]
        })
      }
      this.placeService.savePlace(place_copy).subscribe((data) => {
        //TODO ALERT SAVE
        this._isNew = false;
      });
    }

    if (this.iconUpdated) {  //delete old icon, if changed 
      this.syncPlaceIcon();
      this.iconUpdated = false;
    }
    this.syncPlaceImgs(); //delete changed images, if any

  }
}