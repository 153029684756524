import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { EMPTY, Observable } from 'rxjs';
import { StoreImgsService } from 'src/app/common/services/store-imgs.service';
import { DeleteStoredImgsService } from 'src/app/common/services/delete-stored-imgs.service';
import { ConfigService } from 'src/app/guides/services/config.service';
import { Person, Setting } from 'src/app/guides/model/interfaces';
import { Settings } from 'luxon';
import { map, switchMap, take } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { keys, lastIndexOf } from 'lodash';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
  }),
};

@Injectable({
  providedIn: 'root',
})
export class PeopleService {
  private imgsFolder =  `${environment.imagesBaseDir}people/`; 
  private apiUrl = `${environment.dbApiBaseDir}people`;

  constructor(
    private http: HttpClient,
    private storeImgsService: StoreImgsService,
    private deleteStoredImgsService: DeleteStoredImgsService,
    private configService: ConfigService
  ) {}

  getPeopleConfig(area: number): Observable<Setting[]> {
    return this.configService.getSettingParams(2, ['personActivity']);
  }

  getPersonByUuid(uuid:string):Observable<Person>{
    return this.http.get<any>(`${this.apiUrl}?filter=uuid,eq,${uuid}`).pipe(
      take(1),
      map(({records})=>{return records[0]})
    )
  }

  getPeople(): Observable<Person[]> {
    return this.http.get<Person[]>(this.apiUrl);
  }

  getPeopleByActivity(activity:string):Observable<Person[]> {
    activity = activity.replace(' ','%20');
    return this.http.get<Person[]>(`${this.apiUrl}?filter=activity,eq,${activity}`)
  }

  getTaxinNcc():Observable<Person[]>{
    const url = `${this.apiUrl}?filter=activity,in,ncc,taxi`;
    console.log(url);
    return this.http.get<Person[]>(url);
  }

  deletePerson(person:Person):Observable<number>{
    const url = `${this.apiUrl}/${person.id}`;
    let files = [];
    if (person.profileImgPath){
      files.push(person.profileImgPath)
    }
    return this.deleteStoredImgsService.deleteFolder(person.uuid)
    .pipe(
      switchMap(data=>{
        return this.http.delete<number>(url);
      })
    ).pipe(take(1))
  }

  /*updateProfileImg(id:number,img:string):Observable<string>{
    const url = `${this.apiUrl}/${id}`;
    return this.storeImgsService.saveImgsToPath({files:[img],path:this.imgFolder})
    .pipe(
      switchMap(data=>{
        return this.http.patch(url,{profileImgPath:data[0]['filePath']},httpOptions)
        .pipe(
          switchMap(
            (data1)=>{
              //console.log(data1);
              return new Observable<string>((observer)=>{
                observer.next(<string>data[0]['filePath'])
              })
            })
        )
      })
    )
  }*/

  getPeopleImagesStringArray$():Observable<string[]>{
    return this.http.get<string[]>(`${this.apiUrl}?include=profileImgPath`).pipe(
      map(data=>{
        let kvArray = data['records'];
        let response : string[] = [];
        kvArray.forEach(elem=>{
          response.push(elem['profileImgPath'])
        })
        return response;
      })
    )
  }

  

  pruneStorage(personUuid:any){
    this.deleteStoredImgsService.deleteFolder(personUuid).subscribe();
  }

  removeImage(filePath:string):Observable<string[]>{
    return this.deleteStoredImgsService.deleteImgs([filePath]);
  }

  savePerson(person: Person): Observable<number> {
    const url = `${this.apiUrl}${person.id ? '/' + person.id : ''}`;
    let files = [];
    if (person.oldProfileImgPath){
      files.push(person.profileImgPath)
    }
    return this.storeImgsService
      .saveImgsToPath({
        files: files,
        path: this.imgsFolder,
      })
      .pipe(
        switchMap((data) => {
          person.oldProfileImgPath&&data.length?person.profileImgPath=data[0]['filePath']:'';
          //person.profileImgPath = data.length?data[0]['filePath']:[];
          if (person.id) {
            return this.removeImage(person.oldProfileImgPath).pipe(
              switchMap(data=>{
                console.log(`removed ${data}`);
                return this.http.put<number>(url, person, httpOptions);
              })
            )
          } else {
            return this.http.post<number>(this.apiUrl, person, httpOptions);
          }
        })
      );
  }
}






// {
//   "text": "quanti abitanti ha Moncalieri",
//   "category": "moncalieri",
//   "difficulty": 1,
//   "type": 1,
//   "answers":[
//     {
//       "text": "tra 50 e 60 mila",
//       "valid": true
//     },
//     {
//       "text": "tra 50 e 60 mila",
//       "valid": false
//     },
//     {
//       "text": "tra 50 e 60 mila",
//       "valid": false
//     },
//     { 
//       "text": "tra 50 e 60 mila",
//       "valid": false
//     }
//   ]
// } 