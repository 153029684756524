import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Person } from 'src/app/guides/model/interfaces';
import { PeopleService } from 'src/app/guides/services/people.service';

@Component({
  selector: 'app-people',
  templateUrl: './people.component.html',
  styleUrls: ['./people.component.css']
})
export class PeopleComponent implements OnInit {
  
  people:Person[] = [];
  loading: boolean = true;
  
  toastHeader:string;
  toastBody:string;

  constructor(
    private router: Router,
    private peopleService: PeopleService,
    ) { }

  ngOnInit(): void {
    this.peopleService.getPeople().subscribe((data)=>{
      this.people = data['records']
      console.log(this.people);
    })
    this.loading = false;
  }

  createNew() {
    this.router.navigate(['/addPerson']);
  }

  deletePerson(person:Person){
    this.peopleService.deletePerson(person).subscribe(data=>{
      this.people = this.people.filter(t=>t.id !== person.id);
      //TODO: ALERT
    })
  }
}
