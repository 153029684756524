export const environment = {

  production: true,

  _BAD_WORDS: 'https://raw.githubusercontent.com/napolux/paroleitaliane/master/paroleitaliane/lista_badwords.txt',

  xferApi: {
    _AMADEUS_AUTH: 'tourismapp/api/amadeusAccessToken.php',
    _TRENITALIA: 'tourismapp/api/trenitaliaApi.php',
  },

  fileApi: {
    _SAVE_IMAGES: 'https://oldadmin.tourismapp.it/api/saveImgsToPath.php',
    _DELETE_IMAGES: 'https://oldadmin.tourismapp.it/api/deleteImgsFromPath.php',
    _SYNC_FILES: 'https://oldadmin.tourismapp.it/api/syncDbFiles.php',
  },

  api: {
    apiUrl: "",
    adminUrl: "",
    authUrl: "",
    auth: "session",
  },

  auth0: {
    url: "",
    clientId: "",
    returnUrl: ""
  },

  IMAGES: "https://oldadmin.tourismapp.it/",
  FILES: "https://files.tourismapp.it/",
  dbApiBaseDir: 'https://oldadmin.tourismapp.it/api/adminapi.php/records/',
  authApiBaseDir: 'https://oldadmin.tourismapp.it/api/adminapi.php/',
  imagesBaseDir: 'images/',
  imagesPrefix: 'https://oldadmin.tourismapp.it/',
  MESSAGES: {
    SUCCESS: 'success',
    WARNING: 'warning',
    ERROR: 'danger',
    LOG: 'dark'
  }
};
