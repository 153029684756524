import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { EMPTY, Observable, of , } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
  }),
};

const HttpOptionsText: Object = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
  }),
  responseType: 'text',
  observe: 'response',
};

@Injectable({
  providedIn: 'root',
})
export class TagsService {
  private badwordsUrl = environment._BAD_WORDS;

  constructor(private http: HttpClient) {}

  getTags(): Observable<string[]> {
    return of(['uno', 'due', 'tre']);
  }

  getBlacklistedTags(): Observable<string> {
    //return this.http.get<string[]>(this.badwordsUrl,HttpOptionsText)
    return this.http.get(this.badwordsUrl, { responseType: 'text' })
    .pipe(
      /*tap(
        (data) => console.log(data),
        (error) => console.log(error)
      )*/
    );
  }

  postTag(): Observable<number> {
    return of(1);
  }
}
