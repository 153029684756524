import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { GbSharedService } from 'src/app/guides/services/gb-shared.service';
import { GuidebookService } from 'src/app/guides/services/guidebook.service';

@Component({
  selector: 'app-placeslist',
  templateUrl: './placeslist.component.html',
  styleUrls: ['./placeslist.component.css'],
})
export class PlaceslistComponent implements OnInit {
  @Input() public uuid: any = null;
  @Input() public listSearch: any = {};
  @Input() public onSave!: EventEmitter<void>;
  @Input() public onCancel!: EventEmitter<void>;
  @Output() public onShowToast!: EventEmitter<any>;
  content: any = {};
  ids: any = {};
  saveSub:any;
  cancelSub:any;

  constructor(
    private service: GbSharedService,
    private guidebookService: GuidebookService
  ) {

    this.ids.placesIds = [];
    this.uuid = this.service.uuid;
    this.listSearch = this.service.listSearch;
    this.onSave = this.service.onSave;
    this.onCancel = this.service.onCancel;
    this.onShowToast = this.service.onShowToast;
    this.guidebookService
      .getGuideBookSectionContent(this.uuid)
      .subscribe((content) => {
        this.content = content;
        if(this.content.content.placesIds.length){
          this.ids = this.content.content
        }else{
          this.ids['placesIds'] = [];
        }

        /*if(data['records'][0]){
          this.content = data['records'][0];
          this.ids = this.content.content;
        }else{
          this.content = [];
          this.ids['placeIds'] = [];
        }*/
      });
  }

  ngOnInit(): void {
    this.saveSub = this.service.onSave.subscribe(()=>{this.save();});
    this.cancelSub = this.service.onCancel.subscribe(()=>{this.cancel();});
  }

  cancel(){
    console.log("unsubscribing");
    this.saveSub.unsubscribe();
    this.cancelSub.unsubscribe();
  }

  onItemCheckChange($event){
    if($event.target.checked){
      this.ids.placesIds.push(Number($event.target.value));
    }else{
      this.ids.placesIds.splice(this.ids.placesIds.indexOf(Number($event.target.value)),1);
    }
    console.log(this.ids)
  }

  checkIfSelected(itemId): boolean {
    if(this.ids.placesIds.length){
      return this.ids.placesIds.indexOf(itemId) > -1 ? true : false;
    }else{
      return false;
    }
  }

  save():void{
    this.content['content'] = JSON.stringify(this.ids);
    this.guidebookService.saveGuideBookSectionContent(this.content).subscribe(
      data=>{
        console.log(data);
        if(data){
          this.onShowToast.emit()
        }else{
          
        }
      }
    )
  }
}
