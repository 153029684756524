<div class="card-sl mb-1 d-flex align-items-start flex-column p-0 position-relative">
  <!-- <a class="card-action" (click)="onDelete(place); $event.stopPropagation()"><fa-icon [icon]="['fas', 'trash']"
      size="2x"></fa-icon></a> -->
  <div class="card-image w-100 m-1">
    <div class="d-flex justify-content-center">
      <span>Google Id: {{ place.googleId }}</span>
    </div>
    <div id="carousel{{ place.id }}" class="carousel slide" data-bs-ride="carousel">
      <div class="carousel-inner">
        <!-- ngfor loop using get dynamic value and ngclass for add acive class -->
        <div class="carousel-item" *ngFor="let img of place.images; let i = index" [ngClass]="{ active: i === 0 }">
          <img class="card-img-top img-fluid" src="{{ imgpref + img.path }}" title="{{ img.title }}"
            style="height: 10rem; overflow: hidden; object-fit: cover" loading="lazy" />
        </div>
      </div>
      <button (click)="$event.stopPropagation()" class="carousel-control-prev" type="button"
        [attr.data-bs-target]="'#carousel' + place.id" data-bs-slide="prev">
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Previous</span>
      </button>
      <button (click)="$event.stopPropagation()" class="carousel-control-next" type="button"
        [attr.data-bs-target]="'#carousel' + place.id" data-bs-slide="next">
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Next</span>
      </button>
    </div>
  </div>

  <div class="card-heading w-100">
    <h5 class="card-title text-truncate text-uppercase">{{ place.name }}</h5>
    <h6 class="card-subtitle text-truncate text-muted">
      {{ place.address }}
    </h6>
  </div>
  <div class="d-flex w-100 mb-auto">
    <ng-container *ngIf="place.tags">
      <div class="tag" *ngFor="let tag of place.tags">{{ tag }}</div>
    </ng-container>
  </div>
  <div class="card-text h-25 overflow-auto">
    {{ place.description }}
  </div>
  <app-modify-delete [target]="place.id" (onDelete)="onDelete(place)"></app-modify-delete>
</div>