import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule, Routes } from '@angular/router';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { GooglePlaceModule } from "ngx-google-places-autocomplete";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DevelComponent } from './components/devel/devel.component';
import { LoginComponent } from './components/login/login.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from "@angular/common";
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { FaModule } from './common/fa/fa.module';
import { MaterialModule } from './common/material/material.module';
import { NgbActiveModal, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CustomComponentsModule } from './common/components/custom-components.module';
import { ContestModule } from './contest/contest.module';
import { GuidesModule } from './guides/guides.module';
import { BreadcrumbModule } from 'xng-breadcrumb';


const appRoutes: Routes = [
  //{ path: '', component: AreasComponent },
  //{ path: 'people', component: PeopleComponent },
  //{ path: 'areas', component: AreasComponent },
  //{ path: 'addPerson', component: AddPersonComponent },
  //{ path: 'settings', component: SettingsComponent },
  { path: 'devel', component: DevelComponent},
  //{ path: 'guidebooks', component: GuidebooksComponent},
  //{ path: 'guidebooks/guidebookConfig', component: GuideModalComponent}
  /*{ path: 'about', component: AboutComponent },*/
];

@NgModule({
  declarations: [
    AppComponent,
    DevelComponent,
    //LoginComponent,
  ],
  imports: [
    FormsModule,
    HttpClientModule,
    GooglePlaceModule,
    BrowserModule,
    AppRoutingModule,
    FontAwesomeModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    RouterModule.forRoot(appRoutes, { enableTracing: true }),
    DragDropModule,
    CommonModule,
    NgxSliderModule,
    //NgxsModule.forRoot([],{developmentMode: !environment}),
    //BusinessModule,
    FaModule,
    MaterialModule,
    CustomComponentsModule,
    NgbModule,
    ContestModule,
    GuidesModule,
    BreadcrumbModule
  ],
  providers: [
      NgbActiveModal
  ],
  bootstrap: [AppComponent],
  exports:[
    
  ]
})
export class AppModule { }
