import { Component, OnInit, ViewChildren } from '@angular/core';
import { Router } from '@angular/router';
import { QuizService } from '../../services/quiz.service';

@Component({
  selector: 'app-questions',
  templateUrl: './questions.component.html',
  styleUrls: ['./questions.component.css']
})
export class QuestionsComponent implements OnInit {
  @ViewChildren('linkRef') linkRefs;
  filteredQuestions:any[] = [];
  questions:any[] = [];
  

  filtersActive = true;
  lastFilteredProperty = '';
  previousFilteredResult = [];

  /*filters = [
    { name: 'type', options: ['Risposta Multipla', 'Vero/falso', 'Immagine'], displayName: 'Tipo', selected: 'Tipo' },
    { name: 'category', options: ['arte', 'cinema', 'cultura generale'], displayName: 'Categoria', selected: 'Categoria' },
    { name: 'difficulty', options: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10], displayName: 'Difficoltà', selected: 'Difficoltà' }
  ];*/

  _filters = [
    {
      name: 'type',
      displayName: 'Tipo',
      options: { 'Risposta Multipla': 1, 'Vero/falso': 2, 'Immagine': 3 },
      selected: 'Tipo',
      disabled: false
    },
    {
      name: 'category',
      displayName: 'Categoria',
      options: { 'Arte': 'arte', 'Cinema': 'cinema', 'Cultura Generale': 'culturaGenerale','moncalieri':'moncalieri' },
      selected: 'Categoria',
      disabled: false
    }, {
      name: 'difficulty',
      displayName: 'Difficoltà',
      options: { 1: 1, 2: 2, 3: 3, 4: 4, 5: 5, 6: 6, 7: 7, 8: 8, 9: 9, 10: 10 },
      selected: 'Difficoltà',
      disabled: false
    },
  ]

  constructor(
    private router: Router,
    private quizService: QuizService) {
    
  }

  ngOnInit(): void {
    this.loadQuestions();
  }

  loadQuestions(){
    this.quizService.getQuestions().subscribe((questions:any)=>{
      this.questions = questions['records'];
      this.resetFilters();
    })
  }

  applyFilter(property: any, value: any, index: any) {
    if (this.lastFilteredProperty == property) {
      this.filteredQuestions = this.previousFilteredResult.filter(q => {
        return q[property] == value;
      })
    } else {
      this.previousFilteredResult = this.filteredQuestions;
      this.filteredQuestions = this.filteredQuestions.filter(q => {
        return q[property] == value;
      });
      if (this.lastFilteredProperty != '') {
        this._filters.find(filter => {
          return filter.name == this.lastFilteredProperty
        }).disabled = true;
      }
    }
    this.lastFilteredProperty = property;
    let valueName;
    Object.entries(this._filters[index].options).find(([k, v]) => {
      if (v === value) {
        valueName = k;
        return true;
      }
      return false;
    });
    this._filters[index].selected = this._filters[index].displayName + ': ' + valueName;
  }

  resetFilters() {
    this.filteredQuestions = this.questions;
    this.previousFilteredResult = this.questions;
    this._filters.forEach(filter => {
      filter.selected = filter.displayName;
      filter.disabled = false;
    })
    this.lastFilteredProperty = '';
  }

  getTypeName(type: any) {
    switch (type) {
      case 1:
        return "Risposta Multipla";
        break;
      case 2:
        return "Vero/Falso";
        break;
      case 3:
        return "Immagine";
    }
  }

  addQuestion(){
    this.router.navigateByUrl('questions/details/0')
  }

  deleteQuestion(i:any){    
    if(this.filteredQuestions[i].id){
      this.quizService.deleteQuestion(this.questions[i].id).subscribe((result)=>{
        console.log(result);
        this.loadQuestions();
      })
    }
  }

  onListItemSelect($event: any) {
    // let currentElement = $event.currentTarget;
    // if(!currentElement.classList.contains('active')){
    //   currentElement.classList.add('active');
    //   this.getSiblings(currentElement).forEach(elem => {
    //     elem.classList.remove('active');
    //   })
    // }
  }

  /* https://attacomsian.com/blog/javascript-dom-get-all-siblings-of-an-element */
  getSiblings(elem: any): any[] {
    // create an empty array
    let siblings: any[] = [];
    // if no parent, return empty list
    if (!elem.parentNode) {
      return siblings;
    }
    // first child of the parent node
    let sibling = elem.parentNode.firstElementChild;
    // loop through next siblings until `null`
    do {
      // push sibling to array
      if (sibling != elem) {
        siblings.push(sibling);
      }
    } while ((sibling = sibling.nextElementSibling));
    return siblings;
  }

}
