import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { SubmitComponent } from './submit/submit.component';
import { TagsInputComponent } from './tags-input/tags-input.component';
import { SortableStepperComponent } from './sortable-stepper/sortable-stepper.component';
import { DurationComponent } from './duration/duration.component';
import { DatepickerRange } from './datepicker-range/datepicker-range';
import { MultiImgModalComponent } from './multi-img-modal/multi-img-modal.component';
import { ConfirmModalComponent } from './confirm-modal/confirm-modal.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { ModifyDeleteComponent } from './modify-delete/modify-delete.component';
import { RouterModule } from '@angular/router';



@NgModule({
  declarations: [
    SubmitComponent,
    TagsInputComponent,
    SortableStepperComponent,
    DurationComponent,
    DatepickerRange,
    MultiImgModalComponent,
    ConfirmModalComponent,
    ModifyDeleteComponent,
    ConfirmModalComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    ReactiveFormsModule,
    FontAwesomeModule,
    NgbModule,
    NgxSliderModule
  ],
  exports: [
    SubmitComponent,
    TagsInputComponent,
    SortableStepperComponent,
    DurationComponent,
    DatepickerRange,
    MultiImgModalComponent,
    ConfirmModalComponent,
    ModifyDeleteComponent,
    ConfirmModalComponent
  ]
})

export class CustomComponentsModule { 
  
}
