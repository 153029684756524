import { Component, OnInit, Input, EventEmitter } from '@angular/core';
import { GbSharedService } from 'src/app/guides/services/gb-shared.service';
import { GuidebookService } from 'src/app/guides/services/guidebook.service';
import { StoreImgsService } from 'src/app/common/services/store-imgs.service';
import { DeleteStoredImgsService } from 'src/app/common/services/delete-stored-imgs.service';
import { MultiImgModalComponent } from 'src/app/common/components/multi-img-modal/multi-img-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PlaceService } from 'src/app/guides/services/place.service';

@Component({
  selector: 'app-guidebook-product',
  templateUrl: './guidebook-product.component.html',
  styleUrls: ['./guidebook-product.component.css']
})
export class GuidebookProductComponent implements OnInit {
  @Input() public config: any = {};
  @Input() public onSave!: EventEmitter<void>;
  @Input() public onCancel!: EventEmitter<void>;

  private imgFolder="../api/images/products/"
  section: any = {};
  guideSection: any = {};
  products: any[] = [];
  places: any[] = [];

  saveSub: any;
  cancelSub: any;

  constructor(
    private service: GbSharedService,
    private gbService: GuidebookService,
    private placeService: PlaceService,
    private storeImagesSvc: StoreImgsService,
    private deleteImageSvc: DeleteStoredImgsService,
    private modalService: NgbModal,
  ) {
    this.config = this.service.configuration;
    this.onSave = this.service.onSave;
    this.onCancel = this.service.onCancel;
  }

  ngOnInit(): void {
    this.saveSub = this.onSave.subscribe(()=>{this.save()})
    this.cancelSub = this.onCancel.subscribe(()=>{this.cancel();});
    console.log(this.config);
    this.gbService
    .getGuideBookSectionContent(this.config.uuid)
    .subscribe((data)=>{
        this.section = data['records'][0];
        console.log(this.section);
        this.guideSection = data['records'][0]?data['records'][0]:[];
        this.products = data['records'][0]?data['records'][0].content.fields:[];
        console.log(this.products);
      })
    this.placeService.getPlaceByCat("products").subscribe(
      data=>{
        this.places = data['records'];
        console.log(this.places)
        
    })
  }

  remove(index:number){
    this.products.splice(index,1)
  }

  cancel(){
    console.log("unsubscribing");
    this.saveSub.unsubscribe();
    this.cancelSub.unsubscribe();
  }

  trackByIndex(index: number, obj: any): any {
    return index;
  }

  _click(s: string) {
    document.getElementById(s).click();
  }

  removeImg(file:string) {
    this.deleteImageSvc.deleteImgs([file])
    .subscribe(data =>{
      console.log(`delete image msg${data}`);
    });
  }

  onImgChange(e,i) {
    const reader = new FileReader();
    let img: string;

    if (e.target.files && e.target.files.length) {
      const [file] = e.target.files;
      reader.readAsDataURL(file);
      reader.onload = () => {
        img = reader.result as string;
        this.storeImagesSvc.saveImgsToPath({files:[img],path:this.imgFolder})
        .subscribe(data =>{
          console.log(<any>data);
          if(this.products[i].imgPath) {this.removeImg(this.products[i].imgPath);}
          this.products[i].imgPath = (<any>data[0]).filePath;
        })
      };
    }
  }

  save():void{
    console.log(this.products)
    this.guideSection.content = JSON.stringify({fields:this.products});
    this.gbService
      .saveGuideBookSectionContent(this.guideSection)
      .subscribe((data)=> {
        console.log(data);
      }
    );
  }

  addProduct():void{
    this.products.unshift({
      name:"",
      place:"",
      imgPath:"",
      imgsPaths:[]
    })
    console.log(this.products)
  }

  openModalImage(imgs:any,i:number){
    const modalRef = this.modalService.open(MultiImgModalComponent);
    modalRef.componentInstance.images = imgs;
    modalRef.componentInstance.imgFolder = this.imgFolder;
    modalRef.dismissed.subscribe(()=>{
      
    })
    modalRef.result.then((images) => {
      this.products[i].imgsPaths=images;
      this.save();
    });
    modalRef.componentInstance.imagesChange.subscribe((images)=>{
      console.log('changed');
      console.log(images);
      this.products[i].imgsPaths=images;
      this.save();
    })
  }
}
