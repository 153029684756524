import { Component, Input, OnInit,  Output, EventEmitter } from '@angular/core';
import { GbSharedService } from 'src/app/guides/services/gb-shared.service';
import { GuidebookService } from 'src/app/guides/services/guidebook.service';
import { StoreImgsService } from 'src/app/common/services/store-imgs.service';
import { DeleteStoredImgsService } from 'src/app/common/services/delete-stored-imgs.service';
import { PlaceService } from 'src/app/guides/services/place.service';

@Component({
  selector: 'app-guidebook-contact',
  templateUrl: './guidebook-contact.component.html',
  styleUrls: ['./guidebook-contact.component.css']
})
export class GuidebookContactComponent implements OnInit {
  @Input() public config: any = {};
  @Input() public onSave! : EventEmitter<void>;
  @Input() public onCancel! : EventEmitter<void>;

  private imgFolder="../api/images/contacts/"
  section:any = {};
  guideSection: any = {};
  contacts:any[] = [];
  places:any[] = [];
  
  saveSub:any;
  cancelSub:any;

  constructor(
    private service:GbSharedService,
    private gbService:GuidebookService,
    private placeService:PlaceService,
    private storeImagesSvc: StoreImgsService,
    private deleteImageSvc: DeleteStoredImgsService
  ) {
    this.config = this.service.configuration;
    this.onSave = this.service.onSave;
    this.onCancel = this.service.onCancel;
    
   }

  ngOnInit(): void {
    this.saveSub = this.service.onSave.subscribe(()=>{this.save()})
    this.cancelSub = this.service.onCancel.subscribe(()=>{this.cancel();});
    console.log(this.config);
    this.gbService.getGuideBookSectionContent(this.config.uuid).subscribe(
      section=>{
        this.section = section;
        console.log(this.section);
        this.guideSection = section;
        this.contacts = section.content.fields??null;
        debugger
      })
    this.placeService.getPlaceByCat("numero utile").subscribe(
      data=>{
        this.places = data['records'];
        
    })
  }

  remove(index:number){
    this.contacts.splice(index,1)
  }

  cancel(){
    console.log("unsubscribing");
    this.saveSub.unsubscribe();
    this.cancelSub.unsubscribe();
  }

  trackByIndex(index: number, obj: any): any {
    return index;
  }

  _click(s: string) {
    document.getElementById(s).click();
  }

  removeImg(file:string) {
    this.deleteImageSvc.deleteImgs([file])
    .subscribe(data =>{
      console.log(`delete image msg${data}`);
    });
  }

  onImgChange(e,i) {
    const reader = new FileReader();
    let img: string;

    if (e.target.files && e.target.files.length) {
      const [file] = e.target.files;
      reader.readAsDataURL(file);
      reader.onload = () => {
        img = reader.result as string;
        this.storeImagesSvc.saveImgsToPath({files:[img],path:this.imgFolder})
        .subscribe(data =>{
          console.log(<any>data);
          if(this.contacts[i].imgPath) {this.removeImg(this.contacts[i].imgPath);}
          this.contacts[i].imgPath = (<any>data[0]).filePath;
        })
      };
    }
  }

  save():void{
    console.log(this.contacts)
    this.guideSection.content = JSON.stringify({fields:this.contacts});
    this.gbService.saveGuideBookSectionContent(this.guideSection).subscribe(
      data => {
        console.log(data);
      }
    )
  }

  addContact():void{
    this.contacts.unshift({
      name:"",
      place:"",
      telno:"",
      link:""
    })
  }

}
