<!--div class="card" (click)="selectArea(area.uuid)">
  <div class="card-body d-flex align-items-start flex-column p-0">
    <div class="w-100 p-1">
      <img src="{{imgpref + area.imgName}}"
      class="card-img-top img-fluid overflow-hidden"
      style="height: 10rem; overflow: hidden; object-fit: cover" 
    />
    </div>
    <div class="mb-auto">
    <div class="card-title fs-5">
      {{ area.area_name }}
    </div>
    <div class="card-subtitle fs-6">
      {{ area.desc }}
    </div>
  </div>
  <app-modify-delete [target]="area.uuid" (onDelete)="onDelete(area);" ></app-modify-delete>
  </div>
</div-->

<div class="card mb-3 overflow-hidden" style="max-width: 540px;">
  <div class="row g-0">
    <div class="col-md-4">
      <img src="{{imgpref + area.imgName}}" class="img-fluid" alt="..."
      style="height: 100%;object-fit: cover;"/>
    </div>
    <div class="col-md-8 position-relative">
      <div id="ol-map-{{area.area_name}}" style="z-index:1;border-width: 0px !important;"
        class="map-container position-absolute end-0 top-0 border w-100 h-100 opacity-gradient" ></div>
      <div class="card-body position-relative" style="z-index:0;">
        <h3 class="card-title fw-semibold">{{ area.area_name }}</h3>
        <h4 class="card-subtitle fw-medium">{{area.desc}}</h4>
        <p class="card-text">lat: {{area.latlng.lat}} | lng: {{area.latlng.lng}}</p>
      </div>
    </div>
  </div>
  <app-modify-delete [target]="area.uuid" (onDelete)="onDelete(area);" ></app-modify-delete>
</div>
