import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EMPTY, Observable } from 'rxjs';
import { DeleteStoredImgsService } from 'src/app/common/services/delete-stored-imgs.service';
import { StoreImgsService } from 'src/app/common/services/store-imgs.service';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
  }),
};

@Injectable({
  providedIn: 'root',
})
export class DevelService {
  constructor(
    private http: HttpClient,
    private storeImgsService: StoreImgsService,
    private deleteStoredImgsService: DeleteStoredImgsService
  ) {}

  storeImgs(imgs:{ files: string[]; path: string }): Observable<string[]> {
    if (true) {
      return this.storeImgsService.saveImgsToPath(imgs)
    } else {
      return EMPTY;
    }
  }

  deleteImgs(files: string[]): Observable<string[]> {
    if (true) {
      return this.deleteStoredImgsService.deleteImgs(files)
    } else {
      return EMPTY;
    }
  }
}
