import { Component, EventEmitter, Inject, Input, OnInit, Output, inject } from '@angular/core';
import { GbSharedService } from 'src/app/guides/services/gb-shared.service';
import { GuidebookService } from 'src/app/guides/services/guidebook.service';
import { PeopleService } from 'src/app/guides/services/people.service';
import { StoreImgsService } from 'src/app/common/services/store-imgs.service';
import { DeleteStoredImgsService } from 'src/app/common/services/delete-stored-imgs.service';
import { NgbDateParserFormatter, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MomentDateFormatter } from 'src/app/common/providers/parser/momentParserFormatter';
import { MultiImgModalComponent } from 'src/app/common/components/multi-img-modal/multi-img-modal.component';
import { Config } from '../guide-switch.component';

@Component({
  selector: 'app-guidebook-experience',
  templateUrl: './guidebook-experience.component.html',
  styleUrls: ['./guidebook-experience.component.css'],
  providers: [{ provide: NgbDateParserFormatter, useClass: MomentDateFormatter }]
})
export class GuidebookExperienceComponent implements OnInit {
  //@Input() public config: any = {};
  @Output() public onSave!: EventEmitter<void>;
  @Input() public onCancel!: EventEmitter<void>;
  //@Input('config') public config_: any;

  private imgFolder = "../api/images/experiences/"
  section: any = {};
  guideSection: any = {};
  experiences: any[] = [];
  activities: any[] = [];
  people: any[] = [];

  saveSub: any;
  cancelSub: any;

  constructor(
    private service: GbSharedService,
    private gbService: GuidebookService,
    private peopleSvc: PeopleService,
    private storeImagesSvc: StoreImgsService,
    private deleteImageSvc: DeleteStoredImgsService,
    private modalService: NgbModal,
    @Inject(Config) private config
  ) {
    console.log(config);
    this.config = this.service.configuration;
    this.onSave = this.service.onSave;
    this.onCancel = this.service.onCancel;
  }

  ngOnInit(): void {
    this.saveSub = this.service.onSave.subscribe(() => { this.save(); });
    this.cancelSub = this.service.onCancel.subscribe(() => { this.cancel(); });
    console.log(this.config);
    this.gbService
      .getGuideBookSectionContent(this.config.uuid)
      .subscribe((section) => {
        this.section = section;
        console.log(this.section);
        this.guideSection = section;
        this.experiences = section.content.fields??null;
        console.log(this.experiences);
      });
    this.gbService.getGuideBookSectionContent(51).subscribe(
      data => {
        this.activities = data["records"][0] ? data["records"][0].content.fields : [];
        console.log(this.activities)

      })
    this.peopleSvc.getPeople().subscribe((data) => {
      this.people = data['records'];
      console.log(this.people);
    });
  }

  remove(index: number) {
    this.experiences.splice(index, 1);
  }

  cancel() {
    console.log('unsubscribing');
    this.saveSub.unsubscribe();
    this.cancelSub.unsubscribe();
  }

  trackByIndex(index: number, obj: any): any {
    return index;
  }

  _click(s: string) {
    document.getElementById(s).click();
  }

  removeImg(file: string) {
    this.deleteImageSvc.deleteImgs([file] )
      .subscribe(data => {
        console.log(`delete image msg${data}`);
      });
  }

  onImgChange(e, i) {
    const reader = new FileReader();
    let img: string;
    if (e.target.files && e.target.files.length) {
      const [file] = e.target.files;
      reader.readAsDataURL(file);
      reader.onload = () => {
        img = reader.result as string;
        this.storeImagesSvc.saveImgsToPath({ files: [img], path: this.imgFolder })
          .subscribe(data => {
            console.log(<any>data);
            if (this.experiences[i].imgPath) { this.removeImg(this.experiences[i].imgPath); }
            this.experiences[i].imgPath = (<any>data[0]).filePath;
          })
      };
    }
  }

  compareActivity = function (option: { name: string }, value: { name: string }): boolean {
    return option?.name === value?.name
  }

  save(): void {
    console.log(this.experiences);
    this.guideSection.content = JSON.stringify({ fields: this.experiences });
    this.gbService
      .saveGuideBookSectionContent(this.guideSection)
      .subscribe((data) => {
        console.log(data);
      });
    console.log(this.guideSection);
  }

  addExperience(): void {
    this.experiences.unshift({
      name: '',
      activity: {},
      days: {},
      date: {},
      duration: {},
      imgPath: '',
      imgsPaths:[],
      place: ''
    });
    console.log(this.experiences);
  }

  openModalImage(imgs:any,i:number){
    const modalRef = this.modalService.open(MultiImgModalComponent);
    modalRef.componentInstance.images = imgs;
    modalRef.componentInstance.imgFolder = this.imgFolder;
    modalRef.dismissed.subscribe(()=>{
      
    })
    modalRef.result.then((images) => {
      this.experiences[i].imgsPaths=images;
      this.save();
    });
    modalRef.componentInstance.imagesChange.subscribe((images)=>{
      console.log('changed');
      console.log(images);
      this.experiences[i].imgsPaths=images;
      this.save();
    })
  }
}
