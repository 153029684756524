<div role="button" class="btn btn-primary sticky-top m-auto m-3" (click)="addEvent()"
style="left: 100%;
width: 40px;
border-radius: 35px;">+</div>

<div class="row">

    <!-- il form -->
    <div *ngFor="let event of events; let i = index" class="p-2 col-12 col-xl-6">

        <div class=" border m-2 bg-success text-dark bg-opacity-10">
            
            <fa-icon [icon]="['fas', 'trash']" size="lg" class="ml-auto"
                style="color: red; position: relative; top: 0rem; right: 0rem; float: right" (click)="remove(i)">
            </fa-icon>
        
            <div class="d-flex flex-row flex-wrap justify-content-between p-1">
                <div class="p-2 d-flex w-100">
                    <div class="p-2 flex-grow-1">
                        <div class="form-group mb-1">
                            <label [for]="name[i]">Nome</label>
                            <input type="text" class="form-control" id="name" required [(ngModel)]="events[i].name" name="name"
                                #name="ngModel" />
                        </div>
                        <div class="w-100 mt-3">
                            <select class="form-select col-auto" name="eventType{{i}}" id="eventType{{i}}"
                                [(ngModel)]="events[i].eventType">
                                <option [ngValue]="undefined" value="" selected disabled>
                                    Tipo Evento
                                </option>
                                <option *ngFor="let eventType of eventTypes" [value]="eventType">
                                    {{ eventType }}
                                </option>
                            </select>
                        </div>
                        <div class="w-100 mt-3">
                            <select class="form-select col-auto" name="person{{i}}" id="person{{i}}"
                                [(ngModel)]="events[i].people">
                                <option [ngValue]="undefined" value="" selected disabled>
                                    Organizzatore
                                </option>
                                <option *ngFor="let person of people" [value]="person.id">
                                    {{ person.name }}
                                </option>
                            </select>
                        </div>
                        <div class="w-100 mt-3">
                            <select class="form-select col-auto" name="place{{i}}" id="place{{i}}"
                                [(ngModel)]="events[i].place" [compareWith]="comparePlace" >
                                <option [ngValue]="undefined" value="" selected disabled>
                                    Luogo
                                </option>
                                <option *ngFor="let place of places" [ngValue]="{id:place.id,name:place.name}">
                                    {{ place.name }}
                                </option>
                            </select>
                        </div>
                    </div>
                    <div class="border p-2 ms-auto">
                        <div class="d-flex flex-grow-1 flex-column p-1" style="max-height: 200px; aspect-ratio: 1/1; background-color: gray">
                            <img src="{{ event.imgPath }}" (click)="_click('img' + i)"
                                onerror="this.onerror=null;this.src='assets/placeholder.jpg';" class="img-fluid border w-100"
                                style="overflow: hidden; object-fit: cover;height: 100%;" />
                            <div hidden class="input-group input-group-sm mt-3">
                                <input type="file" aria-label="File" (change)="onImgChange($event, i)" name="img{{ i }}"
                                  id="img{{ i }}" class="form-control" accept=".jpg,.jpeg,.png,.gif" />
                            </div>
                            <div class="btn btn-success w-100 m-auto" (click)="openModalImage(events[i].imgsPaths,i)" type="button">
                                <fa-icon [icon]="['fas', 'images']"></fa-icon>
                            </div>
                        </div>
                    </div>
                </div>
        
                <div class="d-flex flex-column w-100 mt-5 border p-2">
        
                    <div class="form-group mb-1">
                        <label [for]="description[i]">Descrizione</label>
                        <textarea class="form-control" id="description{{i}}" [(ngModel)]="events[i].description" name="description{{i}}"
                            #description="ngModel" ></textarea>
                    </div>
        
                    <div class="form-group mb-1">
                        <label [for]="notes[i]">Note</label>
                        <textarea type="textarea" class="form-control" id="notes{{i}}" [(ngModel)]="events[i].notes" name="notes{{i}}"
                          #notes="ngModel" ></textarea>
                      </div>
        
                </div>
        
                <div class="d-flex flex-column w-100 mt-5 border">
                    <div class="me-2">
                        <label>Quando</label>
                    </div>
                    <div class="mt-3" >
                        <app-datepicker-range [(fromDate)]="events[i].date.from" [(toDate)]="events[i].date.to" class="w-100"></app-datepicker-range>
                    </div>
                </div>
        
                <div class="d-flex flex-row mt-2 align-items-center">
        
                    <div class="form-group">
                        <label class="active" for="timeStandard">Dalle</label>
                        <input class="form-control" id="timeStandard" type="time" [(ngModel)]="events[i].time">
                    </div>
        
                    <div class="form-group ms-3">
                        <label class="active" for="timeStandard">Durata</label>
                        <input class="form-control" id="timeStandard" type="time" [(ngModel)]="events[i].duration">
                    </div>
        
                    <div class="form-group ms-5 w-auto">
                        <label [for]="cost[i]">Costo</label>
                        <input type="text" class="form-control" id="cost" [(ngModel)]="events[i].cost" name="cost"
                            #cost="ngModel" />
                    </div>
        
                </div>
        
            </div>

        </div>
    
    </div>
    
</div>
