import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ConfigService } from 'src/app/guides/services/config.service';
import { environment } from 'src/environments/environment';
import { EMPTY, Observable } from 'rxjs';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
  }),
};

@Injectable({
  providedIn: 'root'
})
export class GameService {
  private apiUrl = `${environment.dbApiBaseDir}games`;

  constructor(
    private http: HttpClient,
    private configService: ConfigService
  ){ }

  getGames():Observable<any>{
    return this.http.get<any>(`${this.apiUrl}?filter=active,eq,1`,httpOptions);
  }

}

