<div class="w-100 h-100">
  <div class="px-sm-0 px-md-2 px-lg-5 col-lg-9 col-xl-8 col-xxl-7 mx-auto py-4"
    *ngFor="let section of guidebookConfig.vals; let i = index">
    <div class="card">
      <div class="card-header">
        {{ section.name | uppercase }}
      </div>
      <div class="card-body position-relative">
        <div class="position-absolute top-0 end-0 p-2">
          <div class="form-floating me-auto">
            <select class="form-select" id="relatedGame{{i}}" [(ngModel)]="section.relatedGame">
              <option *ngFor="let g of games" [value]="g.id">{{g.name}}</option>
            </select>
            <label for="relatedGame{{i}}">Gioco Associato:</label>
          </div>
        </div>
        <div class="pt-5">
          <h4><span>Sezioni</span></h4>
          <ul *ngIf="section.content as contents" class="nav nav-pills gap-3">
            <li *ngFor="let content of contents | keyvalue" class="nav-item">
              <a class="nav-link active" aria-current="page" style="background-color:#39ca69;"
                [routerLink]="['./',section.uuid,content.value.uuid]">{{ content.key | uppercase }}</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>