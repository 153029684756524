import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { GbSharedService } from 'src/app/guides/services/gb-shared.service';
import { GuidebookService } from 'src/app/guides/services/guidebook.service';

@Component({
  selector: 'app-itinerarieslist',
  templateUrl: './itinerarieslist.component.html',
  styleUrls: ['./itinerarieslist.component.css'],
})
export class ItinerarieslistComponent implements OnInit {
  @Input() public uuid: any = null;
  @Input() public listSearch: any = {};
  @Input() public onSave!: EventEmitter<void>;
  @Input() public onCancel!: EventEmitter<void>;
  @Output() public onShowToast!: EventEmitter<any>;
  content: any = {};
  ids: any = {};
  saveSub:any;
  cancelSub:any;

  constructor(
    private service: GbSharedService,
    private guidebookService: GuidebookService
  ) {

    this.ids.itinerariesIds = [];
    this.uuid = this.service.uuid;
    this.listSearch = this.service.listSearch;
    this.onSave = this.service.onSave;
    this.onCancel = this.service.onCancel;
    this.onShowToast = this.service.onShowToast;
    this.guidebookService
      .getGuideBookSectionContent(this.uuid)
      .subscribe((data) => {
        this.content = data['records'][0];
        this.ids = this.content.content;
      });
  }

  ngOnInit(): void {
    this.saveSub = this.service.onSave.subscribe(()=>{this.save();});
    this.cancelSub = this.service.onCancel.subscribe(()=>{this.cancel();});
  }

  cancel(){
    console.log("unsubscribing");
    this.saveSub.unsubscribe();
    this.cancelSub.unsubscribe();
  }

  onItemCheckChange($event){
    if($event.target.checked){
      this.ids.itinerariesIds.push(Number($event.target.value));
    }else{
      this.ids.itinerariesIds.splice(this.ids.itinerariesIds.indexOf(Number($event.target.value)),1);
    }
    console.log(this.ids)
  }

  checkIfSelected(itemId): boolean {
    return this.ids.itinerariesIds.indexOf(itemId) > -1 ? true : false;
  }

  save():void{
    this.content.content = JSON.stringify(this.ids);
    this.guidebookService.saveGuideBookSectionContent(this.content).subscribe(
      data=>{
        console.log(data);
        if(data){
          this.onShowToast.emit()
        }else{

        }
      }
    )
  }
}
