import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Area } from 'src/app/guides/model/interfaces';
import { AreasService } from '../../services/areas.service';

@Component({
  selector: 'app-areas',
  templateUrl: './areas.component.html',
  styleUrls: ['./areas.component.css']
})
export class AreasComponent implements OnInit {

  constructor(private router:Router, private areasService: AreasService) { }

  areas:Area[] = [];
  loading: boolean = false;

  ngOnInit(): void {
    this.areasService.getAreas().subscribe(
      (data) => {
        this.areas = data['records'];
        console.log(this.areas)
      }
    )
  }

  createNew(){
    this.router.navigate(['/addAreas']);
  }

}
