import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { Person } from 'src/app/guides/model/interfaces';
import { PeopleService } from 'src/app/guides/services/people.service';
import * as _ from 'lodash';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-add-person',
  templateUrl: './add-person.component.html',
  styleUrls: ['./add-person.component.css'],
})
export class AddPersonComponent implements OnInit {
  
  imgpref = environment.imagesPrefix;

  person: Person = {} as Person;
  activities = [];
  cities = ['Torino', 'Moncalieri', 'Chieri'];
  submitting = false;

  oldImage : string;

  constructor(
    private route: ActivatedRoute,
    private location: Location,
    private peopleService: PeopleService,
    
  ) {
    this.route.params.subscribe(({uuid})=>{
      if(uuid===''){
        this.person = new Person();
        this.person.tags = [];
      }else{
        this._isNew = false;
        this.peopleService.getPersonByUuid(uuid).subscribe((person:Person)=>{
          this.person = person;
          this.person.profileImgPath = this.imgpref + this.person.profileImgPath;
        })
        this.person.profileImgPath = this.imgpref + this.person.profileImgPath;
      }
    })
  }

  _isNew: boolean = true;

  ngOnInit(): void {
    this.peopleService.getPeopleConfig(2).subscribe((data) => {
      this.activities = data[0].vals;
      this.person.area = 2;
    });
    //this.cleanImgs();
  }

  _click(s: string) {
    document.getElementById(s).click();
  }

  ngOnDestroy() {
    // Unsubscribe to ensure no memory leaks
    //this.subscription.unsubscribe();
  }
  
  onImgChange(e) {
    const reader = new FileReader();

    if (e.target.files && e.target.files.length) {
      const [file] = e.target.files;
      reader.readAsDataURL(file);
      reader.onload = () => {
        if (!this._isNew) {
          this.person.oldProfileImgPath = this.person.profileImgPath
        }
        this.person.profileImgPath = reader.result as string;
      };
    }
  }

  cancel() {
    this.location.back(); // <-- go back to previous location on cancel
  }


  onSubmit() {
    this.submitting = true;
    let person_copy: Person = _.cloneDeep(this.person);
    person_copy.bookable?person_copy.bookable=1:person_copy.bookable=0;
    person_copy.professional?person_copy.professional=1:person_copy.professional=0;
    console.log(person_copy);
    this.peopleService.savePerson(person_copy).subscribe(
      (data) => {
        //TODO: ALERT
        this.submitting = false;
        if(this._isNew){
          this.person.id = data;
          this._isNew = false;
        }
      },
      (error) => {
        //TODO ALERT ERROR
      }
    );
  }

  showAlert($event) {
    //TODO ALERT
  }
}
