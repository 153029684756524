import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EMPTY, Observable, of, take } from 'rxjs';
import { environment } from 'src/environments/environment';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
  }),
};

@Injectable({
  providedIn: 'root',
})
export class DeleteStoredImgsService {

  private deleteImgsUrl = environment.IMAGES;

  constructor(private http: HttpClient) { }

  deleteImgs(
    files: string[]
  ): Observable<string[]> {
    if (files.length) {
      return this.http.post<string[]>(`${this.deleteImgsUrl}delete`, { files: files }, {
        headers: new HttpHeaders({
          'Content-type': 'application/json',
          Accept: 'application/json',
        }),
      }).pipe(take(1));
    } else {
      return new Observable<string[]>(observer => {
        observer.next([])
      });
    }
  }

  deleteFolder(uuid: string): Observable<any> {
    return this.http.post<any>(`${this.deleteImgsUrl}deleteDir`, { dir: uuid }, {
      headers: new HttpHeaders({
        'Content-type': 'application/json',
        Accept: 'application/json',
      })
    }).pipe(take(1));
  }

  /*syncImgs(imagesToKeep:{folder:string,files:string[]}):Observable<string[]>{
    return this.http.post<string[]>(this.syncImgsUrl, imagesToKeep,{
      headers: new HttpHeaders({
        'Content-type': 'application/x-www-form-urlencoded',
        Accept: 'application/json',
      }),
    });
  }*/
}
