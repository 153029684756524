<div id="fab-button" class="btn btn-primary position-fixed bottom-0 end-0" [routerLink]="['./','']">
  <fa-icon [icon]="['fas', 'plus']" size="2x" style="margin: 1%"></fa-icon>
</div>

<div class="mt-1" style="position: sticky;top:0;z-index: 100;">
  <select
    class="form-select"
    name="selectArea"
    (change)="onSelectArea(area)"
    [(ngModel)]="area"
  >
    <option value="" selected>All Areas - Select to Filter</option>
    <option
      *ngFor="let area of availAreas; let i = index"
      [disabled]="!area.active"
      [value]="area.id"
    >
      {{ area.area_name }}
    </option>
  </select>
</div>

<div *ngIf="loading" class="spinner-border text-primary" role="status">
  <span class="visually-hidden">Loading...</span>
</div>
<div class="pt-4">
  <div class="d-flex flex-row flex-wrap">
    <div
      *ngFor="let place of places"
      class="col-12 col-sm-6 col-lg-4 col-xl-3 col-xxl-2 p-4"
    
    >
      <app-place-item
        [place]="place"
        (onDeletePlace)="deletePlace(place)"
      >
      </app-place-item>
    </div>
  </div>
</div>

